.NE_normal_input_with_action_button {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    position: relative;
}

.NE_normal_input_with_action_button label {
    font-size: 20px;
    font-weight: 500;
    color: #3f4351;
    margin-bottom: 6px;
}

.NE_normal_input_with_action_button input {
    width: 100%;
    height: 52px;
    border: 1px solid #dbe2ea;
    border-radius: 6px;
    box-shadow: 0px 4px 8px #2c27380a;
    padding: 16px 122px 16px 16px;
    font-size: 16px;
    color: #2c2738;
    margin-bottom: 6px;
}

.NE_normal_input_with_action_button input::placeholder{
    font-size: 18px;
    color: #989898;
}

.NE_normal_input_with_action_button input:focus{
    border: 2px solid #68A4F5;
    padding: 15px 121px 15px 15px;
}

.NE_normal_input_with_action_button input:disabled{
    background-color: #ebf4f8;
    color: #989898;
}

.NE_normal_input_with_action_button p {
    display: none;
    font-size: 14px;
}

.NE_normal_input_with_action_button button {
    border:none;
    position: absolute;
    right:12px;
    top: 44%;
    transform: translateY(-50%);
    width: 100px;
    height: 34px;
    box-shadow: 0px 12px 24px #2C273814;
    border-radius: 3px;
    background-color: var(--primary-color);
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
}

.NE_normal_input_with_action_button_success p{
    display: block;
    color: #14a38b;
}

.NE_normal_input_with_action_button_error p{
    display: block;
    color: #ff7171;
}

.controller{
    position: relative;
}
