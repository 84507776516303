.NE_etudiant_Evaluation{
    width: 100%;
    background-color: #FFFFFF;
    margin-left: 26px;
}

.NE_etudiant_Evaluation .NE_notionItem_header{
    margin-top: 28px;
    margin-left: 40px;
    margin-right: 20px;
    width: 97%;
}


.NE_etudiant_quiz .NE_EVALUATION {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* width: 1402px; */
    height: 904px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 16px 56px #2C273814;
    border-radius: 9px;
    overflow-y: scroll;
    overflow-x: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.NE_etudiant_quiz .NE_EVALUATION::-webkit-scrollbar{
    display: none;
}
.NE_etudiant_quiz .NE_header_text {
    width: 100%;
    margin-top: 48px;
    margin-bottom: 30px;
    margin-left: 21px;
}

.NE_etudiant_quiz .NE_header_text h2 {
    font: normal normal bold 28px/60px IBM Plex Sans;
    letter-spacing: 0px;
    color: #2C2738;
}

.NE_etudiant_quiz .NE__ {
    display: flex;
    flex-direction: column;
    width: 1360px;
    height: 344px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 2px solid #F5F6FA;
    border-radius: 6px;
    margin-bottom: 16px;
}

.NE_etudiant_quiz .NE_body_content {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    margin-top: 30px;
}
.NE_etudiant_quiz .NE_body_content .NE_body_paragraph {
    margin-top: 16px;
    width: 1140px;
    height: 97px;
}

.NE_etudiant_quiz .NE_body_content h6 {
    font: normal normal 600 16px/20px IBM Plex Sans;
    letter-spacing: 0px;
    color: #989898;
}

.NE_etudiant_quiz .NE_body_content p {
    font: normal normal 600 25.6px/33px IBM Plex Sans;
    letter-spacing: 0px;
    color: #2C2738;
}

.NE_etudiant_quiz .NE_normal_radiobutton .NE_checked_radiobutton{
    margin: 0;
}

.NE_etudiant_quiz .NE_align_items {
    display: flex;
    align-items: center;
    height: 48px;
}

.NE_etudiant_quiz .NE_align_items h4{
    font: normal normal 500 20px/20px IBM Plex Sans;
    letter-spacing: 0px;
    color: #3F4351;
}
