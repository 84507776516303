li.NE_session_item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-shrink: 0;
    width: 100%;
    height: 54px;
    border-radius: 6px;
    margin-bottom: 8px;
    cursor: pointer;
    padding-left: 43px;
    position: relative;
    font-size: 20px;
    color: #9F9F9F;
}

li.NE_session_item::before {
    content: '';
    display: flex;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background-color: #ccc;
    position: absolute;
    left: 17px;
}

li.NE_session_item .options_wrapper {
    position: absolute;
    right: 27px;
    top: 12px;
    display: none;
    z-index: 100;
}

li.NE_session_item .options_wrapper_open {
    display: block;
}

li.NE_session_item_selected {
    background-color: var(--primary-color);
    font-weight: bold;
    color: #fff;
}

li.NE_session_item_ongoing::before {
    background-color: #14A38B;
}

li.NE_session_item_to_come::before {
    background-color: #F2AC57;
}

li.NE_session_item_completed::before {
    background-color: #FF7171;
}

li.NE_session_item .actions_btn {
    position: absolute;
    right: 7px;
    width: 20px;
    height: 20px;
    background-image: url("./assets/treedots_grey.svg");
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
}

li.NE_session_item_selected .actions_btn {
    background-image: url("./assets/treedots_white.svg");
}


.close_modale{
    background-color: rgb(255 255 255 / 0%);
    width: 100vw;
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;
    height: 100vh;
}
