.NE_course_publish_overview{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    box-shadow: 0px 16px 56px #2C273814;
    border-radius: 9px;
    padding: 11px 34px 11px 16px;
    width: 100%;
}

.NE_course_publish_overview .course_overview{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
}

.NE_course_publish_overview .course_overview img{
    object-fit: contain;
    border-radius: 16px;
    margin-right: 24px;
    width: 224px;
    height: 170px;
}

.NE_course_publish_overview .course_overview .course_infos{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    align-self: center;
    height: 140px;
    max-width: 450px;
}

.NE_course_publish_overview .course_overview .course_infos .category{
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 2px 4px #2C273814;
    border-radius: 13px;
    padding: 5px 12px;
    background-color: var(--primary-color);
    font-size: 14px;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.86);
}

.NE_course_publish_overview .course_overview .course_infos h1{
    font-size: 28px;
    font-weight: bold;
    color: #2C2738;
}
.NE_course_publish_overview .course_overview .course_infos p{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.NE_course_publish_overview .course_overview .course_infos h1{
    font-size: 20px;
    color: #2C2738;
}

.NE_course_publish_overview .actions .action{
    width: 180px;
}
.mt-1{
    margin-top: 1em;
}


