.NE_SessionsSideBar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    width: 320px;
    min-height: 904px;
    background: #FFFFFF;
    box-shadow: 0.78px 5px 20px #B0CAD11A;
    border-radius: 9px;
    padding: 10px;
}

.NE_SessionsSideBar > .body {
    width: 100%;
}

.NE_SessionsSideBar > .body .NE_icon_input {
    margin-bottom: 9px;
}

.NE_SessionsSideBar > .body ul.sessions_list {
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: 624px;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    margin-top: 47px;
}

.NE_SessionsSideBar > .body ul.sessions_list::-webkit-scrollbar{
    display: none;
}

/*.NE_SessionsSideBar > .body ul.sessions_list li.session_item {*/
/*    display: flex;*/
/*    flex-direction: row;*/
/*    justify-content: flex-start;*/
/*    align-items: center;*/
/*    flex-shrink: 0;*/
/*    width: 100%;*/
/*    height: 54px;*/
/*    border-radius: 6px;*/
/*    margin-bottom: 8px;*/
/*    cursor: pointer;*/
/*    padding-left: 43px;*/
/*    position: relative;*/
/*    font-size: 20px;*/
/*    color: #9F9F9F;*/
/*}*/

/*.NE_SessionsSideBar > .body ul.sessions_list li.session_item::before {*/
/*    content: '';*/
/*    display: flex;*/
/*    width: 10px;*/
/*    height: 10px;*/
/*    border-radius: 100%;*/
/*    background-color: #ccc;*/
/*    position: absolute;*/
/*    left: 17px;*/
/*}*/

/*.NE_SessionsSideBar > .body ul.sessions_list li.session_item .options_wrapper {*/
/*    position: absolute;*/
/*    right: 27px;*/
/*    top: 12px;*/
/*    display: none;*/
/*    !*width: 150px;*!*/
/*}*/

/*.NE_SessionsSideBar > .body ul.sessions_list li.session_item .options_wrapper_open {*/
/*    display: block;*/
/*}*/

/*.NE_SessionsSideBar > .body ul.sessions_list li.session_item_selected {*/
/*    background-color: var(--primary-color);*/
/*    font-weight: bold;*/
/*    color: #fff;*/
/*}*/

/*.NE_SessionsSideBar > .body ul.sessions_list li.session_item_ongoing::before {*/
/*    background-color: #14A38B;*/
/*}*/

/*.NE_SessionsSideBar > .body ul.sessions_list li.session_item_to_come::before {*/
/*    background-color: #F2AC57;*/
/*}*/

/*.NE_SessionsSideBar > .body ul.sessions_list li.session_item_completed::before {*/
/*    background-color: #FF7171;*/
/*}*/

/*.NE_SessionsSideBar > .body ul.sessions_list li.session_item .actions_btn {*/
/*    position: absolute;*/
/*    right: 7px;*/
/*    width: 20px;*/
/*    height: 20px;*/
/*    background-image: url("./assets/treedots_grey.svg");*/
/*    background-repeat: no-repeat;*/
/*    background-position: center;*/
/*    cursor: pointer;*/
/*}*/

/*.NE_SessionsSideBar > .body ul.sessions_list li.session_item_selected .actions_btn {*/
/*    background-image: url("./assets/treedots_white.svg");*/
/*}*/

.NE_SessionsSideBar > .footer {
    width: 100%;
}
