/* Split the screen in half */
.signup_split {
  height: 100%;
  width: 50%;
  position: fixed;
  z-index: 1;
  top: 0;
  overflow-x: hidden;
  padding-top: 20px;
}

/* Control the left side */
.signup_left {
  left: 0;
  background-color: #fff;
}

/* Control the right side */
.signup_right {
  right: 0;
  /* background-color: red; */
  /*background-image: url('../images/cristofer.jpg');*/
  /* background-repeat: no-repeat; */
  background-size: cover;
}

/* If you want the content centered horizontally and vertically */
.signup_centered {
  display: flex;
  justify-content: center;
  /*width: 673px;*/
  /*position: absolute;*/
  /*top: 50%;*/
  /*left: 50%;*/
  /*transform: translate(-50%, -50%);*/
  /*text-align: center;*/
  padding-bottom: 20px;
  width: 100%;
}

/* Style the image inside the centered container, if needed */

.signup_NE_header_logo {
  width: 184px;
  height: 81px;
  margin-bottom: 33px;
  object-fit: contain;
}

.signup_container {
  display: flex;
  justify-content: center;
}

.signup_centered .signup_NE_contain {
  /*width: 670px;*/
  width: 80%;
  height: 52px;
}

.signup_NE_header_ {
  /*width: 675px;*/
  height: 44px;
  text-align: left;
  letter-spacing: 0px;
  color: #2c2738;
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 34px;
  font-weight: 800;
}

.alerts_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  /*background-color: red;*/
}

.signup_NE_paragraph {
  /*width: 675px;*/
  height: 26px;
  letter-spacing: 0px;
  color: #2c2738;
  /* margin-bottom: 22px; */
  margin: 0;
}

.signup_NE_paragraph_underlined {
  /*width: 675px;*/
  height: 26px;
  letter-spacing: 0px;
  color: #2c2738;
  margin-bottom: 22px;
  /* margin: 0; */
}

.signup_NE_paragraph_underlined a {
  color: var(--primary-color);
  text-decoration-line: none;
}

.signup_NE_label {
  /* margin-top: 5px; */
  margin-top: 2px;
  /* margin-bottom: 29px; */
}

.signup_NE_terms {
  display: flex;
  align-items: center;
  /* align-items: center; */
  margin-top: 34px;
  margin-bottom: 38px;
}

.signup_NE_terms p {
  font-weight: 500;
  letter-spacing: 0px;
  color: #3f4351;
}
.signup_NE_terms p span {
  text-decoration: underline;
  letter-spacing: 0px;
  color: var(--primary-color);
}

.signup_NE_label_signup {
  margin-bottom: 49px;
}

.signup_NE_Social {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

@media screen and (max-width: 768px) {
  .signup_right {
    width: 0;
  }
  .signup_left {
    display: flex;
    left: 0;
    background-color: #fff;
    justify-content: center;
    width: 100%;
  }

  .signup_centered .signup_NE_contain {
    width: 90%;
    height: 52px;
  }
}
