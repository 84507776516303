.NE_elements_list {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    width: 360px;
    height: 713px;
    border: 2px solid #F6F5F5;
    border-radius: 6px;
    background-color: #fff;
}

.NE_elements_list .list_header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 30px;
    padding: 5px 20px;
    background: var(--primary-color);
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}

.NE_elements_list .list_header p{
    font-size: 18px;
    font-weight: 500;
    letter-spacing: -0.18px;
    color: #FFFFFF;
}

.NE_elements_list .list_items{
    display: block;
    padding: 19px 10px 0 10px;
    width: 100%;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.NE_elements_list .list_items::-webkit-scrollbar{
    display: none;
}

.NE_elements_list .list_item{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 54px;
    border-radius: 6px;
    margin-bottom: 5px;
    cursor: pointer;
    padding: 17px 21px;
}

.NE_elements_list .list_item > img{
    width: 18px;
    height: 20px;
    margin-right: 18px;
}

.NE_elements_list .list_item > p{
    font-weight: 500;
    color: #989898;
    overflow: hidden;
    font-size: 16px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
}

.NE_elements_list .list_item:last-child{
    margin-bottom: 0;
}

.NE_elements_list .list_item_active{
    background-color: #F1F8FF;
}

.NE_elements_list .list_item_active > p{
    font-weight: bold;
    color: var(--primary-color);
}

/*.NE_header_list {*/
/*    display: flex;*/
/*    !* justify-content: center; *!*/
/*    align-items: center;*/
/*    !* width: 360px; *!*/
/*    height: 32px;*/
/*    background: var(--primary-color) 0% 0% no-repeat padding-box;*/
/*    border-top-left-radius: 6px;*/
/*    border-top-right-radius: 6px;*/
/*    margin-bottom: 19px;*/
/*}*/
/*.NE_elemets_wrapper {*/
/*    overflow: hidden;*/
/*    overflow-y: scroll;*/
/*    overflow-x: hidden;*/
/*    max-height: 650px;*/
/*    -ms-overflow-style: none;*/
/*    scrollbar-width: none;*/
/*}*/

/*.NE_elemets_wrapper::-webkit-scrollbar{*/
/*    display: none;*/
/*}*/

/*.NE_body_list_active {*/
/*    !* margin-top: 20px; *!*/
/*    !* margin-bottom: 20px; *!*/
/*    margin: auto;*/
/*    width: 330px;*/
/*    height: 54px;*/
/*    background: #F1F8FF 0% 0% no-repeat padding-box;*/
/*    border-radius: 6px;*/
/*    cursor: pointer;*/
/*}*/

/*.NE_body_list_active .NE_body_button {*/
/*    display: flex;*/
/*    */
/*}*/

/*.NE_body_list_active .NE_body_button img{*/
/*    margin-left: 22px;*/
/*    margin-right: 18px;*/
/*}*/

/*.NE_body_list .NE_body_button img{*/
/*    margin-left: 22px;*/
/*    margin-right: 18px;*/
/*}*/


/*.NE_body_list {*/
/*    !* margin: auto; *!*/
/*    !* width: 100%; *!*/
/*    width: 330px;*/
/*    height: 54px;*/
/*    background: #fff 0% 0% no-repeat padding-box;*/
/*    border-radius: 6px;*/
/*    margin-top: 8px;*/
/*    margin-bottom: 8px;*/
/*}*/

/*.NE_header_text {*/
/*    margin-left: 20px;*/
/*    !* text-align: left; *!*/
/*    font-family: "IBM Plex Sans";*/
/*    font-size: 18px;*/
/*    font-weight: normal;*/
/*    letter-spacing: -0.18px;*/
/*    color: #FFFFFF;*/
/*}*/

/*.NE_body_text_active {*/
/*    !* margin-left: 57px; *!*/
/*    text-align: left;*/
/*    font: normal normal bold 20px/60px IBM Plex Sans;*/
/*    letter-spacing: 0px;*/
/*    color: var(--primary-color);*/
/*}*/

/*.NE_body_text {*/
/*    margin-left: 57px;*/
/*    text-align: left;*/
/*    font: normal normal bold 20px/60px IBM Plex Sans;*/
/*    letter-spacing: 0px;*/
/*    color: #989898;*/
/*}*/
