.NE_normal_textarea {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
}

.NE_normal_textarea label {
    font-size: 15px;
    font-weight: 500;
    color: #3f4351;
    margin-bottom: 6px;
}

.NE_normal_textarea textarea {
    min-width: 100%;
    max-width: 100%;
    min-height: 60px;
    border: 1px solid #dbe2ea;
    border-radius: 6px;
    box-shadow: 0px 4px 8px #2c27380a;
    padding: 16px 57px 16px 16px;
    font-size: 20px;
    color: #2c2738;
    resize: none;
}

.NE_normal_textarea textarea::placeholder{
    font-size: 18px;
    color: #989898;
}

.NE_normal_textarea textarea:focus{
    border: 2px solid #68A4F5;
    padding: 15px 56px 15px 15px;
}

.NE_normal_textarea textarea:disabled{
    background-color: #ebf4f8;
    color: #989898;
}

.NE_normal_textarea p {
    display: none;
    font-size: 14px;
    margin-top: 6px;
}

.NE_normal_textarea_success p{
    display: block;
    color: #14a38b;
}

.NE_normal_textarea_error p{
    display: block;
    color: #ff7171;
}
