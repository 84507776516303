.NE_collapsable_card_with_icon{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 4px 8px #2C27380A;
    border: 1px solid #DBE2EA;
    border-radius: 9px;
    padding: 30px 34px;
}

.NE_collapsable_card_with_icon > .header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.NE_collapsable_card_with_icon > .header .title{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.NE_collapsable_card_with_icon > .header .title img{
    width: 23px;
    height: 25px;
    margin-right: 11px;
}

.NE_collapsable_card_with_icon > .header .title h2{
    font-size: 28px;
    font-weight: bold;
    color: #2C2738;
}

.NE_collapsable_card_with_icon > .header .btn_collapse{
    display: flex;
    width: 30px;
    height: 30px;
    background-image: url('assets/icon-chevron-down.svg');
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    justify-self: flex-end;
}

.NE_collapsable_card_with_icon > .body{
    display: none;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    padding: 33px 11px;
}

.NE_collapsable_card_with_icon_open > .header{
    padding-bottom: 23px;
}

.NE_collapsable_card_with_icon_open > .header .btn_collapse{
    transform: rotate(180deg);
}

.NE_collapsable_card_with_icon_open > .body{
    display: flex;
}

.quiz_creation_card .body form{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}

.quiz_creation_card .body form .form-field{
    width: 100%;
}

.form-field .file{
    width: 100%;
}
.form-field .file .upload_item{
  display: flex;
}

.form-field .file .upload_item_icon{
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-content: center;
    margin-right: 10px;
}

.form-field .file .upload_item_icon img{
    width: 20px;
    height: 20px;
}

.upload_item {
    display: flex;
    margin-top: 10px;
}
.upload_item .upload_item_content{
    flex: 1;
    margin-bottom: 10px;
}
.upload_item .upload_item_content .upload_item_header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 32px;
    flex: 1;
}

.upload_item .upload_item_content .upload_item_header h1{
    display: flex;
    font-weight: bold;
    color: #101426;
    font-size: 16px;
    align-self: flex-start;
    line-height: 16px;
    margin-top: 6px;
}

.upload_item .upload_item_content .upload_item_header button{
    border: none;
    padding: 8px 21px;
    border-radius: 20px;
    background-color: #FAF0F0;
    color: #FA5D5D;
    font-size: 12px;
    font-weight: 600;
    cursor: pointer;
    margin-left: auto;
}

.quiz_creation_card .body form .form-field .question{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 15px;
}

.quiz_creation_card .body form .form-field .question:last-child{
    margin-bottom: 0;
}

.quiz_creation_card .body form .form-field .question .question_options{
    margin-top: 18px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    list-style: none;
}

.quiz_creation_card .body form .form-field .question .question_options li{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 14px 15px 14px 20px;
    margin-bottom: 7px;
    border-radius: 8px;
}

.quiz_creation_card .body form .form-field .question .question_options li > .NE_normal_input{
    display: none;
}

.quiz_creation_card .body form .form-field .question .question_options li:last-child{
    margin-bottom: 0;
}

.quiz_creation_card .body form .form-field .question .question_options li .question_option_action{
    display: flex;
    width: 29px;
    height: 29px;
    background-image: url('assets/icon-trash.svg');
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
}

.quiz_creation_card .body form .form-field .question .question_options li.question_option_selected{
    background-color: rgba(20, 163, 139, 0.16);
}

.quiz_creation_card .body form .form-field .question .question_options li.question_option_selected .question_option_action{
    background-image: url('assets/icon-check.svg');
    cursor: default;
}

.quiz_creation_card .body form .form-field .question .question_options li.add_question_option{
    justify-content: flex-start;
    font-size: 20px;
    font-weight: 500;
    color: #3F4351;
    width: fit-content;
    cursor: pointer;
}

.quiz_creation_card .body form .form-field .question .question_options li.add_question_option::before{
    content: '';
    display: block;
    width: 24px;
    height: 24px;
    background-image: url('assets/add-small.svg');
    margin-right: 10px;
}

.quiz_creation_card .body form .add_question{
    display: block;
    width: 50px;
    height: 50px;
    background-image: url('assets/add-big.svg');
    cursor: pointer;
    margin-top: 16px;
}

.quiz_creation_card .body form .form-field .question .question_options li.question_option_edit_mode > .NE_normal_input{
    display: flex;
}

.quiz_creation_card .body form .form-field .question .question_options li.question_option_edit_mode .NE_radio_button{
    display: none;
}

.quiz_creation_card .body form .form-field .question .question_options li.question_option_edit_mode .question_option_action{
    display: none;
}

.body form .actions{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.body form .action{
    margin-right: 8px;
}
.body form .action .NE_button_large{
    width: 180px;
}
body form .action:last-child{
    margin-right: 0;
}
.body form{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}

.body form .form-field:nth-child(2){
    margin-bottom: 41px;
}

.notion_creation_card .body form .form-field:nth-child(3){
    margin-bottom: 41px;
}

.notion_creation_card .body form .form-field:nth-child(4){
    margin-bottom: 30px;
}

.notion_creation_card .body form .form-field .NE_normal_textarea textarea{
    min-height: 286px;
}

.actions_bar_st{

    width: 372px !important;
    padding: 20px 54px !important;

}

.actions_bar_st img path {
    background-color: red;
        fill: blue;

}

.notion_icon {
    margin-right: 10px;
    background-color: white; /* defines the background color of the image */
    mask: url(./assets/file-remove.svg) no-repeat center / contain;
    -webkit-mask: url(./assets/file-remove.svg) no-repeat center / contain;
    width: 24px;
    height: 24px;
    margin-top: 4px;
}

.quiz_icon {
    margin-right: 10px;
    background-color: white; /* defines the background color of the image */
    mask: url(./assets/icon-question.svg) no-repeat center / contain;
    -webkit-mask: url(./assets/icon-question.svg) no-repeat center / contain;
    width: 24px;
    height: 24px;
    margin-top: 4px;
}

.isCollapse{
    height: 145px;
    overflow: hidden;
}

.isCollapse .body{
    display: none;
}

.isCollapse .NE_collapsable_card_with_icon_open  > .header .btn_collapse {
    transform: rotate(0deg ) !important;
}

.NE_documents_uploader{
  position: relative;
}
.NE_documents_uploader input[type=file] {
    font-size: 10px;
    border: 1px solid;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
}

.mb-1{
    margin-bottom: 10px !important;
}
.error_msg{
    color: red;
}

.body_element_notion{
    width: 100%;
    margin-bottom: 40px;
}


.btn_save{
    display: flex;
    background-image: url(./assets/save.svg) !important;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer !important;
    align-items: center;
    align-content: center;
    justify-content: flex-start;
    background-size: 20px;
    width: 24px;
}

.btn_edit{
    display: flex;
    background-image: url(./assets/edit.svg) !important;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer !important;
    align-items: center;
    align-content: center;
    justify-content: flex-start;
    background-size: 26px;
    width: 24px;
}

