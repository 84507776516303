/* Split the screen in half */
.split {
    height: 100%;
    width: 50%;
    position: fixed;
    z-index: 1;
    top: 0;
    overflow-x: hidden;
    padding-top: 20px;
}

/* Control the left side */
.left {
    left: 0;
    background-color: #fff;
}

/* Control the right side */
.right {
    right: 0;
    /* background-color: red; */
    background-image: url('../../images/cristofer.jpg');
    /* background-repeat: no-repeat; */
    background-size: cover;
    z-index: -1;
}

/* If you want the content centered horizontally and vertically */
.centered {
    display: flex;
    justify-content: center;

}

/* Style the image inside the centered container, if needed */

.NE_header_logo {
    width: 184px;
    height: 81px;
    margin-top: 60px;
    margin-bottom: 33px;
}


.container {
    display: flex;
    justify-content: center;
}

.container .NE_contain {
    width: 670px;
    height: 52px;
}

.NE_header_ {
    width: 675px;
    height: 44px;
    text-align: left;
    letter-spacing: 0px;
    color: #2C2738;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 34px;
    font-weight: 600;
}

.NE_paragraph {
    top: 365px;
    left: 140px;
    width: 675px;
    height: 26px;
    text-align: left;
    letter-spacing: 0px;
    color: #2C2738;
    margin: 0;
}
.NE_label {
    margin-top: 22px;
}

.NE_header_addImg {
    cursor: pointer;
    display: flex;
    align-items: center;
}

.NE_header_addImg img {
    width: 74px;
    height: 74px;
    margin-right: 10px;
}



.NE_text {
    font-weight: bolder;
    font-size: 24px;
    text-align: left;
    letter-spacing: 0px;
    color: #2C2738;
}

.NE_textArea {
    margin-bottom: 64px;
}

.NE_label {
    margin-top: 20px;
    margin-bottom: 39px;
}

@media screen and (max-width: 1440px) {
    .right {
        width: 0;
    }
    .left {
        display: flex;
        left: 0;
        background-color: #fff;
        justify-content: center;
        width: 100%;
    }
}