.NE_toggle_bar_button{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}

.NE_toggle_bar_button .toggle_button{
    cursor: pointer;
}

.NE_toggle_bar_button .actions_bar{
    display: none;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: var(--primary-color);
    border-radius: 7px;
    position: relative;
    margin-top: 20px;
    padding: 14px 30px;
}

.NE_toggle_bar_button .actions_bar::after{
    content: '';
    display: block;
    border: 10px solid transparent;
    border-bottom-color: var(--primary-color);
    position: absolute;
    top: -20px;
    left: calc(50% - 10px);
}

.NE_toggle_bar_button .actions_bar .action{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
}

.NE_toggle_bar_button .actions_bar .action img{
    margin-right: 8px;
}

.NE_toggle_bar_button .actions_bar .action p{
    font-size: 20px;
    font-weight: 500;
    letter-spacing: -0.24px;
    color: #fff;
}

.NE_toggle_bar_button_open .actions_bar{
    display: flex;
}