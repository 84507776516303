.moderator_add_moderator_modal_wrapper{
    display: none;
    justify-content: center;
    align-items: center;
    position: fixed;
    top:0;
    left:0;
    bottom: 0;
    width: 100%;
    background-color: rgba(44, 39, 56, 0.6);
}

.moderator_add_moderator_modal_open{
    display: flex;
}

.moderator_add_moderator_modal_wrapper .modal_body{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    /*width: 100%;*/
    margin: 0 auto;
    /*padding-top: 76px;*/
}

.moderator_add_moderator_modal_wrapper .modal_body .create_course_form_wrapper{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 1134px;
    background-color: #fff;
    box-shadow: 0px 16px 56px #2C273814;
    border-radius: 9px;
    padding: 46px 34px;
}

.moderator_add_moderator_modal_wrapper .modal_body .create_course_form_wrapper > h1{
    font-size: 28px;
    font-weight: bold;
    color: #2C2738;
}

.moderator_add_moderator_modal_wrapper .modal_body .create_course_form_wrapper form{
    margin-top: 33px;
    width: 100%;
}

.moderator_add_moderator_modal_wrapper .modal_body .create_course_form_wrapper .form-field{
    width: 100%;
    margin-bottom: 18px;
}

.moderator_add_moderator_modal_wrapper .modal_body .create_course_form_wrapper .form-field:last-child{
    margin-bottom: 0px;
}

.moderator_add_moderator_modal_wrapper .modal_body .create_course_form_wrapper .form-field > .NE_normal_textarea textarea{
    min-height: 120px;
    resize: vertical;
}

.moderator_add_moderator_modal_wrapper .modal_body .create_course_form_wrapper .form-actions{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 34px;
}

.moderator_add_moderator_modal_wrapper .modal_body .create_course_form_wrapper .form-action{
    width: 190px;
    font-size: 20px
}

.moderator_add_moderator_modal_wrapper .modal_body .create_course_form_wrapper .form-action:first-child{
    margin-right: 24px;
}

