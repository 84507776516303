.NE_etudiant_quiz_course{
   background-color: #3B5998;
    height: auto !important;
}
.NE_etudiant_quiz_course .NE_QUIZ {
    display: flex;
    flex-direction: column;
    /*align-items: center;*/
    /* width: 1402px; */
    height: 904px;
    /*background: #FFFFFF 0% 0% no-repeat padding-box;*/
    box-shadow: 0px 16px 56px #2C273814;
    border-radius: 9px;
    overflow-y: scroll;
    overflow-x: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding-bottom: 20px;
}

.NE__QUIZ_course{
    display: flex;
    flex-direction: column;
    margin-right: 20px;
    margin-left: 40px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 2px solid #F5F6FA;
    border-radius: 6px;
    margin-top: 21px;
}

.NE__QUIZ_course{
    display: flex;
    flex-direction: column;
    margin-right: 20px;
    margin-left: 40px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 2px solid #F5F6FA;
    border-radius: 6px;
    margin-top: 21px;
}


.NE_etudiant_quiz_course {
    display: flex;
    flex-direction: column;
    width: 95% !important;
    height: 904px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 16px 56px #2c273814;
    border-radius: 9px;
    margin-left: 21px;
}

.NE_CourseSideBar{
    padding: 10px;
}

.NE_etudiant_quiz_course .NE_notionItem_header{
  width: 95% !important;
    margin-left: 40px;
    margin-top: 28px;
}

.NE_etudiant_quiz_course .NE_QUIZ::-webkit-scrollbar{
    /* display: none; */
    /* ===== Scrollbar CSS ===== */
  /* Firefox */
  
}
.NE_etudiant_quiz_course .NE_header_text {
    width: 100%;
    margin-top: 48px;
    margin-bottom: 30px;
    margin-left: 21px;
}

.NE_etudiant_quiz_course .NE_header_text h2 {
    font: normal normal bold 28px/60px IBM Plex Sans;
    letter-spacing: 0px;
    color: #2C2738;
}

.NE_etudiant_quiz_course .NE__ {
    display: flex;
    flex-direction: column;
    /* width: 1360px; */
    height: 344px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 2px solid #F5F6FA;
    border-radius: 6px;
    margin-bottom: 16px;
}

.NE_etudiant_quiz_course .NE_body_content {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    margin-top: 30px;
}
.NE_etudiant_quiz_course .NE_body_content .NE_body_paragraph {
    margin-top: 16px;
    /* width: 1140px; */
    height: auto;
}

.NE_body_content h6 {
    font: normal normal 600 16px/20px IBM Plex Sans;
    letter-spacing: 0px;
    color: #989898;
    margin-bottom: 16px;
}

 .NE_body_content p {
    font: normal normal 600 25.6px/33px IBM Plex Sans;
    letter-spacing: 0px;
    color: #2C2738;
     margin-bottom: 20px;
}

.NE_etudiant_quiz_course .NE_normal_radiobutton .NE_checked_radiobutton{
    margin: 0;
}

.NE_etudiant_quiz_course .NE_align_items {
    display: flex;
    align-items: center;
    height: 48px;
}

.NE_etudiant_quiz_course .NE_align_items h4{
    font: normal normal 500 20px/20px IBM Plex Sans;
    letter-spacing: 0px;
    color: #3F4351;
}


.NE_etudiant_quiz_course .NE_etudiant_quiz_course {
    display: flex;
    flex-direction: column;
    /* align-items: center;
    justify-content: center; */
    width: auto;
    flex: 1;
    height: 904px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 16px 56px #2C273814;
    border-radius: 9px;
    margin-left: 21px;
}

.NE_etudiant_quiz_course .NE_etudiant_quiz_course .NE_etudiant_header {
    margin-top: 43px;
    margin-left: 42px;
    width: 100%;
    height: 37px;
    margin-bottom: 30px;
}

.NE_etudiant_quiz_course .NE_etudiant_quiz_course .NE_etudiant_header p{
    font-size: 28px;
    font-weight: bold;
    letter-spacing: 0px;
    color: #2C2738;
}


.NE_etudiant_quiz_course .NE_etudiant_quiz_course .NE_body_doc {
    /* width: 1253px; */
    height: 571px;
    /* background-color: #2C2738; */
}


.NE_etudiant_quiz_course .NE_etudiant_quiz_course .NE_body_doc .NE_inline_items {
    display: flex;
    align-items: center;
}

.NE_etudiant_quiz_course .NE_radio_button {
    margin-left: 20px;
    /* margin-top: 30px; */
    margin-bottom: 30px;
}
