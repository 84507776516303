.NE_draft_editor {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    height: 286px;
}
.NE_draft_editor .wrapper-class {
    padding: 1rem;
    border: 1px solid #ccc;
    height: 286px;
}
.NE_draft_editor .editor-class {
    height: auto;
    padding: 1rem;

}
.NE_draft_editor .toolbar-class {
    border: 1px solid #ccc;
}