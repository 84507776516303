.NE_notionItem_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 16px 56px #2c273814;
  border-radius: 9px;
  overflow-y: scroll;
  overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding: 38px;
  padding-top: 10px;
  margin-bottom: 20px;
}
.NE_notionItem_container::-webkit-scrollbar {
  display: none;
}
.NE_notionItem_header {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
}

.NE_notionItem_header_h2 {
  font: normal normal bold 28px/60px IBM Plex Sans;
  letter-spacing: 0px;
  color: #2c2738;
  flex: 1;
}

.NE_notionItem_header_button {
  display: flex;
  align-self: flex-end;
  height: 100%;
  justify-content: space-between;
  width: 420px;
  align-items: center;
}

.NE_notionItem_body_video {
  width: 100%;
}

.NE_notionItem_body_video iframe {
  margin-top: 28px;
  /* margin-left: 38px; */
  /* margin-bottom: 52px; */
}

.NE_notionItem_container .active_button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 205px;
  height: 48px;
  background: var(--primary-color) 0% 0% no-repeat padding-box;
  box-shadow: 0px 24px 48px #2c273814;
  border-radius: 6px;
  cursor: pointer;
}
.NE_notionItem_container .disabled_button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 205px;
  height: 48px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 2px solid var(--primary-color);

  box-shadow: 0px 24px 48px #2c273814;
  border-radius: 6px;
  cursor: pointer;
}

.NE_notionItem_container .active_button span {
  font: normal normal bold 20px/32px IBM Plex Sans;
  letter-spacing: 0px;
  color: #ffffff;
}

.NE_notionItem_container .disabled_button span {
  font: normal normal bold 20px/32px IBM Plex Sans;
  letter-spacing: 0px;
  color: var(--primary-color);
}

.NE_notionItem_header_text {
  width: 100%;
}

.NE_notionItem_header_text h2 {
  font: normal normal bold 28px/60px IBM Plex Sans;
  letter-spacing: 0px;
  color: #2c2738;
  margin-top: 28px;
  margin-left: 10px;
}

.NE_notionItem_header_text .p_text {
  /* font: normal normal bold 28px/60px IBM Plex Sans; */
  letter-spacing: 0px;
  color: #2c2738;
  margin-bottom: 28px;
  margin-left: 10px;
  margin-right: 10rem;
}

