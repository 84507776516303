.NE_collapsable_card_with_icon{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 4px 8px #2C27380A;
    border: 1px solid #DBE2EA;
    border-radius: 9px;
    padding: 30px 34px;
}

.NE_collapsable_card_with_icon > .header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.NE_collapsable_card_with_icon > .header .title{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.NE_collapsable_card_with_icon > .header .title img{
    width: 23px;
    height: 25px;
    margin-right: 11px;
}

.NE_collapsable_card_with_icon > .header .title h2{
    font-size: 28px;
    font-weight: bold;
    color: #2C2738;
}

.NE_collapsable_card_with_icon > .header .btn_collapse{
    display: flex;
    width: 30px;
    height: 30px;
    background-image: url('assets/icon-chevron-down.svg');
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    justify-self: flex-end;
}

.NE_collapsable_card_with_icon > .body{
    display: none;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    padding: 33px 11px;
}

.NE_collapsable_card_with_icon_open > .header{
    padding-bottom: 23px;
}

.NE_collapsable_card_with_icon_open > .header .btn_collapse{
    transform: rotate(180deg);
}

.NE_collapsable_card_with_icon_open > .body{
    display: flex;
}

.quiz_creation_card .body form{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}

.quiz_creation_card .body form .form-field{
    width: 100%;
}

.quiz_creation_card .body form .form-field .question{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 15px;
}

.quiz_creation_card .body form .form-field .question:last-child{
    margin-bottom: 0;
}

.quiz_creation_card .body form .form-field .question .question_options{
    margin-top: 18px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    list-style: none;
}

.quiz_creation_card .body form .form-field .question .question_options li{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 14px 15px 14px 20px;
    margin-bottom: 7px;
    border-radius: 8px;
}

.quiz_creation_card .body form .form-field .question .question_options li > .NE_normal_input{
    display: none;
}

.quiz_creation_card .body form .form-field .question .question_options li:last-child{
    margin-bottom: 0;
}

.quiz_creation_card .body form .form-field .question .question_options li .question_option_action{
    display: flex;
    width: 29px;
    height: 29px;
    background-image: url('assets/icon-trash.svg');
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
}

.quiz_creation_card .body form .form-field .question .question_options li.question_option_selected{
    background-color: rgba(20, 163, 139, 0.16);
}

.quiz_creation_card .body form .form-field .question .question_options li.question_option_selected .question_option_action{
    background-image: url('assets/icon-check.svg');
    cursor: default;
}

.quiz_creation_card .body form .form-field .question .question_options li.add_question_option{
    justify-content: flex-start;
    font-size: 20px;
    font-weight: 500;
    color: #3F4351;
    width: fit-content;
    cursor: pointer;
}

.quiz_creation_card .body form .form-field .question .question_options li.add_question_option::before{
    content: '';
    display: block;
    width: 24px;
    height: 24px;
    background-image: url('assets/add-small.svg');
    margin-right: 10px;
}

.quiz_creation_card .body form .add_question{
    display: block;
    width: 50px;
    height: 50px;
    background-image: url('assets/add-big.svg');
    cursor: pointer;
    margin-top: 16px;
}

.quiz_creation_card .body form .actions{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 33px;
}

.quiz_creation_card .body form .action{
    margin-right: 8px;
}

.quiz_creation_card .body form .action .NE_button_large{
    width: 180px;
}

.quiz_creation_card .body form .action:last-child{
    margin-right: 0;
}

.quiz_creation_card .body form .form-field .question .question_options li.question_option_edit_mode > .NE_normal_input{
    display: flex;
}

.quiz_creation_card .body form .form-field .question .question_options li.question_option_edit_mode .NE_radio_button{
    display: none;
}

.quiz_creation_card .body form .form-field .question .question_options li.question_option_edit_mode .question_option_action{
    display: none;
}

.mt-2{
  margin-top: 4em;
}


.question_option_action_valid{
    background-image: url('../../../configure-course/assets/icon-check.svg');
    cursor: default;
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    background-position: center;
}

.question_option_action_trash{
    display: flex;
    width: 29px;
    height: 29px;
    background-image: url('../../../configure-course/assets/icon-trash.svg');
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
}
.delete_element_content{
    display: flex;
}

.btn_delete_element{
    background-image: url('../../../configure-course/assets/icon-trash.svg');
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
}

.block_elemnt{
    display: flex;
    justify-content: space-between;
}
