
.client_details_page .page_header{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}

.client_details_page .page_body{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 1490px;
    height: calc(100vh - 60px);
    margin-left: auto;
    margin-right: auto;
    padding-top: 20px;
    padding-bottom: 18px;
}

.client_details_page .page_body .page_content{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: calc(100% - 18px - 296px);
    height: 100%;
    background-color: #fff;
    box-shadow: 0px 24px 48px #2C27380A;
    border-radius: 24px;
    padding: 27px 32px;
    overflow-y: scroll;
}

.client_details_page .page_body .page_content .section_title{
    font-size: 28px;
    font-weight: bold;
    color: #2C2738;
    margin-bottom: 14px;
}

.client_details_page .page_body .page_content .section_description{
    font-size: 16px;
    font-weight: normal;
    color: #2C2738;
    max-width: 341px;
}

.client_details_page .page_body .page_content .moderators_list{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    /*max-height: 182px;*/
    margin-top: 38px;
    margin-bottom: 15px;
    /* overflow-y: scroll; */
    /* overflow-x: hidden; */
}

.client_details_page .page_body .page_content .moderators_list::-webkit-scrollbar{
    width: 10px;
}

.client_details_page .page_body .page_content .moderators_list::-webkit-scrollbar-track{
    background-color: #DBE2EA;
    border-radius: 6px;
}

.client_details_page .page_body .page_content .moderators_list::-webkit-scrollbar-thumb{
    background-color: var(--primary-color);
    border-radius: 6px;
}

.client_details_page .page_body .page_content .moderators_list .moderator_item{
    width: calc(100% - 5px);
    margin-bottom: 10px;
}

.client_details_page .page_body .page_content .moderators_list .moderator_item:last-child{
    margin-bottom: 0;
}

.client_details_page .page_body .page_content .business_games_list{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    overflow-x: scroll;
    /* overflow-y: hidden; */
    margin-top: 23px;
    flex-shrink: 0;
    padding: 5px 0;
}

.client_details_page .page_body .page_content .business_games_list::-webkit-scrollbar{
    height: 10px;
}

.client_details_page .page_body .page_content .business_games_list::-webkit-scrollbar-track{
    background-color: #DBE2EA;
    border-radius: 6px;
}

.client_details_page .page_body .page_content .business_games_list::-webkit-scrollbar-thumb{
    background-color: var(--primary-color)rimary-color);
    border-radius: 6px;
}


.client_details_page .page_body .page_content .business_games_list .business_game_item{
    margin-right: 23px;
}

.client_details_page .page_body .page_content .business_games_list .business_game_item:last-child{
    margin-right: 0;
}
