.configure_course_modal_wrapper{
    display: none;
    justify-content: center;
    align-items: center;
    position: fixed;
    top:0;
    left:0;
    bottom: 0;
    width: 100%;
    background-color: rgba(44, 39, 56, 0.6);
}

.configure_course_modal_open{
    display: flex;
}

.configure_course_modal_wrapper .NE_body {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    width: 1048px;
    height: 781px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 32px 64px #2C273814;
    border: 1px solid #707070;
    height: 879px;
    border-radius: 16px;
}

.configure_course_modal_wrapper .NE_text {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.configure_course_modal_wrapper .NE_text_body {
    /*
        font-weight: 500;
        letter-spacing: -0.24px; */

    margin-top: 26px;
    margin-bottom: 35px;
    margin-left: 42px;
    margin-right: 42px;
    font-size: 34px;
    text-align: left;
    letter-spacing: -0.34px;
    color: #2C2738;
    font-weight: 600;
}


.configure_course_modal_wrapper .form-actions {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    position: absolute;
    bottom: 40px;
    right: 37px;
}
.collaps-content{
    /*background-color: #2B2737;*/
    height: 66%;
    overflow-y: auto;
}

.configure_course_modal_wrapper .form-action {
    width: 180px;
    height: 56px;
    margin-right: 8px;
}

.configure_course_modal_wrapper .NE_text_header {
    display: flex;
    justify-content: space-between;
}

.configure_course_modal_wrapper .NE_search_bar {
    /* width: 100%; */
    width: 933px;
    height: 40px;
    margin-left: 50px;
    margin-bottom: 35px;
}

.configure_course_modal_wrapper .NE_body_content {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    /* gap: 23px 21px; */
    width: 933px;
    max-height: 600px;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    /* margin: 0 auto; */
}

.configure_course_modal_wrapper .NE_body_content::-webkit-scrollbar{
    display: none;
}

.configure_course_modal_wrapper .NE_module_evaluation_card {
    width: 296px;
    height: 361px;
    margin-right: 23px;
    margin-bottom: 21px;
    cursor: pointer;
}

.comment_date{
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 14px;
    color: #989898;
}

.NE_business_game_card .card_body {
    margin-top: 10px;
    padding: 0;
}

.card_body_clone p{
    font-size: 14px;
    color: #2C2738;
    font-weight: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: 1.5em;

}

.card_body_clone h1{
    font-size: 18px;
    color: #2C2738;
    font-weight: bold;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;

}

.NE_business_game_card {
    height: 408px;
    width: 296px;
}

.NE_business_game_card .card_header .card_image img {
    object-fit: fill;
}
.NE_business_game_card .card_header .card_image  {
   position: relative;
}
.NE_business_game_card .card_header .category  {
   position: absolute;
    top: 8px;
    left: 8px;
    padding: 5px 10px;
    background: #FC7D49;
    box-shadow: 0px 2px 4px #2C273814;
    border-radius: 13px;

    text-align: left;
    letter-spacing: 0px;
    color: #FFFFFF;
}

.card_footer{
    flex: 1;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    width: 100%;
    margin-top: 6px;
    align-items: flex-end;
    margin-bottom: 7px;
}

.card_footer .number{
    font: normal normal 600 20px/26px IBM Plex Sans;
    letter-spacing: -0.2px;
    color: #000000;
    margin-left: 4px;
    margin-right: 4px;
}

.card_footer .text_{
    font: normal normal normal 16px/24px IBM Plex Sans;
    letter-spacing: -0.1px;
    color: #616161;
}

.form-actions_mt{
    display: flex;
    justify-content: flex-end;
    padding-right: 56px;

}

.mr-0 {
    margin-right: 0 !important;
}

.NE_button_dark{
    font-size: 18px;
    border: none;
    background-color: var(--primary-color);
    color: #EBF4F8;
    box-shadow: 0px 4px 8px #2c273814;
}
