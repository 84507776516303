.NE_won-modal-container_points{
    /*display: none; !* Hidden by default *!*/
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

.modal_body_won{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 2em;
}

.NE_reward_modal{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 848px;
    background: var(--gradient-color);
    border-radius: 16px;
    padding: 67px 53px 50px 53px;
    /* text-align: center; */
}

.NE_reward_modal h1{
    font-size: 44px;
    font-weight: 600;
    color: #fff;
    margin-bottom: 26px;
    /* text-align: center; */
}

.NE_reward_modal p{
    font-size: 20px;
    font-weight: normal;
    color: #fff;
    margin-bottom: 40px;
}

.NE_reward_modal button{
    border: none;
    box-shadow: 0px 24px 48px #2C273814;
    border-radius: 6px;
    width: 183px;
    height: 63px;
    background-color: #fff;
    font-size: 19px;
    font-weight: bold;
    color: var(--primary-color);
    cursor: pointer;
    margin-left: 7px;
}

