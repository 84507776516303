.NE_radio_avatar{
    width: fit-content;
    height: fit-content;
}

.NE_radio_avatar input[type=radio]{
    display: none;
}

.NE_radio_avatar label{
    display: block;
    position: relative;
}

.NE_radio_avatar label > img{
    border: 2px solid #707070;
    border-radius: 4px;
}

.NE_radio_avatar label .checkbox_container{
    display: none;
}

.NE_radio_avatar label .checkbox_container .checkbox{
    width: 100%;
    height: 100%;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #707070;
}

.NE_radio_avatar input[type=radio]:checked + label .checkbox_container{
    position: absolute;
    top: 0;
    left: 0;
    width: 30px;
    height: 30px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid var(--primary-color);
    border-radius: 4px;
}

.NE_radio_avatar input[type=radio]:checked + label > img{
    border: 5px solid var(--primary-color)rimary-color);
}