.NE_element_progress_overview {
  width: 1123px;
  height: 713px;
  background: #ffffff;
  border: 2px solid #f5f6fa;
  border-radius: 6px;
  padding: 29px 28px 30px 39px;
  overflow-x: hidden;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.NE_element_progress_overview::-webkit-scrollbar {
  display: none;
}

.NE_element_progress_overview .average_progress {
  // margin-bottom: 30px;
  width: 100%;
  background: var(--gradient-color);
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 14px;
  /*padding: 31px 27px 36px 16px;*/
  height: 88px;
  padding: 29px 17px 32px 23px;
}

.NE_element_progress_overview .average_progress .progress_infos {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 24px;
  font-weight: normal;
  letter-spacing: -0.24px;
  color: #ffffff;
  margin-bottom: 5px;
}

.NE_element_progress_overview .average_progress .progress_infos .title {
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.24px;
  color: #ffffff;
}

.NE_element_progress_overview .average_progress .progress_infos .percentage {
  font-size: 24px;
  font-weight: bold;
  letter-spacing: -0.34px;
  color: #ffffff;
}

.NE_element_progress_overview .average_progress .progress_bar {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 9px;
  background-color: #f6f5f5;
  border-radius: 7px;
}

.NE_element_progress_overview .average_progress .progress_bar .progress_level {
  height: 9px;
  background: var(--primary-color);
  border-radius: 7px;
}

.NE_element_progress_overview .question_progress {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 28px;
  width: 100%;
  height: 88px;
  background: #ffffff;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 14px;
  padding: 29px 17px 32px 23px;
}

.NE_element_progress_overview .question_progress:last-child {
  margin-bottom: 0;
}

.NE_element_progress_overview .question_progress .question_number {
  font-size: 20px;
  font-weight: 500;
  color: #000;
  letter-spacing: -0.2px;
}

.NE_element_progress_overview .question_progress .percentage {
  font-size: 24px;
  font-weight: bold;
  color: #2c2738;
  letter-spacing: -0.24px;
}

.NE_element_progress_overview .question_progress .progress_bar {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 80%;
  height: 9px;
  background: #f6f5f5;
  border-radius: 7px;
}

.NE_element_progress_overview .question_progress .progress_level {
  height: 9px;
  background: var(--secondary-color);
  border-radius: 7px;
}

.NE_business_game_content_content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.NE_business_game_content {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  gap: 3px;
  margin-bottom: 10px;

  img {
    width: 50px;
    aspect-ratio: 1/1;
  }

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    background: var(--gradient-color);
    box-shadow: 0px 4px 8px #2c273814;
    border-radius: 6px;
    cursor: pointer;
  }
}