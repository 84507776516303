.NE_business_game_card{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 255px;
    height: 332px;
    /* margin-left: 35px; */
    background-color: #F8FBFF;
    padding: 13px 16px;
    border-radius: 24px;
    box-shadow: 0px 4px 8px #2C273814;
    border: 1px solid #DBE2EA;
    cursor: pointer;
    position: relative;
}

.NE_business_game_card .card_header{
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.NE_business_game_card .card_header .card_image{
    display: block;
    width: 100%;
    height: 200px;
}

.NE_business_game_card .card_header .card_image img{
    border-radius: 16px;
    width: 100%;
    height: 200px;
    object-fit: contain;
}

.NE_business_game_card .card_body{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 26px;
    padding: 0 4px;
}

.NE_business_game_card .card_body h1{
    font-weight: bold;
    font-size: 22px;
    color: #2C2738;
    margin-bottom: 8px;
}

.NE_business_game_card .card_body p{
    font-size: 14px;
    color: #2C2738;
}

.NE_business_game_card_selected{
    border: 4px solid #7155DF;
    padding: 10px 13px;
}

.NE_business_game_card .card_body .counter_wrapper{
    display: none;
}

.NE_business_game_card .card_body .counter_wrapper > p{
    font-size: 24px;
    font-weight: bold;
    color: #2C2738;
    margin-bottom: 15px;
}

.NE_business_game_card_selected .card_body .counter_wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(248, 251, 255, 0.87);
    border-radius: 24px;
}