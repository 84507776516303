.NE_moderator_card{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 270px;
    height: 361px;
    background-color: #F8FBFF;
    padding: 13px 16px;
    border-radius: 24px;
    box-shadow: 0px 4px 8px #2C273814;
    border: 1px solid #DBE2EA;
    cursor: pointer;
    position: relative;
}

.NE_moderator_card .card_header{
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.NE_moderator_card .card_header .card_image{
    display: flex;
    justify-content: center;
    width: 100%;
    height: 200px;
}

.NE_moderator_card .card_header .card_image img{
    border-radius: 100%;
    height: 200px;
    border: 1px solid black;
}

.NE_moderator_card .card_body{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: 26px;
    padding: 0 4px;
}

.NE_moderator_card .card_body h1{
    font-weight: bold;
    font-size: 28px;
    color: #2C2738;
    margin-bottom: 8px;
}

.NE_moderator_card .card_body p{
    font-size: 16px;
    color: #2C2738;
}

.NE_moderator_card_selected{
    border: 4px solid #7155DF;
    padding: 10px 13px;
}
