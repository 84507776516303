.NE_icon_input {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
}

.NE_icon_input label {
    font-size: 20px;
    font-weight: 500;
    color: #3f4351;
    margin-bottom: 6px;
}

.NE_icon_input input {
    /* width: 100%; */
    height: 52px;
    /* width: 877px; */
    border: 1px solid #dbe2ea;
    border-radius: 6px;
    box-shadow: 0px 4px 8px #2c27380a;
    padding: 16px 57px 16px 16px;
    font-size: 16px;
    color: #2c2738;
}

#search_business-game{
    width: 877px;
}
#search_moderateur{
    width: 892px;
}

.NE_icon_input input::placeholder{
    font-size: 18px;
    color: #989898;
}

.NE_icon_input input:focus{
    border: 2px solid #68A4F5;
    padding: 15px 56px 15px 15px;
}

.NE_icon_input input:disabled{
    background-color: #ebf4f8;
    color: #989898;
}

.NE_icon_input p {
    display: none;
    font-size: 14px;
    margin-top: 6px;
}

.NE_icon_input .input_wrapper{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    position: relative;
}

.NE_icon_input .input_wrapper img{
    position: absolute;
    right: 32px;
    width: fit-content;
    height: fit-content;
}

.NE_icon_input_success p{
    display: block;
    color: #14a38b;
}

.NE_icon_input_error p{
    display: block;
    color: #ff7171;
}