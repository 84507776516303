.NE_collapsable_card_with_icon{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 4px 8px #2C27380A;
    border: 1px solid #DBE2EA;
    border-radius: 9px;
    padding: 30px 34px;
}

.NE_normal_textarea.NE_normal_textarea_success.NE_normal_textarea_error > textarea{
    min-height: 97px !important;

}
.moderator_configure_course_page .notion_creation_card .body form .form-field{
    flex-direction: column;
    gap: 14px;
}


.NE_collapsable_card_with_icon > .header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.NE_collapsable_card_with_icon > .header .title{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.NE_collapsable_card_with_icon > .header .title img{
    width: 23px;
    height: 25px;
    margin-right: 11px;
}

.NE_collapsable_card_with_icon > .header .title h2{
    font-size: 28px;
    font-weight: bold;
    color: #2C2738;
}

.NE_collapsable_card_with_icon > .header .btn_collapse{
    display: flex;
    width: 30px;
    height: 30px;
    background-image: url('assets/icon-chevron-down.svg');
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    justify-self: flex-end;
}

.NE_collapsable_card_with_icon > .body{
    display: none;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    padding: 33px 11px;
}

.NE_collapsable_card_with_icon_open > .header{
    padding-bottom: 23px;
}

.NE_collapsable_card_with_icon_open > .header .btn_collapse{
    transform: rotate(180deg);
}

.NE_collapsable_card_with_icon_open > .body{
    display: flex;
}


.switch-btn{
    margin-right: 3em;
    padding-top: 5px;
}

.header .title .arrow{
    border: 1px solid red;
    border-radius: 20%;
    /*padding: 10px;*/
}

.header .title .arrow:hover{
    border: 1px solid var(--primary-color);
    background-color:var(--primary-color);

}


.header .title .arrow:hover svg{
    border: 1px solid var(--primary-color);
    background-color:var(--primary-color);

}

.d-flex{
    display: flex;
}

.hidden{
    visibility: hidden;
}
