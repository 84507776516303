.NE_toggle_button{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.NE_toggle_button .switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.NE_toggle_button .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.NE_toggle_button .switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 4px 8px #2C273814;
  border: 1px solid #DBE2EA;
  -webkit-transition: .4s;
  transition: .4s;
}

.NE_toggle_button .switch .slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 3px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 4px 8px #2C273833;
  border: 1px solid #DBE2EA;
  -webkit-transition: .4s;
  transition: .4s;
}

.NE_toggle_button input:checked + .slider {
  background-color: var(--primary-color);
}

.NE_toggle_button input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.NE_toggle_button .slider.round {
  border-radius: 34px;
}

.NE_toggle_button .slider.round:before {
  border-radius: 50%;
}

.NE_toggle_button input:disabled {
  background-color: var(--primary-color);
}


.NE_toggle_button .NE_normal_toggle {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 52px;
  height: 30px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 4px 8px #2C273814;
  border: 1px solid #DBE2EA;
  border-radius: 24px;
}

.NE_toggle_button .NE_toggle_button .circle {
  width: 24px;
  height: 24px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 4px 8px #2C273833;
  border: 1px solid #DBE2EA;
  margin-left: 2px;
  border-radius: 24px;
}

.NE_toggle_button .NE_active_toggle {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 52px;
  height: 30px;
  background: var(--primary-color) 0% 0% no-repeat padding-box;
  box-shadow: 0px 4px 8px #2C273814;
  border: 1px solid #DBE2EA;
  border-radius: 24px;
}

.NE_toggle_button .circle_active {
  width: 24px;
  height: 24px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 4px 8px #2C273833;
  margin-right: 2px;
  border-radius: 24px;
}

.NE_toggle_button .NE_disabled_toggle {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 52px;
  height: 30px;
  background: #EBF4F8 0% 0% no-repeat padding-box;
  box-shadow: 0px 4px 8px #2C27380A;
  border: 1px solid #DBE2EA;
  border-radius: 24px;
}

.NE_toggle_button .circle_disabled {
  width: 24px;
  height: 24px;
  background: #EBF4F8 0% 0% no-repeat padding-box;
  box-shadow: 0px 4px 8px #2C273814;
  border: 1px solid #DBE2EA;
  margin-left: 2px;
  border-radius: 24px;
}

.NE_toggle_button .NE_disabled_toggle_active {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 52px;
  height: 30px;
  background: #EBF4F8 0% 0% no-repeat padding-box;
  box-shadow: 0px 4px 8px #2C27380A;
  border: 1px solid #DBE2EA;
  border-radius: 24px;
}