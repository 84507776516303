.participant_list_page .page_header {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
  width: 100%;
  height: 354px;
  background-image: url("assets/header_bg2.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 40%;
}

.participant_list_page .page_body {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  /*width: 1640px;*/
  margin-top: -137px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 20px;

}

.participant_list_page .page_body .content {
  /*display: flex;*/
  /*flex-direction: row;*/
  /*align-items: flex-start;*/
  /*flex: 4;*/
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(266px, 1fr));

  /* grid-gap: 68px; */
  gap: 20px;
  width: 100%;
}

.participant_list_page .page_body .last_comments {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: fit-content;
  margin-left: 24px;
  flex: 1;
  height: calc(100vh - 220px);
}

.NE_won-modal-container {
  border-radius: 15px !important;
  background-color: #9370DB;
}

.container{
  padding:0;
}
