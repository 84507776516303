:root {
    --primary-color: #9370DB;
    --secondary-color:#c13be2;
    --gradient-color: linear-gradient(to bottom, #9370DB 30%, #c13be2);
}


* {
    scrollbar-width: auto;
    scrollbar-color: var(--primary-color) #e6e6e6;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 8px;
}

*::-webkit-scrollbar-track {
    background: #cfcccc;
}

*::-webkit-scrollbar-thumb {
    background-color: var(--primary-color);
    border-radius: 14px;
    /* border: 2px solid #ffffff; */
}

