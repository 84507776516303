.NE_students_list {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  width: 360px;
  height: 713px;
  border: 2px solid #f6f5f5;
  border-radius: 6px;
  background-color: #fff;
}

.NE_students_list .list_header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 30px;
  padding: 5px 20px;
  background: var(--primary-color);
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.NE_students_list .list_header p {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: -0.18px;
  color: #ffffff;
}

.NE_students_list .list_items {
  display: block;
  padding-top: 13px;
  width: 100%;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.NE_students_list .list_items::-webkit-scrollbar {
  display: none;
}

.NE_students_list .list_items .list_item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 68px;
  padding: 0 20px;
  margin-bottom: 12px;
  cursor: pointer;
}

.NE_students_list .list_items .list_item img {
  width: 42px;
  height: 42px;
  border-radius: 100%;
  margin-right: 13px;
}

.NE_students_list .list_items .list_item p {
  font-size: 18px;
  font-weight: bold;
  color: #2c2738;
  letter-spacing: -0.3px;
}

.NE_students_list .list_items .list_item:last-child {
  margin-bottom: 0;
}

.NE_students_list .list_items .list_item_active {
  background-color: #f1f8ff;
}

.NE_students_list .btn_affectation {
  font-size: 18px;
  font-weight: bold;
  border: none;
  background-color: var(--primary-color);
  color: #ebf4f8;
  box-shadow: 0px 4px 8px #2c273814;
  border-radius: 6px;
  margin: 0 5px;
  margin-top: auto;
  margin-bottom: 5px;
  padding: 5px;
  cursor: pointer;
  height: 45px;
}
