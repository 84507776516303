.NE_options_popup{
    display: flex;
    background-color: #fff;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #DBE2EA;
    border-radius: 8px;
    width: fit-content;
    min-width: 140px;
    padding: 8px;
}

.NE_options_popup ul{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
}

.NE_options_popup ul li{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    width: 100%;
    margin-bottom: 7px;
    border-bottom: 1px solid #D5D5D5;
    padding-bottom: 7px;
}

.NE_options_popup ul li:last-child{
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
}

.NE_options_popup ul li img{
    margin-right: 10px;
}

.NE_options_popup ul li p{
    font-size: 14px;
    font-weight: 500;
    color: #2C2738;
}