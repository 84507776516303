.participant_page_header{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}

.participant_page-body{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 1878px;
    margin: 0 auto;
    margin-top: 45px;
}

.participant_page-body .user_infos_score{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
}

.participant_page-body .user_infos_score .user_infos{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 320px;
    background-color: #fff;
    box-shadow: 0.78px 5px 20px #B0CAD11A;
    border-radius: 9px;
    padding: 0 45px 35px 46px;
}

.participant_page-body .user_infos_score .user_infos img{
    width: 76px;
    height: 76px;
    border-radius: 100%;
    border: 5px solid #fff;
    margin-top: -34px;
    box-sizing: content-box;
    box-shadow: 0 4px 8px rgba(44, 39, 56, 0.08);
}

.participant_page-body .user_infos_score .user_infos h1{
    font-size: 24px;
    font-weight: 600;
    color: #2C2738;
    line-height: 24px;
    margin-top: 10px;
}

.participant_page-body .user_infos_score .user_infos p{
    font-size: 16px;
    font-weight: 500;
    color: #989898;
    /*line-height: 16px;*/
    margin-top: 11px;
    text-align: center;
}

.participant_page-body .user_infos_score .user_scores{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    /*width: 1537px;*/
    width: 1200px;
    box-shadow: 0px 16px 56px #2C273814;
    border-radius: 9px;
    background-color: #fff;
    min-height: 201px;
    padding: 12px 32px 0 21px;
}

.participant_page-body .user_infos_score .user_scores h1{
    font-size: 28px;
    font-weight: bold;
    color: #2C2738;
}

.participant_page-body .user_infos_score .user_scores .NE_score_gage{
    padding-top: 41px;
}

.participant_page-body .user_metrics{
    display: flex;
    flex-direction: column;
    width: 100%;
    box-shadow: 0px 16px 56px #2C273814;
    border-radius: 9px;
    background-color: #fff;
    padding: 11px 17px 60px 32px;
    margin-top: 24px;
    position: relative;
}

.participant_page-body .user_metrics h1{
    font-size: 28px;
    font-weight: bold;
    color: #2C2738;
    margin-bottom: 21px;
}

.participant_page-body .user_metrics .metrics_slider{
    display: flex;
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding: 0 129px;
}

.participant_page-body .user_metrics .metrics_slider::-webkit-scrollbar{
    display: none;
}

.participant_page-body .user_metrics .metrics_slider .metric_card{
    display: block;
    width: 195px;
    height: 176px;
    background-color: #F9FBFE;
    border: 2px solid #F5F6FA;
    border-radius: 6px;
    margin-right: 20px;
    padding: 15px;
    flex-shrink: 0;
    cursor: pointer;
}

.participant_page-body .user_metrics .metrics_slider .metric_card:hover{
    background: transparent linear-gradient(90deg, #FFF4F2 0%, var(--secondary-color) 100%) 0% 0% no-repeat padding-box;
    border: none;
    padding: 17px;
}

.participant_page-body .user_metrics .metrics_slider .metric_card:hover > .metric_points > p:first-child{
    color: #fff;
}

.participant_page-body .user_metrics .metrics_slider .metric_card:hover > .metric_points > p:last-child{
    color: #fff;
}

.participant_page-body .user_metrics .metrics_slider .metric_card:hover > .description{
    display: none;
}

.participant_page-body .user_metrics .metrics_slider .metric_card:hover > .long_description{
    display: block;
    width: 100%;
    margin-top: 20px;
    font-size: 16px;
    font-weight: normal;
    color: #fff;
    white-space: pre-line;
}

.participant_page-body .user_metrics .metrics_slider .metric_card:last-child{
    margin-right: 0;
}

.participant_page-body .user_metrics .metrics_slider .slider_action{
    width: 115px;
    height: 176px;
    border: 2px solid #F5F6FA;
    border-radius: 6px;
    background-color: var(--primary-color);
    position: absolute;
    cursor: pointer;
}

.participant_page-body .user_metrics .metrics_slider .action_prev{
    background-image: url('assets/icon-chevron-right.svg');
    background-repeat: no-repeat;
    background-position: center;
    transform: rotate(180deg);
    left: 32px;
}

.participant_page-body .user_metrics .metrics_slider .action_next{
    background-image: url('assets/icon-chevron-right.svg');
    background-repeat: no-repeat;
    background-position: center;
    right: 17px;
}

.participant_page-body .user_metrics .metrics_slider .metric_card .metric_points{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.participant_page-body .user_metrics .metrics_slider .metric_card .metric_points > p:first-child{
    font-size: 49px;
    font-weight: bold;
    color: #2C2738;
    line-height: 33px;
}

.participant_page-body .user_metrics .metrics_slider .metric_card .metric_points > p:last-child{
    font-size: 25px;
    font-weight: bold;
    color: var(--secondary-color);
    text-transform: uppercase;
    line-height: 20px;
}

.participant_page-body .user_metrics .metrics_slider .metric_card > .description{
    display: block;
    width: 100%;
    margin-top: 20px;
    font-size: 24px;
    font-weight: bold;
    color: #3F4351;
    white-space: pre-line;
}

.participant_page-body .user_metrics .metrics_slider .metric_card > .long_description{
    display: none;
}

.participant_page-body .community_progress{
    width: 100%;
    box-shadow: 0px 16px 56px #2C273814;
    background-color: #fff;
    border-radius: 9px;
    padding: 28px 32px;
    margin-top: 25px;
}

.participant_page-body .community_progress .header{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    border-bottom: 2px solid #F5F6FA;
    /*padding-bottom: 23px;*/
}

.participant_page-body .community_progress .title{
    width: 721px;
    height: 60px;
    border-right: 2px solid #F5F6FA;
}

.participant_page-body .community_progress .title h1{
    font-size: 28px;
    font-weight: bold;
    color: #2C2738;
}

.participant_page-body .community_progress .header .metrics_titles{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 138px;
}

.participant_page-body .community_progress .header .metrics_titles .metric_title{
    margin-left: 228px;
    margin-top: 19px;
    font-size: 16px;
    font-weight: 500;
    color: #2C2738;
}

.participant_page-body .community_progress .header .metrics_titles .metric_title:first-child{
    margin-left: 0px;
}

.participant_page-body .community_progress .body{
    display: flex;
    width: 100%;
}

.participant_page-body .community_progress .body .users_progress_wrapper{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 376px;
    overflow-y: scroll;
    scrollbar-width: none;
}

.participant_page-body .community_progress .body .users_progress_wrapper::-webkit-scrollbar{
    display: none;
}

.participant_page-body .community_progress .body .users_progress_wrapper .user_progress_row{
    display: flex;
    flex-direction: row;
    width: 100%;
    /*height: 100px;*/
    flex-shrink: 0;
}

.participant_page-body .community_progress .body .users_progress_wrapper .user_progress_row:first-child .progress{
    padding-top: 50px;
}

.participant_page-body .community_progress .body .users_progress_wrapper .user_progress_row .progress{
    display: flex;
    flex-direction: row;
    width: 721px;
    border-right: 2px solid #F5F6FA;
}

.participant_page-body .community_progress .body .users_progress_wrapper .user_progress_row .metrics{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 138px;
}

.participant_page-body .community_progress .body .users_progress_wrapper .user_progress_row .metric{
    width: 365px;
    font-size: 24px;
    font-weight: 600;
    color: #2C2738;
}

.participant_page-body .community_progress .body .users_progress_wrapper .user_progress_row .metric:nth-child(2){
    width: 343px;
}

.participant_page-body .community_progress .body .users_progress_wrapper .user_progress_row .metric:last-child{
    width: fit-content;
}

.participant_page-body .community_progress .body .users_progress_wrapper .user_progress_row .progress{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 63px;
}

.participant_page-body .community_progress .body .users_progress_wrapper .user_progress_row .progress .user_rank{
    font-size: 24px;
    font-weight: 600;
    color: #2C2738;
    margin-right: 9px;
}

.participant_page-body .community_progress .body .users_progress_wrapper .user_progress_row .progress img{
    width: 64px;
    height: 64px;
    border-radius: 100%;
    margin-right: 7px;
}

.participant_page-body .community_progress .body .users_progress_wrapper .user_progress_row .progress .user_score_progress{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    padding-right: 96px;
}

.participant_page-body .community_progress .body .users_progress_wrapper .user_progress_row .progress .user_score_progress h1{
    font-size: 24px;
    font-weight: 600;
    color: #2C2738;
    margin-bottom: 10px;
}
