.NE_button_large{
    background-color: #fff;
    box-shadow: 0px 4px 8px #2C273814;
    border: 1px solid #DBE2EA;
    border-radius: 6px;
    padding: 0px 12px;
    font-size: 18px;
    font-weight: bold;
    color: #7C9CBF;
    cursor: pointer;
    height: 56px;
    width: 100%;
}

.NE_button_large:hover{
    border: none;
    padding: 0px 13px;
    box-shadow: 0px 24px 48px #2C273814;
}

.NE_button_large:active{
    border: 2px solid var(--primary-color);
    padding: 0px 11px;
    box-shadow: 0px 2px 4px #2C273814;
}

.NE_button_large:disabled{
    border: 1px solid #DBE2EA;
    box-shadow: 0px 4px 8px #2C273814;
    padding: 0px 12px;
    background-color: #EBF4F8;
    color: rgba(44, 39, 56, 0.24);
    cursor: default;
}

.NE_button_large_dark{
    border: none;
    background-color: var(--primary-color);
    color: #EBF4F8;
    box-shadow: 0px 4px 8px #2C273814;
}

.NE_button_large_dark:hover{
    box-shadow: 0px 24px 48px #2C273829;
    padding: 0px 12px;
}

.NE_button_large_dark:active{
    border: 2px solid #404350;
    padding: 0px 10px;
}

.NE_button_large_dark:disabled{
    border: none;
    background-color: #DBE2EA;
    padding: 0px 12px;
    color: rgba(44, 39, 56, 0.24);
    box-shadow: 0px 4px 8px #2C273814;
}

.NE_button_large_outline{
    border: 2px solid #7C9CBF;
    padding: 0px 11px;
    background-color: transparent;
    color:#7C9CBF;
}

.NE_button_large_outline:hover{
    border: 2px solid var(--primary-color);
    color: var(--primary-color);
    padding: 0px 11px;
}

.NE_button_large_outline:active{
    border: 2px solid #756F86;
    color: #756F86;
    padding: 0px 11px;
}


.NE_button_large_outline:disabled{
    border: 2px solid #2C27383D;
    color: rgba(44, 39, 56, 0.24);
    padding: 0px 11px;
    background-color: transparent;
}