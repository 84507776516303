

 .NE_business_game {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 9px;
  margin-left: 21px;
}

.content .NE_notionItem_container{
  margin-bottom:0px;
}
.NE_business_game img {
  margin-bottom: 16px;
}

.NE_business_game .textsize {
  text-align: center;
  width: 100%;
  height: 44px;
  margin-bottom: 60px;
}

.NE_business_game h1{
  font-size: 28px;
  font-weight: bold;
  text-align: center;
  letter-spacing: 0px;
  color: #2C2738;
  margin-bottom: 23px;
}

.NE_business_game p{
  /* margin-bottom: 60px; */
}

.NE_business_game .button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 56px;
  background: var(--gradient-color);
  box-shadow: 0px 4px 8px #2C273814;
  border-radius: 6px;
  cursor: pointer;
}


.NE_business_game .button p{
  font-size: 16px;
  letter-spacing: 0px;
  color: #FFFFFF;
}

.header_button {
  display: flex;
  justify-content: center;
  position: relative;
  top: 20px;
  width: 360px;
  height: 56px;
  border-radius: 6px;

}
.active_button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 179px;
  height: 56px;
  background: var(--primary-color) 0% 0% no-repeat padding-box;
  box-shadow: 0px 24px 48px #2C273814;
  border-radius: 6px;
  cursor: pointer;
}
.disabled_button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 179px;
  height: 56px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 24px 48px #2C273814;
  border-radius: 6px;
  cursor: pointer;
}

.header_button .active_button p {
  font: normal normal bold 24px/32px IBM Plex Sans;
  letter-spacing: 0px;
  color: #FFFFFF;
}

.header_button .disabled_button p {
  font: normal normal bold 24px/32px IBM Plex Sans;
  letter-spacing: 0px;
  color: #7C9CBF;
}
