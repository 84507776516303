.session_code_modal_wrapper{
    display: none;
    justify-content: center;
    align-items: center;
    position: fixed;
    top:0;
    left:0;
    bottom: 0;
    width: 100%;
    background-color: rgba(44, 39, 56, 0.6);
}

.session_code_modal_wrapper_open{
    display: flex;
}

.NE_code_session_modal{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 750px;
    background-color: #fff;
    border-radius: 16px;
    padding: 28px 36px 36px 43px;
}

.NE_code_session_modal .header{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    margin-bottom: 36px;
}

.NE_code_session_modal .header .btn_close{
    font-size: 24px;
    font-weight: 500;
    color: #000;
    cursor: pointer;
}

.NE_code_session_modal .body{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}

.NE_code_session_modal .body > img{
    margin-bottom: 33px;
}

.NE_code_session_modal .body > p{
    font-size: 24px;
    font-weight: 600;
    color: #2C2738;
    margin-bottom: 25px;
}

.NE_code_session_modal .body .session_code {
    margin-bottom: 59px;
}

.NE_code_session_modal .body .session_code p{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-size: 36px;
    line-height: 48px;
    font-weight: normal;
    letter-spacing: -1.2px;
    color: var(--primary-color);
}

.NE_code_session_modal .body .session_code p::after{
    content: '';
    display: block;
    width: 30px;
    height: 37px;
    background-image: url('assets/icon-copy.svg');
    background-repeat: no-repeat;
    background-position: center;
    margin-left: 21px;
    margin-top: 3px;
}

.NE_code_session_modal .body button{
    width: 180px;
}