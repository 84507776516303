

.doc_container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.doc_container .NE_inline_items {
  display: flex;
  align-items: center;
  width: 100%;
  margin-left: 20px;
}


.border_button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 34px;
  border: 2px solid var(--primary-color);
  border-radius: 3px;
}

/* .NE_body_doc  */
.NE_inline_items img {
  margin-left: 2px;
  margin-right: 30px;
}

/* .NE_body_doc */
.NE_inline_items .NE_inline_item_text {
  /* white-space: nowrap; */
  /* width: 170px;
  height: 23px; */
  margin-right: 100px;
  letter-spacing: 0px;
  color: #2C2738;
  font-size: 18px;
  font-weight: bold;
  flex:1;
}

/* .NE_body_doc  */
.NE_inline_items .NE_inline_item_size {
  /* margin-right: 520px; */
  font: normal normal medium 16px/20px IBM Plex Sans;
  letter-spacing: 0px;
  color: #989898;
}


/* .NE_body_doc  */
.NE_inline_items .NE_inline_item_size {
  /* margin-right: 450px; */
}

.normal_button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 34px;
  background: var(--primary-color) 0% 0% no-repeat padding-box;
  box-shadow: 0px 12px 24px #2C273829;
  border-radius: 3px;
  margin-left: 24px;
}


.doc_container .NE_hr_doc {
  margin-bottom: 35px;
  margin-top: 35px;
  margin-left: 33px;
  width: 100%;
  height: 0px;
  border: 2px solid #F5F6FA;
  background-color: #F5F6FA;
}
.NE_inline_items .border_button p {
  font: normal normal bold 16px/20px IBM Plex Sans;
  letter-spacing: 0px;
  color: var(--primary-color);
  cursor: pointer;
}

.NE_inline_items .normal_button p {
  font: normal normal bold 16px/20px IBM Plex Sans;
  letter-spacing: 0px;
  color: #EBF4F8;
  cursor: pointer;

}
