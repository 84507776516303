.input{

  height: 35px;
  margin-bottom: 0;
  border: 1px solid #dbe2ea;
  border-radius: 6px;
  box-shadow: 0px 4px 8px #2c27380a;
  padding: 16px 57px 16px 16px;
  font-size: 14px;
  color: #2c2738;
  flex: 1;

}

.block_{
  display: flex;
  width: 100%;
  align-items: center;
}

.NE_radio_button{
  display: flex;
  flex: 1;
  align-items: baseline;
  width: 100%;
  label{
    margin-left: 5px;
    flex: 1;
  }
}

.btn_save{
  display: flex;
  width: 29px;
  height: 29px;
  background-image: url(../ModuleCard/assets/save-file.png) !important;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
}

.btn_edit{
  display: flex;
  width: 29px;
  height: 29px;
  background-image: url(../ModuleCard/assets/editing.png) !important;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer !important;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
}
.body{
  form{
    .form_field{
      margin-bottom: 20px;
    }
    .form_field:not(:first-child){
      margin-top: 40px;
      border-top: 2px solid #F6F5F5;
      padding-top: 40px ;
    }
  }
}

.question_option{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 7px;
  padding: 14px 15px 14px 20px;
  border-radius: 8px;
}


.radio_item {
  display: inline-block;
  position: relative;
  padding: 0 6px;
  margin: 10px 0 0;
}

.radio_item input[type='radio'] {
  display: none;
}

.radio_item label {
  color: #666;
  font-weight: normal;
}

.radio_item label:before {
  content: " ";
  display: inline-block;
  position: relative;
  top: 5px;
  margin: 0 5px 0 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #DBE2EA;
  background-color: #FFFFFF;
  box-shadow: 0px 4px 8px #2C273814;

}

.radio_item input[type=radio]:checked + label:before {
  content: " ";
  display: inline-block;
  position: relative;
  top: 5px;
  margin: 0 5px 0 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #004c97;
  background-color: transparent;

}

.radio_item input[type=radio]:checked + label:after {
  border-radius: 50%;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 11px;
  left: 12px;
  content: " ";
  display: block;
  background: #004c97;
}

.question_option_action_valid{
  background-image: url('../../../configure-course/assets/icon-check.svg');
  cursor: default;
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  background-position: center;
}

.question_option_action_trash{
  display: flex;
  width: 29px;
  height: 29px;
  background-image: url('../../../configure-course/assets/icon-trash.svg');
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}

.block_elemnt{
  display: flex;
  justify-content: space-between;
}
