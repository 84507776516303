.create_element_form_wrapper{
  box-shadow:initial !important;
  //padding: 0px 34px !important;
}


.form_actions{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  flex-direction: row;
  margin-top: 50px;

  :nth-child(2){
    margin-right: 0px !important;
  }
}

.wrapper{
  display: flex;
  flex-direction: column;
  margin-top: 0 !important;

}
