.configure_course_modal_wrapper{
  z-index: 999;
}

.NE_body_{
  width: 810px;
  background: #FFFFFF;
  box-shadow: 0 32px 64px #2C273814;
  border: 1px solid #707070;
  height: 630px;
  border-radius: 16px;
  overflow-x: hidden;
}

._close_button{
&:hover{
  cursor: pointer;
}
}

.className_actions{
  position: initial !important;
  margin-top: 0 !important;
}
