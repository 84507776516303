.modal_add_client_wrapper{
    display: none;
    justify-content: center;
    align-items: center;
    position: fixed;
    top:0;
    left:0;
    bottom: 0;
    width: 100%;
    background-color: rgba(44, 39, 56, 0.6);
}

.modal_add_client_wrapper_open{
    display: flex;
}

.modal_add_client_wrapper .NE_modal{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 38px 59px 25px 59px;
    box-shadow: 0px 32px 64px #2C273814;
    background-color: #fff;
    border-radius: 16px;
    width: 1020px;
}

.modal_add_client_wrapper .NE_modal .modal_header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.modal_add_client_wrapper .NE_modal .modal_header .modal_title{
    font-size: 28px;
    font-weight: 600;
    letter-spacing: -0.28px;
    color: #2C2738;
}

.modal_add_client_wrapper .NE_modal .modal_header .modal_close{
    font-size: 24px;
    font-weight: 500;
    letter-spacing: -0.24px;
    color: #000000;
    cursor: pointer;
    margin-top: -16px;
}

.modal_add_client_wrapper .NE_modal .modal_body{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 36px;
    padding-bottom: 70px;
    width: 100%;
}

.modal_add_client_wrapper .NE_modal .modal_body form{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
}

.modal_add_client_wrapper .NE_modal .modal_body form .form-field{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 25px;
}

.modal_add_client_wrapper .NE_modal .modal_body form .form-field p.avatar_error{
    font-size: 14px;
    color: #ff7171;
    margin-top: 6px;
}

.modal_add_client_wrapper .NE_modal .modal_body form .form-field:last-child{
    margin-bottom: 0;
}

.modal_add_client_wrapper .NE_modal .modal_body form .form-field .add_picture{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}

.modal_add_client_wrapper .NE_modal .modal_body form .form-field .add_picture img{
    cursor: pointer;
    margin-right: 10px;
    width: 74px;
    height: 74px;
    border-radius: 100%;
}

.modal_add_client_wrapper .NE_modal .modal_body form .form-field .add_picture p{
    font-size: 24px;
    font-weight: bold;
    color: #2C2738;
}

.modal_add_client_wrapper .NE_modal .modal_footer{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
}

.modal_add_client_wrapper .NE_modal .modal_footer .actions{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: fit-content;
}

.modal_add_client_wrapper .NE_modal .modal_footer .action{
    width: 140px;
    margin-right: 16px;
}

.modal_add_client_wrapper .NE_modal .modal_footer .action:last-child{
    margin-right: 0;
}