.NE_social_button{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 332px;
    /*width: 45%;*/
    height: 52px;
    border-radius: 8px;
    cursor: pointer;
}

.NE_social_button .social_logo{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 57px;
    height: 52px;
    border-radius: 8px 0 0 8px;
}

.NE_social_button .social_logo img{
    width: 22px;
    height: 22px;
}

.NE_social_button .social_text{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    text-transform: uppercase;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    color: #FFFFFF;
    border-radius: 0 8px 8px 0;
}

.NE_facebook_social_button .social_logo{
    background-color: #263C68;
}

.NE_facebook_social_button .social_text{
    background-color: #3B5998;
}

.NE_google_social_button .social_logo{
    background-color: #C53126;
}

.NE_google_social_button .social_text{
    background-color: #F44336;
}