.NE_client_info_card {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 270px;
    min-height: 420px;
    background-color: #fff;
    padding: 16px 16px 19px 16px;
    border-radius: 24px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2), 0px 8px 16px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(255, 192, 203, 0);
    border: 1px solid transparent;
    cursor: pointer;
    transition: transform 0.2s ease-in-out, box-shadow 0.3s ease-in-out;
}

.NE_client_info_card:hover {
    transform: translateY(-3px);
    box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.2), 0px 16px 32px rgba(0, 0, 0, 0.1), 0px 0px 40px rgba(39, 4, 10, 0.7);
    border: 0.3px solid rgb(230, 74, 199);
}
.NE_client_info_card .header{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    position: relative;
}

.NE_client_info_card .header .actions_btn{
    width: 30px;
    height: 30px;
    background-image: url('assets/actions-icon.svg');
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    top: -14px;
    right: -12px;
    cursor: pointer;
}

.NE_client_info_card .header > img{
    width: 145px;
    height: 140px;
    border-radius: 100%;
    border: 3px solid #bc64eb;
    margin-bottom: 7px;
    padding: 10px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2), 0px 10px 22px rgba(0, 0, 0, 0.1), 0px 0px 20px rgba(39, 4, 10, 0.7);
}

.NE_client_info_card .header h1 {
    font-size: 26px;
    font-weight: bold;
    color: #2C2738;
    margin-top: 20px;
}

.NE_client_info_card .header p {
    font-size: 16px;
    font-weight: normal;
    color: #989898;
    text-align: center;
}

.NE_client_info_card .header .actions_wrapper {
    position: absolute;
    top: -10px;
    right: 10px;
    display: none;
}

.NE_client_info_card .header .actions_wrapper_open {
    display: block;
}

.NE_client_info_card .body{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-top: 13px;
}

.NE_client_info_card .body .phone{
    width: fit-content;
    margin-bottom: 19px;
}

.NE_client_info_card .body .phone p{
    font-size: 18px;
    font-weight: bold;
    color: #9b0587;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.NE_client_info_card .body .phone p::before{
    content: '';
    display: block;
    background-image: url("assets/icon-phone.svg");
    background-repeat: no-repeat;
    width: 15px;
    height: 15px;
    margin-right: 7px;
}

.NE_client_info_card .body .address{
    width: fit-content;
}

.NE_client_info_card .body .address p{
    font-size: 16px;
    color: #9F9F9F;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    line-height: 25px;
}

.NE_client_info_card .body .address p::before{
    content: '';
    display: block;
    background-image: url("assets/icon-address.svg");
    background-repeat: no-repeat;
    width: 34px;
    height: 16px;
    margin-right: 16px;
    margin-top: 3px;
}