.terms_modal_wrapper {
    display: none;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    position: fixed;
    top:0;
    left:0;
    bottom: 0;
    width: 100%;
    background-color: rgba(44, 39, 56, 0.6);
    z-index: 1;
}


.terms_modal_wrapper_open {
    display: flex;
}


.NE_terms_elements_wrapper {
    overflow: hidden;
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 650px;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.NE_terms_elements_wrapper::-webkit-scrollbar{
    display: none;
}



.terms_modal_wrapper .terms_modal_body {
    display: flex;
    justify-content: center;
    align-items: center;
    /*width: 100%;*/
    width: 1048px
}

.terms_modal_wrapper .terms_modal_body .form_wrapper{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    /*width: 70%;*/
    background-color: #fff;
    box-shadow: 0px 16px 56px #2C273814;
    border-radius: 9px;
    padding: 46px 34px;
}