.moderator_add_element_page .page_header{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}

.moderator_add_element_page .page_body{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    padding-top: 76px;
}

.moderator_add_element_page .page_body .course_publish_overview_wrapper{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 1134px;
    margin-bottom: 48px;
}

.moderator_add_element_page .page_body .create_course_form_wrapper{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 1134px;
    background-color: #fff;
    box-shadow: 0px 16px 56px #2C273814;
    border-radius: 9px;
    padding: 46px 34px;
    margin-bottom: 20px;
}

.moderator_add_element_page .page_body .create_course_form_wrapper form{
    margin-top: 33px;
    width: 100%;
}

.moderator_add_element_page .page_body .create_course_form_wrapper .form-field{
    width: 100%;
    margin-bottom: 18px;
}

.moderator_add_element_page .page_body .create_course_form_wrapper .form-field:last-child{
    margin-bottom: 0px;
}

.moderator_add_element_page .page_body .create_course_form_wrapper .form-actions{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    margin-top: 47px;
}

.moderator_add_element_page .page_body .create_course_form_wrapper .form-action{
    width: 199px;
    font-size: 20px
}

.moderator_add_element_page .page_body .create_course_form_wrapper .form-action:first-child{
    margin-right: 14px;
}
