.NE_collapsable_cards_container{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 16px 56px #2C273814;
    border-radius: 9px;
    padding: 30px 34px;
}

.NE_collapsable_cards_container > .header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.NE_collapsable_cards_container > .header .title{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.NE_collapsable_cards_container > .header .title img{
    width: 20px;
    height: 20px;
    margin: 0 11px;
}

.NE_collapsable_cards_container > .header .title h2{
    font-size: 22px;
    font-weight: bold;
    color: #2C2738;
}

.NE_collapsable_cards_container > .header .btn_collapse{
    display: flex;
    width: 30px;
    height: 30px;
    background-image: url('./assets/icon-chevron-down.svg');
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    justify-self: flex-end;
}

.NE_collapsable_cards_container > .body{
    display: none;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    padding: 0px 11px;
}

.NE_collapsable_cards_container > .body .body_element{
    width: 100%;
}

.NE_collapsable_cards_container_open > .header{
    border-bottom: 2px solid #F6F5F5;
    padding-bottom: 23px;
}

.NE_collapsable_cards_container_open > .header .btn_collapse{
    transform: rotate(180deg);
}

.NE_collapsable_cards_container_open > .body{
    display: flex;
}