.NE_alert {
    width:100%;
    height:60px;
    box-shadow: 0px 24px 48px #2C273814;
    border-radius: 0 0 10px 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: fixed;
    margin-bottom: 15px;
    z-index: 100;
    top: 0;
}

.NE_alert:last-child {
    margin-bottom: 0;
}

.NE_alert .container{
    padding-left: 10px;
}

.NE_alert .container p {
    font-size: 18px;
    opacity: 0.86;
    color: #FFFFFF;
}

.NE_alert .close_button{
    background-image: url('assets/Icon-Cross.svg');
    background-repeat: no-repeat;
    background-position: 50% 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    cursor: pointer;
    position: absolute;
    right: 15px;
}

.NE_bright_alert {
    background: #EBF4F8 0% 0% no-repeat padding-box;
}

.NE_bright_alert .alert-container p {
    color: #68A4F5;
}

.NE_bright_alert .close_button {
    background-image:url('assets/Icon-Cross-Bright.svg');
}

.NE_dark_alert {
    background: #3F4351 0% 0% no-repeat padding-box;
}

.NE_flash_alert {
    background: #0880AE 0% 0% no-repeat padding-box;
}

.NE_success_alert {
    background: #14A38B 0% 0% no-repeat padding-box;
}

.NE_warning_alert {
    background: #F2AC57 0% 0% no-repeat padding-box;
}

.NE_danger_alert {
    background: #FF7171 0% 0% no-repeat padding-box;
}

