.clients_list_page .page_header{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 354px;
  background-image: url('assets/header_bg2.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 40%;
}

.clients_list_page .page_body{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 1470px;
  margin-top: -132px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 20px;
}

.clients_list_page .page_body .content{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 25px;
  width: 100%;
}