.training_sessions_page .page_header{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    /* height: 354px; */
    /* background-image: url('assets/header_bg2.jpg'); */
    background-repeat: no-repeat;
    background-size: cover;
}

.training_sessions_page .page_header .switch_sections_wrapper{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 19px 0;
}

.training_sessions_page .page_body{
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 20px;
}

.training_sessions_page .page_body .content{
    /*width: 1878px;*/
    /*display: flex;*/
    /*justify-content: space-between;*/


    box-sizing: border-box;
    outline: none;
    font-family: 'IBM Plex Sans', sans-serif;
    letter-spacing: 0;
    font-weight: normal;
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    margin-right: 21px;
    margin-left: 16px;
    width: 100%;
    padding-bottom: 20px;
}
.training_sessions_page .page_body .content .NE_SessionsSideBar{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 456px;
    min-height: 904px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0.78px 5px 20px #b0cad11a;
    border-radius: 9px;
    padding-bottom: 20px;
}

.training_sessions_page .page_body .content .session_dashboards{
    flex-direction: column;
    align-items: center;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 16px 56px #2c273814;
    border-radius: 9px;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 38px;
    padding-top: 10px;

    flex: 3 1 0%;
    display: flex;
    padding-bottom: 100px;
    margin-left: 19px;
    margin-bottom: 0px;
}

.training_sessions_page .page_body .content .session_dashboards {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    /*width: 1537px;*/
    height: 904px;
    background-color: #fff;
    box-shadow: 0px 16px 56px #2C273814;
    border-radius: 9px;
    padding: 28px 15px 20px 21px;
}

.training_sessions_page .page_body .content .session_dashboards > .header{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
}

.training_sessions_page .page_body .content .session_dashboards > .header .session_infos{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 32px;
    margin-bottom: 33px;
}

.training_sessions_page .page_body .content .session_dashboards > .header .session_infos > h1{
    font-size: 28px;
    font-weight: bold;
    color: #2C2738;
}

.training_sessions_page .page_body .content .session_dashboards > .header .session_infos > p{
    font-size: 24px;
    font-weight: normal;
    color: #2C2738;
}

.training_sessions_page .page_body .content .session_dashboards > .header .session_infos > p > span{
    display: inline-flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    color: var(--secondary-color);
}

.training_sessions_page .page_body .content .session_dashboards > .header .session_infos > p > span::after{
    content: '';
    display: block;
    width: 18px;
    height: 22px;
    background-image: url("./assets/copy.svg");
    background-repeat: no-repeat;
    background-position: center;
    margin-left: 6px;
}

.training_sessions_page .page_body .content .session_dashboards > .header .dashboards_tabs{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    margin-bottom: 26px;
}

.training_sessions_page .page_body .content .session_dashboards > .header .dashboards_tabs .tabs{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    list-style: none;
    margin-bottom: 2px;
}

.training_sessions_page .page_body .content .session_dashboards > .header .dashboards_tabs .tabs li{
    width: 205px;
    margin-right: 14px;
}

.training_sessions_page .page_body .content .session_dashboards > .header .dashboards_tabs .tabs li:last-child{
    margin-right: 0;
}

.training_sessions_page .page_body .content .session_dashboards > .header .dashboards_tabs .tabs .NE_button{
    margin-bottom: 0;
}

.training_sessions_page .page_body .content .session_dashboards > .header .dashboards_tabs .tabs li{
    width: 250px;
}

.training_sessions_page .page_body .content .session_dashboards > .header .dashboards_tabs > p{
    font-size: 20px;
    font-weight: 500;
    letter-spacing: -0.2px;
    color: #000;
}

.training_sessions_page .page_body .content .session_dashboards > .body{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
}

.training_sessions_page .page_body .content .session_dashboards > .body .comments_container{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 1123px;
    height: 713px;
    border: 2px solid #F5F6FA;
    border-radius: 6px;
    background-color: #fff;
    padding: 16px 26px 23px 30px;
}

.training_sessions_page .page_body .content .session_dashboards > .body .comments_container > h1{
    font-size: 24px;
    font-weight: 600;
    color: #212121;
    margin-bottom: 16px;
}

.training_sessions_page .page_body .content .session_dashboards > .body .comments_container .NE_comments_tree{
    height: 635px;
}

.training_sessions_page .page_body .content .session_dashboards > .body .comments_container .NE_comments_tree .reply_box{
    padding-left: 0;
    margin-top: 0;
}
