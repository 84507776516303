.NE_profile_popup_card{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: #fff;
    border-radius: 24px;
    box-shadow: 0px 24px 48px #2C27380A;
    padding: 29px 27px 12px 27px;
    width: 437px;
    z-index: 100;

}

.NE_profile_popup_card .profile_infos{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    border-bottom: 1px solid #DBE2EA;
    padding-bottom: 19px;
}

.NE_profile_popup_card .profile_infos .pic_and_name{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}

.NE_profile_popup_card .profile_infos .pic_and_name img{
    width: 47px;
    height: 47px;
    border-radius: 100%;
    margin-right: 14px;
}

.NE_profile_popup_card .profile_infos .pic_and_name h1{
    font-size: 28px;
    font-weight: 600;
    color: #3F4351;
}

.NE_profile_popup_card .profile_infos .profile_progress{
    width: 376px;
    margin-top: 33px;
}

.NE_profile_popup_card .profile_infos .profile_progress .progress_bar{
    width: 100%;
    height: 8px;
    background-color: #DBE2EA;
    border-radius: 2px;
}

.NE_profile_popup_card .profile_infos .profile_progress .progress_bar .progress_level{
    height: 8px;
    background: var(--gradient-color);
    border-radius: 2px;
    position: relative;
}

.NE_profile_popup_card .profile_infos .profile_progress .progress_bar .progress_level .tooltip_container{
    display: none;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 116px;
    position: absolute;
    bottom: 15px;
    right: -58px;
}

.NE_profile_popup_card .profile_infos .profile_progress .progress_bar .progress_level .tooltip_container .tooltip{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 7px 10px;
    background-color: var(--secondary-color);
    font-size: 14px;
    color: #fff;
    text-align: center;
    min-width: 116px;
    width: fit-content;
    border-radius: 4px;
    position: relative;
}

.NE_profile_popup_card .profile_infos .profile_progress .progress_bar .progress_level .tooltip_container .tooltip::before{
    content: '';
    display: block;
    border: 7px solid transparent;
    border-top-color: #9370DB;
    position: absolute;
    left: calc(50% - 7px);
    bottom: -14px;
}

.NE_profile_popup_card .profile_infos .profile_progress .progress_bar:hover > .progress_level .tooltip_container{
    display: flex;
}

.NE_profile_popup_card .profile_actions{
    width: 100%;
}

.NE_profile_popup_card .profile_actions ul{
    list-style: none;
}

.NE_profile_popup_card .profile_actions ul li{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 44px;
    margin-bottom: 7px;
    padding-left: 8px;
    cursor: pointer;
}

.NE_profile_popup_card .profile_actions ul li:last-child{
    margin-bottom: 0;
}

.NE_profile_popup_card .profile_actions ul li:hover{
    background-color: #EBF4F8;
}

.background_color {
    background-color: #EBF4F8;
}
