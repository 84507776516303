.NE_documents_uploader{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    /*background-color: #fff;*/
    /*padding: 20px;*/
}

.NE_documents_uploader .drop_zone{
    width: 100%;
    padding: 15px 17px;
    box-shadow: 0px 4px 8px #2C27380A;
    border: 2px dashed #DAE1ED;
    border-radius: 4px;
    background-color: #FAFCFD;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.NE_documents_uploader .drop_zone label{
    display: flex;
}

.NE_documents_uploader .drop_zone img{
    width: 15px;
    height: 22px;
    margin-right: 13px;
}

.NE_documents_uploader .drop_zone h1{
    font-weight: 600;
    color: #2C2738;
    font-size: 16px;
    line-height: 16px;
    margin-right: 8px;
}

.NE_documents_uploader .drop_zone p{
    color: #989898;
    font-size: 15px;
    line-height: 15px;
}

.NE_documents_uploader .drop_zone p span{
    color: var(--primary-color);
    font-size: 15px;
    cursor: pointer;
}

.NE_documents_uploader .upload_vault{
    margin-top: 35px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.NE_documents_uploader .upload_vault .upload_item{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 20px;
}

.NE_documents_uploader .upload_vault .upload_item:last-child{
    margin-bottom: 0;
}

.NE_documents_uploader .upload_vault .upload_item .upload_item_icon{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 30px;
}

.NE_documents_uploader .upload_vault .upload_item .upload_item_icon img{
    width: 20px;
    height: 18px;
    margin-top: 6px;
}

.NE_documents_uploader .upload_vault .upload_item .upload_item_content{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
}


.NE_documents_uploader .upload_vault .upload_item .upload_item_content .upload_item_header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 32px;
}

.NE_documents_uploader .upload_vault .upload_item .upload_item_content .upload_item_header h1{
    display: flex;
    font-weight: bold;
    color: #101426;
    font-size: 16px;
    align-self: flex-start;
    line-height: 18px;
    margin-top: 6px;
}

.NE_documents_uploader .upload_vault .upload_item .upload_item_content .upload_item_header button{
    border: none;
    padding: 8px 21px;
    border-radius: 20px;
    background-color: #FAF0F0;
    color: #FA5D5D;
    font-size: 12px;
    font-weight: 600;
    cursor: pointer;
}

.NE_documents_uploader .upload_vault .upload_item .upload_item_content .upload_item_progress{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 8px;
}

.NE_documents_uploader .upload_vault .upload_item .upload_item_content .upload_item_progress .progress_bar{
    width: 100%;
    height: 6px;
    border-radius: 3px;
    background-color: #E9EDF4;
}

.NE_documents_uploader .upload_vault .upload_item .upload_item_content .upload_item_progress .progress_bar .progress_level{
    height: 6px;
    border-radius: 3px;
    background-color: #0676ED;
}

.NE_documents_uploader .upload_vault .upload_item .upload_item_content .upload_item_progress .progress_infos{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 8px;
}

.NE_documents_uploader .upload_vault .upload_item .upload_item_content .upload_item_progress .progress_infos .progress_title{
    font-size: 10px;
    color: #8F9BB3;
    letter-spacing: 0.25px
}

.NE_documents_uploader .upload_vault .upload_item .upload_item_content .upload_item_progress .progress_infos .progress_percentage{
    font-size: 11px;
    font-weight: bold;
    color: #8F9BB3;
    letter-spacing: 0.28px;
}
