.NE_student_progress_overview {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 1123px;
    height: 713px;
    background: #FFFFFF;
    border: 2px solid #F5F6FA;
    border-radius: 6px;
    padding: 29px 22px 29px 30px;
}

.NE_student_progress_overview .header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 52px;
}

.NE_student_progress_overview .header .student_infos_wrapper{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: fit-content;
}

.NE_student_progress_overview .header .student_infos_wrapper img{
    width: 42px;
    height: 42px;
    border-radius: 100%;
    margin-right: 13px;
}

.NE_student_progress_overview .header .student_infos_wrapper .student_infos{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.NE_student_progress_overview .header .student_infos_wrapper .student_infos .name{
    font-size: 18px;
    font-weight: bold;
    letter-spacing: -0.3px;
    color: #2C2738;
    height: 23px;
}

.NE_student_progress_overview .header .student_infos_wrapper .student_infos .email{
    font-size: 16px;
    font-weight: normal;
    letter-spacing: 0px;
    color: #2C2738;
}

.NE_student_progress_overview .header .student_infos_wrapper .student_infos .email .info_title{
    font-weight: bold;
}

.NE_student_progress_overview .header .action{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: fit-content;
}

.NE_student_progress_overview .header .action p{
    margin-left: 8px;
    font-size: 20px;
    font-weight: 500;
    color: #3F4351;
}

.NE_student_progress_overview .body{
    display: block;
    width: 100%;
    height: 572px;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding: 3px 0;
}

.NE_student_progress_overview .body::-webkit-scrollbar{
    display: none;
}

.NE_student_progress_overview .body .notion_progress {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 98%;
    background: #FFFFFF;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 14px;
    padding: 28px 20px;
    margin: 0 auto 38px auto;
}

.NE_student_progress_overview .body .notion_progress:last-child{
    margin-bottom: 0;
}

.NE_student_progress_overview .body .notion_progress .notion_title{
    font-size: 16px;
    font-weight: bold;
    letter-spacing: -0.24px;
    color: #2C2738;
    flex:2;

}

.NE_student_progress_overview .body .notion_progress .progress_infos{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex:4;
}

.NE_student_progress_overview .body .notion_progress .progress_infos p{
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -0.16px;
    color: #000000;
    margin-bottom: 6px;
}

.NE_student_progress_overview .body .notion_progress .progress_infos .progress_bar{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 9px;
    background: #F6F5F5;
    border-radius: 7px;
}

.NE_student_progress_overview .body .notion_progress .progress_infos .progress_level{
    height: 9px;
    background: var(--gradient-color);
    border-radius: 7px;
}
