
.NE_CourseSideBar {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 456px;
    min-height: 580px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0.78px 5px 20px #B0CAD11A;
    border-radius: 9px;

}


.NE_notion {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    cursor: pointer;
}

.NE_CourseCard {
    margin-top: 10px;
}

.NE_CourseCard > p {
    padding: 0 8px;
}

.NE_notion .NE_notion_active {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 8px;
    margin-right: 10px;
    width: 414px;
    height: 54px;
    background: var(--primary-color) 0% 0% no-repeat padding-box;
    /* background: #a4e993 0% 0% no-repeat padding-box; */
    border-radius: 6px;
    opacity: 0.08;
    background-color: #ebf0f3;
}

.NE_blue_text {
    font: normal normal 600 20px/60px IBM Plex Sans;
    letter-spacing: 0px;
    color: var(--primary-color);
}

.NE_grey_text {
    font: normal normal medium 20px/60px IBM Plex Sans;
    letter-spacing: 0px;
    color: #989898;
}

.NE_notion .NE_notion_button {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 8px;
    margin-right: 10px;
    width: 414px;
    height: 54px;
}

.NE_notion .NE_notion_button img {
    margin-left: 20px;
    margin-right: 22px;
}


/* ------------------------------ */

.NE_CourseSideBarButton__disabled {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 436px;
    height: 54px;
    /* background: var(--primary-color) 0% 0% no-repeat padding-box; */
    border-radius: 6px;
}

.NE_CourseSideBarButton__disabled .NE_leftElemt {
    display: flex;
    align-items: center;
}

.NE_CourseSideBarButton__disabled .NE_leftElemt img {
    width: 18px;
    height: 20px;
    margin-left: 21px;
    margin-right: 15px;
}

.NE_CourseSideBarButton__disabled .NE_leftElemt h3 {
    text-align: right;
    font: normal normal bold 20px/60px IBM Plex Sans;
    letter-spacing: 0px;
    color: #989898;
}

.NE_notion .NE_notion_button .NE_text_grey {
    text-align: left;
    font: normal normal medium 20px/60px IBM Plex Sans;
    letter-spacing: 0px;
    color: #989898;
}

.NE_notion .NE_notion_button .NE_text_orange {
    text-align: left;
    font: normal normal medium 20px/60px IBM Plex Sans;
    letter-spacing: 0px;
    color: var(--secondary-color);
}

.NE_CourseSideBarButton__disabled .NE_rightElemt {
    /* display: flex;
    justify-content: center; */
    align-items: center;
    /*width: 30px;*/
    /*height: 30px;*/
    /*margin-right: 9px;*/
}

.NE_CourseSideBarButton__disabled .NE_rightElemt img {
    transform: rotate(0deg);
    /* height: 20px;
    width: 20px; */
}

.NE_notion .NE_notion_button .NE_image {
    margin-left: 0px;
}

.NE_CourseSideBar .NE_Down_button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 110px;
    width: 436px;
    height: 56px;
    background: var(--primary-color) 0% 0% no-repeat padding-box;
    box-shadow: 0px 4px 8px #2C273814;
    border-radius: 6px;
    cursor: pointer;
}

.NE_CourseSideBar .NE_Down_button h3 {
    text-align: center;
    font: normal normal bold 20px/27px IBM Plex Sans;
    letter-spacing: 0px;
    color: #FFFFFF;
}


.NE_CourseSideBarButton_ {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 436px;
    height: 54px;
    background: var(--primary-color) 0% 0% no-repeat padding-box;
    border-radius: 6px;
    margin-top: 10px;
}

.NE_CourseSideBarButton_ .NE_leftElemt {
    display: flex;
    align-items: center;
}

.NE_CourseSideBarButton_ .NE_leftElemt img {
    width: 18px;
    height: 20px;
    margin-left: 21px;
    margin-right: 15px;
}

.NE_CourseSideBarButton_ .NE_leftElemt h3 {
    text-align: right;
    font: normal normal bold 16px/60px IBM Plex Sans;
    letter-spacing: 0px;
    color: #FFFFFF;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 310px;
}

.NE_CourseSideBarButton_ .NE_rightElemt {
    /* display: flex;
    justify-content: center; */
    align-items: center;
    width: 30px;
    height: 30px;
    margin-right: 9px;
}

.NE_CourseSideBarButton_ .NE_rightElemt img {
    transform: rotate(180deg);
    /* height: 20px;
    width: 20px; */
}


.NE_CourseCard {
    width: 436px;
    height: 170px;
    background: transparent linear-gradient(180deg, #2B273700 0%, #2B2737 100%) 0% 0% no-repeat padding-box;
    border-radius: 14px;
}

.NE_CourseCard img {
    width: 436px;
    height: 170px;
    border-radius: 14px;
    margin-bottom: 13px;
    object-fit: cover;
}

.NE_CourseCard h3 {
    position: absolute;
    top: 220px;
    left: 40px;
    letter-spacing: 0px;
    color: #FFFFFF;
    font-weight: 700;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 430px;
    font-size: 18px;
}

.NE_CourseCard p {
    text-align: left;
    letter-spacing: 0px;
    color: #2C2738;
}


.NE_container .NE_progress_bar_text {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 19px;
}

.NE_container .NE_progress_bar_text .left_sideBar {
    display: flex;
    align-items: baseline;
    font: normal normal bold 14px/24px IBM Plex Sans;
    letter-spacing: 0px;
    color: #2C2738;
    /* margin-left: 22px; */
}

.NE_container .NE_progress_bar_text .right_sideBar {
    font: normal normal bold 16px/24px IBM Plex Sans;
    letter-spacing: 0px;
    color: #2C2738;
    /* margin-right: 133px; */
}

.NE_container .NE_progress_bar_text p span {
    font: normal normal bold 12px/24px IBM Plex Sans;
    letter-spacing: 0px;
    color: #989898;
}


.NE_container .NE_progress_bar {
    width: 419px;
    height: 13px;
    background: #DBE2EA 0% 0% no-repeat padding-box;
    border-radius: 10px;
    margin-top: 10px;
    margin-bottom: 45px;
}

.NE_container .NE_progress_bar_orange {

    height: 13px;
    background: var(--gradient-color) ;
    border-radius: 10px;
}


.collapsible-content-edonec {
    overflow: hidden;
    transition: height 0.2s ease-in-out;
    /*border-bottom: 1px solid #dee2e6 !important;*/
}

.title-text-edonec {
    display: block;
    font-size: 1em;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}

.collapsible-header-edonec_ {
    display: flex;
    padding: 10px;
    margin-right:0 !important;
}

.collapsible-header-edonec .img {
    user-select: none;
}

.collapsible-content-padding-edonec {
    padding: 20px 20px 20px 20px;
}

.rotate-center-edonec {
    -moz-transition: all 0.2s linear;
    -webkit-transition: all 0.2s linear;
    transition: all 0.2s linear;
}

.collapsible-header-edonec .down {
    -moz-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    transform: rotate(90deg);
}

.collapsible-header-edonec .up {
    -moz-transform: rotate(360deg) !important;
    -webkit-transform: rotate(360deg) !important;
    transform: rotate(360deg) !important;
}

.collapsible-icon-button-edonec {
    cursor: pointer;
    background-color: Transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
}

.collapsible-card-edonec {
    /*    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);*/
    transition: 0.3s;
}

.side-bare-content {
    flex: 1;
    margin-top: 10px;
}
.side-bare-content .title {
  margin-bottom: 10px;
    font-weight: bold;
}


.tooltip2 {
    position: relative;
    display: inline-block;
}

.tooltip2 .tooltiptext2 {
    visibility: hidden;
    width: 350px;
    background-color: var(--secondary-color);
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 8px;
    position: absolute;
    z-index: 1;
    top: 108%;
    left: 2%;
}

.tooltip2:hover .tooltiptext2 {
    visibility: visible;
}

.tooltiptext2::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent var(--secondary-color) transparent;
}
