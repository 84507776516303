/* Split the screen in half */
.login_split {
  height: 100%;
  width: 50%;
  position: fixed;
  z-index: 1;
  top: 0;
  overflow-x: hidden;
  padding-top: 20px;
}

/* Control the left side */
.login_left {
  left: 0;
  background-color: #fff;
}

/* Control the right side */
.login_right {
  right: 00;
  /* background-color: red; */

  /* background-repeat: no-repeat; */
  background-size: cover;
  background-position-x: 60%;
}

/* If you want the content centered horizontally and vertically */
.login_centered {
  display: flex;
  justify-content: center;
  width: 100%;
}

/* Style the image inside the centered container, if needed */

.login_NE_header_logo {
  width: 189px;
  height: 86px;
  margin-bottom: 33px;
  margin-left: -40px;
  object-fit: contain;
  position: relative;
}

.login_container {
  display: flex;
  justify-content: center;
}

.login_centered .login_NE_contain {
  width: 80%;
  height: 52px;
}

.login_NE_header_ {
  /*width: 675px;*/
  height: 44px;
  text-align: left;
  letter-spacing: 0px;
  color: #2c2738;
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 34px;
  font-weight: 800;
}

.alerts_container {
  display: flex;
  flex-direction: column;
  /*width: 77%;*/
  /*position: absolute;*/
  top: 0px;
  right: 0;
  /*background-color: red;*/
}
.alerts_container .NE_alert .container p {
  margin-left: 84px;
}

.login_NE_paragraph {
  /*width: 675px;*/
  height: 26px;
  letter-spacing: 0px;
  color: #6f024c;
  /* margin-bottom: 22px; */
  margin: 0;
}

.login_NE_paragraph a {
  text-decoration: none;
}

.login_NE_paragraph_underlined {
  /*width: 675px;*/
  height: 26px;
  letter-spacing: 0px;
  color: #2c2738;
  margin-bottom: 22px;
  /* margin: 0; */
}

.login_NE_paragraph_underlined a {
  color: #8c3cfd;
  text-decoration: none;
}

.login_NE_label {
  /* margin-top: 5px; */
  margin-top: 2px;
  /* margin-bottom: 29px; */
}

.login_NE_label_login {
  margin-top: 42px;
  margin-bottom: 25px;
}

.login_NE_label_signup {
  margin-bottom: 49px;
}

.login_NE_Social {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

@media screen and (max-width: 768px) {
  .login_right {
    width: 0;
  }
  .login_left {
    display: flex;
    left: 0;
    background-color: #fff;
    justify-content: center;
    width: 100%;
  }
  .login_centered .login_NE_contain {
    width: 90%;
  }
}

.login_split.login_left.login_page > div.alerts_container > div {
  position: absolute;
}
