.NE_clientModeratorCardWithAction {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 55px;
    background: #FFFFFF;
    box-shadow: 0 4px 8px #2C27380A;
    border: 1px solid #DBE2EA;
    border-radius: 10px;
    padding: 23px 24px 23px 28px;
    position: relative;
}

.NE_clientModeratorCardWithAction .item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-right: 2px solid #D5D5D5;
    margin-right: 14px;
    height: 46px;
    padding-right: 40px;
    min-width: 250px;
}

.NE_clientModeratorCardWithAction .item:nth-child(2){
    border-right: none;
    margin-right: 0;
}

.NE_clientModeratorCardWithAction .item > img {
    margin-right: 14px;
}

.NE_clientModeratorCardWithAction .item_menu {
    display: flex;
    width: 28px;
    height: 46px;
    justify-self: flex-end;
    background-image: url("./assets/icon-menu.svg");
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    top:1px;
    right:24px;
    cursor: pointer;
}

.NE_clientModeratorCardWithAction .item_menu::before {
    content: '';
    display: flex;
    width: 2px;
    height: 53px;
    background-color: #D5D5D5;
    margin-left: -20px;
}

.NE_clientModeratorCardWithAction .actions_wrapper{
    position: absolute;
    top: 33px;
    right: 47px;
    display: none;
}

.NE_clientModeratorCardWithAction .actions_wrapper_open{
    display: block;
}