.configure_course_modal_wrapper{
  z-index: 999;
}

.NE_body{
  width: 650px !important;
  height: 300px !important;
  background-color: #2B2737;
}

._close_button{
&:hover{
  cursor: pointer;
}
}

.msg_title {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  img{
    margin-right:10px;
    width: 20px;
  }
}
