.NE_switch_button{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    background-color: #fff;
    box-shadow: 0 24px 48px #2C273814;
    border-radius: 6px;
}

.NE_switch_button ul{
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.NE_switch_button ul li.switch_button_item{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 179px;
    height: 56px;
    font-size: 24px;
    font-weight: bold;
    color: #7C9CBF;
    border-radius: 6px;
    cursor: pointer;
}

.NE_switch_button ul li.switch_button_item_active{
    background-color: var(--primary-color);
    color: #fff;
    box-shadow: 0 24px 48px #2C273814;
}