.moderator_list_page .page_header{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 354px;
  background-image: url('assets/header_bg2.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 40%;}

.moderator_list_page .page_body{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  /*width: 1640px;*/
  margin-top: -137px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 20px;
  /*padding-bottom: 20px;*/
}

.moderator_list_page .page_body .content{
  /*display: flex;*/
  /*flex-direction: row;*/
  /*justify-content: space-between;*/
  /*align-items: flex-start;*/
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(266px, 1fr));

  grid-gap: 28px;
  gap: 20px;
  width: 100%;
}

.moderator_list_page .page_body .moderator_list_page_last_comments{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: fit-content;
  margin-left: 24px;
  height: calc(100vh - 223px);
}
.container{
  padding:0;
}
