.header_modal_wrapper {
  display: none;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: rgba(44, 39, 56, 0.6);
}
.header_modal_open {
  display: flex;
}
.NE_header {
  display: flex;
  flex-direction: row !important;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 60px;
  background-color: #fff;
  box-shadow: 0px 4px 8px #2c27380a;
  border-radius: 0 0 10px 10px;
  padding: 0 14px;
  position: relative;
}

.affectation_page_header {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 242px;
  background-repeat: no-repeat;
  background-size: cover;
}

.affectation_page_body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* justify-content: flex-start;
    align-items: flex-start; */
  /* width: 1640px; */
  /*margin-top: -137px;*/
  margin-left: auto;
  margin-right: auto;
  /* padding-bottom: 20px; */
  background: #ffffff 0% 0% no-repeat padding-box;
  width: 70%;
}

.affectation_page_body .affectation_content {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  width: 75%;
  height: 620px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 16px 56px #2c273814;
  border-radius: 9px;
  /*display: flex;*/
  /*flex-direction: row;*/
  /*justify-content: space-between;*/
  /*align-items: flex-start;*/
  /* display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 28px;
    width: 100%; */
}

.affectation_page_body .affectation_content_small {
  display: flex;
  flex-direction: column;
  width: 75%;
  height: 620px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 16px 56px #2c273814;
  border-radius: 9px;
}

.affectation_page_body .affectation_content_fb_gl {
  display: flex;
  flex-direction: column;
  width: 1134px;
  height: 775px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 16px 56px #2c273814;
  border-radius: 9px;
}

.affectation_NE_ {
  width: 1134px;
  height: 1100px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 16px 56px #2c273814;
  border-radius: 9px;
}

.affectation_NE_header {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 60px;
  background-color: #fff;
  box-shadow: 0px 4px 8px #2c27380a;
  border-radius: 0 0 10px 10px;
  padding: 0 14px;
}

.affectation_NE_header_edit {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-top: 25px;
  margin-bottom: 12px;
}

.affectation_NE_header_edit label {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: flex-start;
  margin-left: 79px;
  /* background-image: url(https://avatars.dicebear.com/api/initials/Youness.svg?radius=50&size=34); */
}

.affectation_NE_header_edit img {
  height: 50px;
  width: 50px;
  margin-right: 20px;
  border-radius: 60px;
}
.affectation_NE_header_edit p {
  text-decoration: underline;
  letter-spacing: 0px;
  font-size: 16px;
  letter-spacing: 0px;
  color: var(--primary-color);
}

.affectation_description {
  display: flex;
  justify-content: space-between;
  width: 977px;
  /*height: 148px;*/
  margin-left: 79px;
  margin-bottom: 0px;
}
.containerinfo {
  /* border: 1px solid red; */
  width: 100%;
}

.affectation_h {
  display: flex;
  /* justify-content: space-between; */
  flex-direction: row;
  gap: 20px;
  width: 977px;
  height: 80px;
  margin-top: 8px;
}

.affectation_h .header_align_change_password {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
}
.affectation_h .affectation_change_password {
  font-size: 14px;
  text-decoration: underline;
  letter-spacing: 0px;
  color: var(--primary-color);
  position: absolute;
  right: 10px;
  top: 37px;
}

.affectation_h_ {
  width: 475px;
  height: 85px;
}

.affectation_hh {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.affectation_footer {
  display: flex;
  justify-content: center;
  margin-top: 3rem;
}
.affectation_footer .affectation_vwidth {
  width: 180px;
  height: 56px;
  margin-left: 4px;
  /* margin-right: 4px; */
}

.affectation_footer .affectation_hwidth {
  width: 180px;
  height: 56px;
  margin-right: 4px;
}

.alerts_container_user {
  position: relative;
}

.alerts_container_user .NE_alert {
  position: absolute;
}

.error_password_match {
  color: red;
}

.affectation-content {
  display: flex;
}

.form_content {
  /* width: 100%; */
  padding: 2rem;
  padding-bottom: 5px;
  /* border: 1px solid red; */
}

.affectationContent {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.affectation_title {
  /* border: 1px solid red; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.affectation_title button {
  font-size: 18px;
  border: none;
  background-color: var(--primary-color);
  color: #ebf4f8;
  box-shadow: 0px 4px 8px #2c273814;
  height: 48px;
  border-radius: 6px;
  padding: 0px 12px;
}
.affectation_title button:hover {
  cursor: pointer;
}
