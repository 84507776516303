.participant_item_page .page_header{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 60px;
}

.participant_item_page .page_body{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  
}

.participant_item_page .page_body .content{
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  margin-right: 21px;
  margin-left: 16px;
  /*justify-content: space-between;*/
  /*align-items: flex-start;*/
   
  width: 100%;
}

.participant_item_page .page_body .last_comments{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: fit-content;
  margin-left: 24px;
}
