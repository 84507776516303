.modal_image_register_wrapper{
    display: none;
    justify-content: center;
    align-items: center;
    position: absolute;
    top:0;
    left:0;
    bottom: 0;
    width: 100%;
    background-color: rgba(44, 39, 56, 0.6);
    z-index: 1;
}

.modal_image_register_wrapper_open{
    display: flex;
}

.modal_image_register_wrapper .NE_body_modal {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    width: 1048px;
    height: 781px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 32px 64px #2C273814;
    border: 1px solid #707070;
    border-radius: 24px;
}

.modal_image_register_wrapper .NE_text {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.modal_image_register_wrapper .NE_text_body {
    margin-top: 26px;
    margin-bottom: 83px;
    margin-left: 42px;
    margin-right: 42px;
    font-size: 34px;
    text-align: left;
    font: normal normal 600 24px/26px IBM Plex Sans;
    letter-spacing: -0.34px;
    color: #2C2738;
    cursor: pointer;
}

.modal_image_register_wrapper .NE_body_center {
    margin: auto;
    width: 92%;
    margin-bottom: 40px;
}

.modal_image_register_wrapper .NE_middle_text {
    margin-top: 20px;
    margin-bottom: 26px;
    text-align: left;
    font: normal normal 600 28px/26px IBM Plex Sans;
    letter-spacing: -0.28px;
    color: #2C2738;
}

.modal_image_register_wrapper .NE_global_radio {
    display: flex;
    flex-direction: row;
    /* justify-content: space-evenly; */
}

.modal_image_register_wrapper .NE_radio_avatar {
    margin-right: 60px;
}

.modal_image_register_wrapper .NE_button {
    display: flex;
    justify-content: center;
    width: 180px;
    height: 56px;
}
.modal_image_register_wrapper .form-actions{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.modal_image_register_wrapper .form-action {
    width: 190px;
    margin-right: 24px;
}