.NE_collapsable_card_with_checkboxes{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 880px;
    background-color: #fff;
    box-shadow: 0px 4px 8px #2C27380A;
    border: 1px solid #DBE2EA;
    border-radius: 9px;
    padding: 26px 21px 26px 32px;
    margin: auto;

}
.collaps-content>.NE_collapsable_card_with_checkboxes{
    margin-top: 28px;
}

.collaps-content:first-child{
    margin-top: 60px;
}


.NE_collapsable_card_with_checkboxes > .header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.NE_collapsable_card_with_checkboxes .header .NE_checkbox label{
    font-size: 28px;
    font-weight: bold;
    line-height: 28px;
}

.NE_collapsable_card_with_checkboxes .NE_checkbox label::before{
    margin-right: 24px !important;
}

.NE_collapsable_card_with_checkboxes > .header .btn_collapse{
    display: flex;
    width: 30px;
    height: 30px;
    background-image: url('assets/icon-chevron-down.svg');
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    justify-self: flex-end;
}

.NE_collapsable_card_with_checkboxes > .body{
    display: none;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    padding: 0px 11px 6px 48px;
}

.NE_collapsable_card_with_checkboxes > .body ul.notions_list{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    list-style: none;
    padding-top: 5px;
    /*padding-bottom: 20px;*/
}

.NE_collapsable_card_with_checkboxes > .body ul.notions_list li{
    margin-bottom: 33px;
}

.NE_collapsable_card_with_checkboxes > .body ul.notions_list li > .NE_checkbox label {
    font-weight: 600;
    font-size: 28px;
}

.NE_collapsable_card_with_checkboxes > .body ul.notions_list li:first-child{
    margin-top: 36px;
}

/*.NE_collapsable_card_with_checkboxes > .body ul.notions_list li:last-child{*/
/*    margin-bottom: 0;*/
/*}*/

.NE_collapsable_card_with_checkboxes_open > .header{
    padding-bottom: 3px;
}

.NE_collapsable_card_with_checkboxes_open > .header .btn_collapse{
    transform: rotate(180deg);
}

.NE_collapsable_card_with_checkboxes_open > .body{
    display: flex;
}


.collapsible-content-edonec {
    overflow: hidden;
    transition: height 0.2s ease-in-out;
    /*border-bottom: 1px solid #dee2e6 !important;*/
}
.title-text-edonec {
    display: block;
    font-size: 1em;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}
.collapsible-header-edonec {
    display: flex;
    justify-content: space-between;
    padding: 2px 20px 2px 20px;
    border-bottom: 0;
    /*margin-bottom: 20px;*/
    /*border-bottom: 1px solid #dee2e6 !important;*/
}
.collapsible-content-padding-edonec {
    padding: 20px 20px 20px 20px;
}
.rotate-center-edonec {
    -moz-transition: all 0.2s linear;
    -webkit-transition: all 0.2s linear;
    transition: all 0.2s linear;
}
.rotate-center-edonec.down {
    -moz-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    transform: rotate(90deg);
}
.rotate-center-edonec.up {
    -moz-transform: rotate(360deg) !important;
    -webkit-transform: rotate(360deg) !important;
    transform: rotate(360deg) !important;
}
.collapsible-icon-button-edonec {
    cursor: pointer;
    background-color: Transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
}

.collapsible-card-edonec {
/*    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);*/
    transition: 0.3s;
}
