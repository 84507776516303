
 .user_infos_score{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    width: 98%;
}

.user_infos_score .user_infos{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 320px;
    background-color: #fff;
    box-shadow: 0.78px 5px 20px #B0CAD11A;
    border-radius: 9px;
    padding: 0 45px 35px 46px;
    height: 201px;
}

.user_infos_score .user_infos img{
    width: 76px;
    height: 76px;
    border-radius: 100%;
    border: 5px solid #fff;
    margin-top: -34px;
    box-sizing: content-box;
    box-shadow: 0 4px 8px rgba(44, 39, 56, 0.08);
}

 .user_infos_score .user_infos h1{
    font-size: 24px;
    font-weight: 600;
    color: #2C2738;
    line-height: 24px;
    margin-top: 10px;
}

 .user_infos_score .user_infos p{
    font-size: 16px;
    font-weight: 500;
    color: #989898;
    /*line-height: 16px;*/
    margin-top: 11px;
    text-align: center;
}

 .user_infos_score .user_scores{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex: 1;
    box-shadow: 0px 16px 56px #2C273814;
    border-radius: 9px;
    background-color: #fff;
    min-height: 201px;
    padding: 12px 48px 0 21px;
}

 .user_infos_score .user_scores h1{
    font-size: 28px;
    font-weight: bold;
    color: #2C2738;
}

 .user_infos_score .user_scores .NE_score_gage{
    padding-top: 41px;
}

 .user_metrics{
    display: flex;
    flex-direction: column;
    width: calc(100vw - 2%);
    /* width: 1878px;*/
    box-shadow: 0px 16px 56px #2C273814;
    border-radius: 9px;
    background-color: #fff;
    padding: 11px 17px 60px 32px;
    margin-top: 24px;
    position: relative;
}

 .user_metrics h1{
    font-size: 28px;
    font-weight: bold;
    color: #2C2738;
    margin-bottom: 21px;
}

 .user_metrics .metrics_slider{
    display: flex;
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    -ms-overflow-style: none;
    scrollbar-width: none;
     padding: 0 129px;
}

 .user_metrics .metrics_slider::-webkit-scrollbar{
    display: none;
}

 .user_metrics .metrics_slider .metric_card{
    display: block;
    width: 195px;
    height: 176px;
    background-color: #F9FBFE;
    border: 2px solid #F5F6FA;
    border-radius: 6px;
    margin-right: 20px;
    padding: 15px;
    flex-shrink: 0;
    cursor: pointer;
}

 .user_metrics .metrics_slider .metric_card:hover{
    background: transparent linear-gradient(90deg, #FFF4F2 0%, var(--secondary-color) 100%) 0% 0% no-repeat padding-box;
    border: none;
    padding: 17px;
}

 .user_metrics .metrics_slider .metric_card:hover > .metric_points > p:first-child{
    color: #fff;
}

 .user_metrics .metrics_slider .metric_card:hover > .metric_points > p:last-child{
    color: #fff;
}

 .user_metrics .metrics_slider .metric_card:hover > .description{
     font: normal normal normal 16px/20px IBM Plex Sans;
     color: #fff;
    /*display: none;*/
}

 .user_metrics .metrics_slider .metric_card:hover > .long_description{
    display: block;
    width: 100%;
    margin-top: 20px;
    font-size: 16px;
    font-weight: normal;
    color: #fff;
    white-space: pre-line;
}

 .user_metrics .metrics_slider .metric_card:last-child{
    margin-right: 0;
}

 .user_metrics .metrics_slider .slider_action{
    width: 115px;
    height: 176px;
    border: 2px solid #F5F6FA;
    border-radius: 6px;
    background-color: var(--primary-color);
    position: absolute;
    cursor: pointer;
}

 .user_metrics .user_metrics_contant .action_prev{
    background-image: url('./assets/icon-chevron-right.svg');
    background-repeat: no-repeat;
    background-position: center;
    transform: rotate(180deg);
    left: 32px;
}

 .user_metrics .user_metrics_contant .action_prev{
     cursor: pointer;
     right: 17px;
     height: 176px;
     border-radius: 6px;
     width: 115px;
     background: var(--primary-color) 0% 0% no-repeat padding-box;
     display: flex;
     align-content: flex-end;
     justify-content: center;
     align-items: center;
 }



 .user_metrics .user_metrics_contant{
     display: flex;
     justify-content: center;
     user-select: none;

 }
 .user_metrics .user_metrics_contant .action_next{
    cursor: pointer;
     right: 17px;
     height: 176px;
     border-radius: 6px;
     width: 115px;
     background: var(--primary-color) 0% 0% no-repeat padding-box;
     display: flex;
     align-content: flex-end;
     justify-content: center;
     align-items: center;
 }
 .user_metrics .metrics_slider .action_next{
    background-image: url('./assets/icon-chevron-right.svg');
    background-repeat: no-repeat;
    background-position: center;
    right: 17px;
}

 .user_metrics .metrics_slider .metric_card .metric_points{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

 .user_metrics .metrics_slider .metric_card .metric_points > p:first-child{
    font-size: 49px;
    font-weight: bold;
    color: #2C2738;
    line-height: 33px;
}

 .user_metrics .metrics_slider .metric_card .metric_points > p:last-child{
    font-size: 25px;
    font-weight: bold;
    color: var(--secondary-color);
    text-transform: uppercase;
    line-height: 20px;
}

 .user_metrics .metrics_slider .metric_card > .description{
    display: block;
    width: 100%;
    margin-top: 20px;
    font-size: 24px;
    font-weight: bold;
    color: #3F4351;
    white-space: pre-line;
}

 .user_metrics .metrics_slider .metric_card > .long_description{
    display: none;
}

 .community_progress{
     margin:17px;
    width: 98%;
    /* width: 1878px;*/
    box-shadow: 0px 16px 56px #2C273814;
    background-color: #fff;
    border-radius: 9px;
    padding: 28px 32px;
    margin-top: 25px;
}

 .community_progress .header{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    border-bottom: 2px solid #F5F6FA;
    /*padding-bottom: 23px;*/
}

 .community_progress .title{
    /*width: 721px;*/
    height: 60px;
    border-right: 2px solid #F5F6FA;
     flex: 1;
}

 .community_progress .title h1{
    font-size: 28px;
    font-weight: bold;
    color: #2C2738;
}

 .community_progress .header .metrics_titles{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
     /*margin-left: 138px;*/
    flex: 1;
    text-align: center;
     padding-left:2em;
}

 .community_progress .header .metrics_titles .metric_title{
    /* margin-left: 228px;
    margin-top: 19px; */
     text-align: left;
    font-size: 16px;
    font-weight: 500;
    color: #2C2738;
    flex: 1;
}

 .community_progress .header .metrics_titles .metric_title:first-child{
    margin-left: 0px;
}

 .community_progress .body{
    display: flex;
    width: 100%;
}

 .community_progress .body .users_progress_wrapper{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 376px;
    overflow-y: scroll;
    scrollbar-width: none;
}

 .community_progress .body .users_progress_wrapper::-webkit-scrollbar{
    display: none;
}

 .community_progress .body .users_progress_wrapper .user_progress_row{
    display: flex;
    flex-direction: row;
    width: 100%;
    /*height: 100px;*/
    flex-shrink: 0;
}

 .community_progress .body .users_progress_wrapper .user_progress_row:first-child .progress{
    padding-top: 50px;
}

 .community_progress .body .users_progress_wrapper .user_progress_row .progress{
    display: flex;
    flex-direction: row;
    /*width: 721px;*/
    border-right: 2px solid #F5F6FA;
     flex:1;
}

 .community_progress .body .users_progress_wrapper .user_progress_row .metrics{
    display: flex;
    flex-direction: row;
    align-items: center;
    /*margin-left: 114px;*/
    flex: 1;
     padding-left : 2em;
}

 .community_progress .body .users_progress_wrapper .user_progress_row .metric{
    /*width: 365px;*/
    font-size: 24px;
    font-weight: 600;
    color: #2C2738;
    flex: 1;
}

 .community_progress .body .users_progress_wrapper .user_progress_row .metric:nth-child(2){
    width: 343px;
}

 .community_progress .body .users_progress_wrapper .user_progress_row .metric:last-child{
    width: fit-content;
}

 .community_progress .body .users_progress_wrapper .user_progress_row .progress{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 63px;
}

 .community_progress .body .users_progress_wrapper .user_progress_row .progress .user_rank{
    font-size: 24px;
    font-weight: 600;
    color: #2C2738;
    margin-right: 9px;
}

 .community_progress .body .users_progress_wrapper .user_progress_row .progress img{
    width: 64px;
    height: 64px;
    border-radius: 100%;
    margin-right: 7px;
}

 .community_progress .body .users_progress_wrapper .user_progress_row .progress .user_score_progress{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    padding-right: 96px;
}

 .community_progress .body .users_progress_wrapper .user_progress_row .progress .user_score_progress h1{
    font-size: 24px;
    font-weight: 600;
    color: #2C2738;
    margin-bottom: 10px;
}



 .NE_score_gage{
     display: flex;
     flex-direction: row;
     justify-content: flex-start;
     align-items: center;
     width: 100%;
     /*padding-top: 41px;*/
 }

 .NE_score_gage p{
     font-size: 24px;
     font-weight: 600;
     color: #989898;
     margin-right: 7px;
 }

 .NE_score_gage .progress_bar{
     width: 100%;
     height: 16px;
     border: 1px solid #F6F5F5;
     border-radius: 8px;
     background-color: #F6F5F5;
     position: relative;
 }

 .NE_score_gage .progress_bar .progress_level .tooltip_container {
     display: none;
     flex-direction: column;
     justify-content: flex-start;
     align-items: center;
     width: 116px;
     position: absolute;
     bottom: 45px;
     /*left: 27%;*/
 }
 .NE_first{
     left: 27%;
 }

 .NE_second{
     left: 60%;
 }

 .NE_third{
     left: 96%;
 }

 .NE_score_gage .progress_bar .progress_level .tooltip_container .tooltip{
     display: flex;
     flex-direction: column;
     justify-content: flex-start;
     align-items: center;
     padding: 7px 10px;
     background: var(--gradient-color);
     font-size: 14px;
     color: #fff;
     text-align: center;
     min-width: 116px;
     width: fit-content;
     border-radius: 4px;
     position: relative;
 }

 .NE_score_gage .progress_bar .progress_level .tooltip_container .tooltip::before{
     content: '';
     display: block;
     border: 7px solid transparent;
     border-top-color: #9370DB;
     position: absolute;
     left: calc(50% - 7px);
     bottom: -14px;
 }

 .NE_score_gage .progress_bar > .progress_level .tooltip_container{
     display: flex;
 }

 .NE_score_gage .progress_level{
     height: 16px;
     border-radius: 8px;
     background: transparent linear-gradient(90deg, #FFF4F2 0%, var(--secondary-color) 100%) 0% 0% no-repeat padding-box;
 }

 .NE_score_gage .progress_bar .progress_points_wrapper{
     height: 45px;
     position: absolute;
     top: -15px;
     right:0;
     left: 0;
     /*background-color: rgba(204, 204, 204, 0.4);*/
 }

 .NE_score_gage .progress_bar .progress_points_wrapper .progress_points{
     display: flex;
     flex-direction: row;
     align-items: center;
     width: 100%;
     height: 45px;
 }

 .NE_score_gage .progress_bar .progress_points_wrapper .progress_points .progress_point{
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: center;
     width: 45px;
     height: 45px;
     font-size: 20px;
     font-weight: 600;
     color: #fff;
     border: 3px solid #FFFFFF;
     background: var(--gradient-color);
     border-radius: 100%;
     box-sizing: content-box;
     position: relative;
     cursor: pointer;
 }

 .NE_score_gage .progress_bar .progress_points_wrapper .progress_points .progress_point::after{
     content: 'XP';
     font-size: 18px;
     font-weight: 600;
     color: var(--secondary-color);
     position: absolute;
     bottom: -25px;
 }
 .NE_score_gage .progress_bar .progress_points_wrapper .progress_points .progress_point_disabled{
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: center;
     width: 45px;
     height: 45px;
     font-size: 20px;
     font-weight: 600;
     color: #989898;
     border: 3px solid #FFFFFF;
     background-color: #F6F5F5;
     border-radius: 100%;
     box-sizing: content-box;
     position: relative;
     cursor: pointer;
 }

 .NE_score_gage .progress_bar .progress_points_wrapper .progress_points .progress_point_disabled::after{
     content: 'XP';
     font-size: 18px;
     font-weight: 600;
     color: #989898;
     position: absolute;
     bottom: -25px;
 }

 .NE_score_gage .progress_bar .progress_points_wrapper .progress_points .progress_point_small{
     width: 35px;
     height: 35px;
     font-size: 15px;
 }

 .NE_score_gage .progress_bar .progress_points_wrapper .progress_points .progress_point_small::after{
     font-size: 15px;
 }

 .NE_score_gage .progress_bar .progress_points_wrapper .progress_points .next_level_progress_point{
     position: absolute;
     right: -22px;
     /* background-color: #F6F5F5;
     color: #989898; */
 }

 .NE_score_gage .progress_bar .progress_points_wrapper .progress_points .next_level_progress_point::after{
     /* color: #989898; */
 }


 .tooltip {
     position: relative;
     display: inline-block;
     border-bottom: 1px dotted var(--secondary-color);
 }

 .tooltip .tooltiptext {
     width: 80px;
     background-color: var(--secondary-color);
     color: #fff;
     text-align: center;
     border-radius: 6px;
     padding: 5px 0px;

     /* Position the tooltip */
     position: absolute;
     z-index: 1;
     bottom: 130%;
     left: 50%;
     margin-left: -40px;


     font:  normal 600 16px/20px IBM Plex Sans;

 }

 .tooltip .tooltiptext_active {
     width: 80px;
     background-color: #F6F5F5;
     color: #989898;
 }


 .tooltip .tooltiptext::after {
     content: "";
     position: absolute;
     top: 100%;
     left: 50%;
     margin-left: -5px;
     border-width: 5px;
     border-style: solid;
     border-color: var(--secondary-color) transparent transparent transparent;
 }

 .tooltip .tooltiptext_active::after {
     border-color: #F6F5F5 transparent transparent transparent;
 }
 .mr-2{
     margin-right: 15px !important;

 }
