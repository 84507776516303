.NE_counter{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 180px;
    height: 52px;
    background-color: #fff;
    box-shadow: 0px 4px 8px #2C273814;
    border: 1px solid #DBE2EA;
    border-radius: 6px;
}

.NE_counter .counter_btn{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 50px;
    background-color: #fff;
    padding: 11px 15px;
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: center;
}

.NE_counter .counter_btn:hover{
    background-color: var(--primary-color);
}

.NE_counter .decrement_btn{
    background-image: url("assets/Icon-Minus.svg");
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
}

.NE_counter .decrement_btn:hover{
    background-image: url("assets/Icon-Minus-White.svg");
}

.NE_counter .increment_btn{
    background-image: url("assets/Icon-Add.svg");
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}

.NE_counter .increment_btn:hover{
    background-image: url("assets/Icon-Add-White.svg");
}

.NE_counter p{
    font-size: 16px;
    color: #2C2738;
}

.NE_counter > input{
    display: none;
}

.NE_counter_edit > input{
    display: block;
    width: 100%;
    height: 30px;
    text-align: center;
    border: 1px solid #dbe2ea;
    border-radius: 4px;
    box-shadow: 0px 4px 8px #2c27380a;
    font-size: 14px;
    color: #2c2738;
    padding-right: 5px;
}

.NE_counter_edit > p{
    display: none;
}

