.NE_normal_input {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
}

.NE_normal_input label {
    font-size: 16px;
    font-weight: 500;
    color: #3f4351;
    margin-bottom: 6px;
}

.NE_normal_input input {
    width: 100%;
    /* height: 52px; */
    border: 1px solid #dbe2ea;
    border-radius: 6px;
    box-shadow: 0px 4px 8px #2c27380a;
    padding: 16px 57px 16px 16px;
    font-size: 20px;
    color: #2c2738;
    margin-bottom: 6px;
}

.NE_normal_input input::placeholder{
    font-size: 18px;
    color: #989898;
}

.NE_normal_input input:focus{
    border: 2px solid #68A4F5;
    padding: 15px;
}

.NE_normal_input input:disabled{
    background-color: #ebf4f8;
    color: #989898;
}

.NE_normal_input p {
    display: none;
    font-size: 14px;
}

.NE_normal_input_success input{
    border: 2px solid #14a38b;
    /*color: #14a38b;*/
}

.NE_normal_input_success p{
    display: block;
    color: #14a38b;
}

.NE_normal_input_error p{
    display: block;
    color: red;
    font-size: 16px;
    font-family: 'IBM Plex Sans', sans-serif;
}
