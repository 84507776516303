.configure_course_modal_wrapper{
  z-index: 999;
}

.NE_body{
  width: 960px !important;
  height: 436px !important;
}

._close_button{
&:hover{
  cursor: pointer;
}
}
