.NE__QUIZ .NE_ {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 1402px;
    height: 904px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 16px 56px #2C273814;
    border-radius: 9px;
    overflow-y: scroll;
    overflow-x: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.NE__QUIZ .NE_::-webkit-scrollbar{
    display: none;
}
.NE_header_text {
    width: 100%;
    margin-top: 48px;
    margin-bottom: 30px;
    margin-left: 21px;
}

.NE__QUIZ .NE_header_text h2 {
    font: normal normal bold 28px/60px IBM Plex Sans;
    letter-spacing: 0px;
    color: #2C2738;
}

.NE__QUIZ .NE__QUIZ {
    display: flex;
    flex-direction: column;
    width: 95%;
    height: auto !important;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 2px solid #F5F6FA;
    border-radius: 6px;
    margin-bottom: 16px;
}

.NE__QUIZ .NE_body_content {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    margin-top: 30px;
    height: auto !important;
}
.NE__QUIZ .NE_body_content .NE_body_paragraph {
    margin-top: 16px;
    width: 1140px;
}

.NE__QUIZ .NE_body_content h6 {
    font: normal normal 600 16px/20px IBM Plex Sans;
    letter-spacing: 0px;
    color: #989898;
}

.NE__QUIZ .NE_body_content p {
    font: normal normal 600 25.6px/33px IBM Plex Sans;
    letter-spacing: 0px;
    color: #2C2738;
}

.NE__QUIZ .NE_normal_radiobutton .NE_checked_radiobutton{
    margin: 0;
}

.NE__QUIZ .NE_align_items {
    display: flex;
    align-items: center;
    height: 48px;
}

.NE__QUIZ .NE_align_items h4{
    font: normal normal 500 20px/20px IBM Plex Sans;
    letter-spacing: 0px;
    color: #3F4351;
}

.NE__QUIZ .active_button{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 205px;
    height: 48px;
    background: var(--primary-color) 0% 0% no-repeat padding-box;
    box-shadow: 0px 24px 48px #2c273814;
    border-radius: 6px;
    cursor: pointer;



}

.NE__QUIZ .active_button span{
    font: normal normal bold 20px/32px IBM Plex Sans;
    letter-spacing: 0px;
    color: #ffffff;
}

.NE__QUIZ_course ._justification{
    padding: 20px;
    background-color: rgba(20, 163, 139, 0.16);
}

.NE__QUIZ_course ._justification p{
    color: #383636;
    font-size: 18px;
}
