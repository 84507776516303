.moderator_configure_course_page .page_body{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    padding-top: 54px;
    padding-bottom: 25px;
}

.moderator_configure_course_page .page_body .course_publish_overview_wrapper{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 930px;
    margin-bottom: 30px;
}


.moderator_configure_course_page .page_body .create_course_form_wrapper{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 932px;
}

.moderator_configure_course_page .page_body .create_course_form_wrapper .NE_collapsable_cards_container{
    margin-bottom: 48px;
}

.moderator_configure_course_page .page_body .create_course_form_wrapper .NE_collapsable_cards_container:last-child{
    margin-bottom: 0;
}

.moderator_configure_course_page .page_body .create_course_form_wrapper .btn_add_element{
    width: 50px;
    height: 50px;
    background-image: url('assets/add-enabled.svg');
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    border-radius: 50%;
}

.moderator_configure_course_page .page_body .create_course_form_wrapper .btn_add_element_disabled{
    background-image: url('assets/add-disabled.svg');
    cursor: default;
}

.moderator_configure_course_page .notion_creation_card .body form{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
}

.moderator_configure_course_page .notion_creation_card .body form .form-field{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 19px;
}

.moderator_configure_course_page .notion_creation_card .body form .form-field:nth-child(2){
    margin-bottom: 41px;
}

.moderator_configure_course_page .notion_creation_card .body form .form-field:nth-child(3){
    margin-bottom: 41px;
}

.moderator_configure_course_page .notion_creation_card .body form .form-field:nth-child(4){
    margin-bottom: 30px;
}

.moderator_configure_course_page .notion_creation_card .body form .form-field .NE_normal_textarea textarea{
    min-height: 286px;
}

.moderator_configure_course_page .notion_creation_card .body form .actions{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.moderator_configure_course_page .notion_creation_card .body form .action{
    margin-right: 8px;
}

.moderator_configure_course_page .notion_creation_card .body form .action .NE_button_large{
    width: 180px;
}

.moderator_configure_course_page .notion_creation_card .body form .action:last-child{
    margin-right: 0;
}

.moderator_configure_course_page .quiz_creation_card .body form{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}

.moderator_configure_course_page .quiz_creation_card .body form .form-field{
    width: 100%;
}

.moderator_configure_course_page .quiz_creation_card .body form .form-field .question{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 15px;
}

.moderator_configure_course_page .quiz_creation_card .body form .form-field .question:last-child{
    margin-bottom: 0;
}

.moderator_configure_course_page .quiz_creation_card .body form .form-field .question .question_options{
    margin-top: 18px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    list-style: none;
}

.moderator_configure_course_page .quiz_creation_card .body form .form-field .question .question_options li{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 14px 15px 14px 20px;
    margin-bottom: 7px;
    border-radius: 8px;
}

.moderator_configure_course_page .quiz_creation_card .body form .form-field .question .question_options li > .NE_normal_input{
    display: none;
}

.moderator_configure_course_page .quiz_creation_card .body form .form-field .question .question_options li:last-child{
    margin-bottom: 0;
}

.moderator_configure_course_page .quiz_creation_card .body form .form-field .question .question_options li .question_option_action{
    display: flex;
    width: 29px;
    height: 29px;
    background-image: url('assets/icon-trash.svg');
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
}

.moderator_configure_course_page .quiz_creation_card .body form .form-field .question .question_options li.question_option_selected{
    background-color: rgba(20, 163, 139, 0.16);
}

.moderator_configure_course_page .quiz_creation_card .body form .form-field .question .question_options li.question_option_selected .question_option_action{
    background-image: url('assets/icon-check.svg');
    cursor: default;
}

.moderator_configure_course_page .quiz_creation_card .body form .form-field .question .question_options li.add_question_option{
    justify-content: flex-start;
    font-size: 20px;
    font-weight: 500;
    color: #3F4351;
    width: fit-content;
    cursor: pointer;
}

.moderator_configure_course_page .quiz_creation_card .body form .form-field .question .question_options li.add_question_option::before{
    content: '';
    display: block;
    width: 24px;
    height: 24px;
    background-image: url('assets/add-small.svg');
    margin-right: 10px;
}

.moderator_configure_course_page .quiz_creation_card .body form .add_question{
    display: block;
    width: 50px;
    height: 50px;
    background-image: url('assets/add-big.svg');
    cursor: pointer;
    margin-top: 16px;
}

.moderator_configure_course_page .quiz_creation_card .body form .form-field .question .question_options li.question_option_edit_mode > .NE_normal_input{
    display: flex;
}

.moderator_configure_course_page .quiz_creation_card .body form .form-field .question .question_options li.question_option_edit_mode .NE_radio_button{
    display: none;
}

.moderator_configure_course_page .quiz_creation_card .body form .form-field .question .question_options li.question_option_edit_mode .question_option_action{
    display: none;
}

.moderator_configure_course_page .page_body .create_course_form_wrapper > .NE_collapsable_card_with_icon{
    margin-bottom: 48px;
}

.moderator_configure_course_page .page_body .create_course_form_wrapper > .NE_collapsable_card_with_icon:last-child{
    margin-bottom: 0px;
}

.moderator_configure_course_page .page_body .create_course_form_wrapper > .actions{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    /*margin-top: 53px;*/
}

.moderator_configure_course_page .page_body .create_course_form_wrapper > .actions .action{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 450px;
    border-radius: 9px;
    padding-top: 75px;
    padding-bottom: 75px;
}

.moderator_configure_course_page .page_body .create_course_form_wrapper > .actions .action div{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
}

.moderator_configure_course_page .page_body .create_course_form_wrapper > .actions .action div img{
    width: 75px;
    margin-bottom: 12px;
}

.moderator_configure_course_page .page_body .create_course_form_wrapper > .actions .action div p{
    font-size: 28px;
    font-weight: 500;
    color: #fff;
}

.moderator_configure_course_page .page_body .create_course_form_wrapper > .actions .action_add_element{
    background: var(--gradient-color);
}

.moderator_configure_course_page .page_body .create_course_form_wrapper > .actions .action_clone_element{
    background-color: var(--primary-color);
}

.moderator_configure_course_page .page_body .create_element_form_wrapper{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 930px;
    background-color: #fff;
    box-shadow: 0px 16px 56px #2C273814;
    border-radius: 9px;
    padding: 46px 34px;
    margin-bottom: 20px;
}

.moderator_configure_course_page .page_body .create_element_form_wrapper form{
    margin-top: 33px;
    width: 100%;
}

.moderator_configure_course_page .page_body .create_element_form_wrapper .form-field{
    width: 100%;
    margin-bottom: 18px;
}

.moderator_configure_course_page .page_body .create_element_form_wrapper .form-field:last-child{
    margin-bottom: 0px;
}

.moderator_configure_course_page .page_body .create_element_form_wrapper .form-actions{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    margin-top: 47px;
}

.moderator_configure_course_page .page_body .create_element_form_wrapper .form-action{
    width: 180px;
    font-size: 18px
}

.moderator_configure_course_page .page_body .create_element_form_wrapper .form-action:first-child{
    margin-right: 14px;
}



/*.moderator_configure_course_page .page_header{*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    justify-content: flex-start;*/
/*    align-items: center;*/
/*    width: 100%;*/
/*}*/

/*.moderator_configure_course_page .page_body{*/
/*    display: flex;*/
/*    flex-direction: row;*/
/*    justify-content: flex-start;*/
/*    align-items: flex-start;*/
/*    width: 100%;*/
/*    padding-top: 54px;*/
/*}*/

/*.moderator_configure_course_page .page_body .wrapper{*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    justify-content: flex-start;*/
/*    align-items: flex-start;*/
/*    width: 1134px;*/
/*    margin: 0 auto;*/
/*}*/

/*.moderator_configure_course_page .page_body .wrapper > .actions{*/
/*    display: flex;*/
/*    flex-direction: row;*/
/*    justify-content: space-between;*/
/*    align-items: flex-start;*/
/*    width: 100%;*/
/*    margin-top: 53px;*/
/*}*/

/*.moderator_configure_course_page .page_body .wrapper > .actions .action{*/
/*    display: flex;*/
/*    flex-direction: row;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*    width: 555px;*/
/*    border-radius: 9px;*/
/*    padding-top: 117px;*/
/*    padding-bottom: 75px;*/
/*}*/

/*.moderator_configure_course_page .page_body .wrapper > .actions .action div{*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    justify-content: flex-start;*/
/*    align-items: center;*/
/*    cursor: pointer;*/
/*}*/

/*.moderator_configure_course_page .page_body .wrapper > .actions .action div img{*/
/*    width: 82px;*/
/*    margin-bottom: 13px;*/
/*}*/

/*.moderator_configure_course_page .page_body .wrapper > .actions .action div p{*/
/*    font-size: 34px;*/
/*    font-weight: 500;*/
/*    color: #fff;*/
/*}*/

/*.moderator_configure_course_page .page_body .wrapper > .actions .action_add_element{*/
/*    background-color: var(--secondary-color);*/
/*}*/

/*.moderator_configure_course_page .page_body .wrapper > .actions .action_clone_element{*/
/*    background-color: var(--primary-color);*/
/*}*/

/*.moderator_configure_course_page .page_body .course_publish_overview_wrapper{*/
/*    display: flex;*/
/*    flex-direction: row;*/
/*    justify-content: flex-start;*/
/*    align-items: flex-start;*/
/*    width: 1134px;*/
/*    margin-bottom: 48px;*/
/*}*/


/*.moderator_configure_course_page .page_body .create_course_form_wrapper{*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    justify-content: flex-start;*/
/*    align-items: center;*/
/*    width: 1134px;*/
/*}*/

/*.moderator_configure_course_page .page_body .create_course_form_wrapper .NE_collapsable_cards_container{*/
/*    margin-bottom: 48px;*/
/*}*/

/*.moderator_configure_course_page .page_body .create_course_form_wrapper .NE_collapsable_cards_container:last-child{*/
/*    margin-bottom: 0;*/
/*}*/

/*.moderator_configure_course_page .page_body .create_course_form_wrapper .btn_add_element{*/
/*    width: 79px;*/
/*    height: 79px;*/
/*    background-image: url('assets/add-enabled.svg');*/
/*    background-repeat: no-repeat;*/
/*    background-position: center;*/
/*    cursor: pointer;*/
/*    margin-top: 16px;*/
/*}*/

/*.moderator_configure_course_page .page_body .create_course_form_wrapper .btn_add_element_disabled{*/
/*    background-image: url('assets/add-disabled.svg');*/
/*    cursor: default;*/
/*}*/

/*.moderator_configure_course_page .notion_creation_card .body form{*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    justify-content: flex-start;*/
/*    align-items: flex-start;*/
/*    width: 100%;*/
/*}*/

/*.moderator_configure_course_page .notion_creation_card .body form .form-field{*/
/*    display: flex;*/
/*    flex-direction: row;*/
/*    justify-content: flex-start;*/
/*    align-items: flex-start;*/
/*    width: 100%;*/
/*    margin-bottom: 19px;*/
/*}*/

/*.moderator_configure_course_page .notion_creation_card .body form .form-field:nth-child(2){*/
/*    margin-bottom: 41px;*/
/*}*/

/*.moderator_configure_course_page .notion_creation_card .body form .form-field:nth-child(3){*/
/*    margin-bottom: 41px;*/
/*}*/

/*.moderator_configure_course_page .notion_creation_card .body form .form-field:nth-child(4){*/
/*    margin-bottom: 30px;*/
/*}*/

/*.moderator_configure_course_page .notion_creation_card .body form .form-field .NE_normal_textarea textarea{*/
/*    min-height: 286px;*/
/*}*/

/*.moderator_configure_course_page .notion_creation_card .body form ._actions{*/
/*    display: flex;*/
/*    flex-direction: row;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*    width: 100%;*/
/*}*/

/*.moderator_configure_course_page .notion_creation_card .body form .action{*/
/*    margin-right: 8px;*/
/*}*/

/*.moderator_configure_course_page .notion_creation_card .body form .action .NE_button_large{*/
/*    width: 180px;*/
/*}*/

/*.moderator_configure_course_page .notion_creation_card .body form .action:last-child{*/
/*    margin-right: 0;*/
/*}*/

/*.moderator_configure_course_page .quiz_creation_card .body form {*/

/*}*/
/*.document_creation_card .body form{*/
/*    !*display: flex;*!*/
/*    flex-direction: column;*/
/*    justify-content: flex-start;*/
/*    align-items: flex-start;*/
/*    width: 100%;*/
/*}*/




/*.quiz_creation_card .body form{*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    justify-content: flex-start;*/
/*    align-items: center;*/
/*    width: 100%;*/
/*}*/

/*.moderator_configure_course_page .quiz_creation_card .body form .form-field{*/
/*    width: 100%;*/
/*}*/

/*.moderator_configure_course_page .quiz_creation_card .body form .form-field .question{*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    justify-content: flex-start;*/
/*    align-items: flex-start;*/
/*    width: 100%;*/
/*    margin-bottom: 15px;*/
/*}*/

/*.moderator_configure_course_page .quiz_creation_card .body form .form-field .question:last-child{*/
/*    margin-bottom: 0;*/
/*}*/

/*.moderator_configure_course_page .quiz_creation_card .body form .form-field .question .question_options{*/
/*    margin-top: 18px;*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    justify-content: flex-start;*/
/*    align-items: flex-start;*/
/*    width: 100%;*/
/*    list-style: none;*/
/*}*/

/*.moderator_configure_course_page .quiz_creation_card .body form .form-field .question .question_options li{*/
/*    display: flex;*/
/*    flex-direction: row;*/
/*    justify-content: space-between;*/
/*    align-items: center;*/
/*    width: 100%;*/
/*    padding: 14px 15px 14px 20px;*/
/*    margin-bottom: 7px;*/
/*    border-radius: 8px;*/
/*}*/

/*.moderator_configure_course_page .quiz_creation_card .body form .form-field .question .question_options li > .NE_normal_input{*/
/*    display: none;*/
/*}*/

/*.moderator_configure_course_page .quiz_creation_card .body form .form-field .question .question_options li:last-child{*/
/*    margin-bottom: 0;*/
/*}*/

/*.moderator_configure_course_page .quiz_creation_card .body form .form-field .question .question_options li .question_option_action{*/
/*    display: flex;*/
/*    width: 29px;*/
/*    height: 29px;*/
/*    background-image: url('assets/icon-trash.svg');*/
/*    background-repeat: no-repeat;*/
/*    background-position: center;*/
/*    cursor: pointer;*/
/*}*/

/*.moderator_configure_course_page .quiz_creation_card .body form .form-field .question .question_options li.question_option_selected{*/
/*    background-color: rgba(20, 163, 139, 0.16);*/
/*}*/

/*.moderator_configure_course_page .quiz_creation_card .body form .form-field .question .question_options li.question_option_selected .question_option_action{*/
/*    background-image: url('assets/icon-check.svg');*/
/*    cursor: default;*/
/*}*/

/*.moderator_configure_course_page .quiz_creation_card .body form .form-field .question .question_options li.add_question_option{*/
/*    justify-content: flex-start;*/
/*    font-size: 20px;*/
/*    font-weight: 500;*/
/*    color: #3F4351;*/
/*    width: fit-content;*/
/*    cursor: pointer;*/
/*}*/

/*.moderator_configure_course_page .quiz_creation_card .body form .form-field .question .question_options li.add_question_option::before{*/
/*    content: '';*/
/*    display: block;*/
/*    width: 24px;*/
/*    height: 24px;*/
/*    background-image: url('assets/add-small.svg');*/
/*    margin-right: 10px;*/
/*}*/

/*.moderator_configure_course_page .quiz_creation_card .body form .add_question{*/
/*    display: block;*/
/*    width: 50px;*/
/*    height: 50px;*/
/*    background-image: url('assets/add-big.svg');*/
/*    cursor: pointer;*/
/*    margin-top: 16px;*/
/*}*/

/*.moderator_configure_course_page .quiz_creation_card .body form .form-field .question .question_options li.question_option_edit_mode > .NE_normal_input{*/
/*    display: flex;*/
/*}*/

/*.moderator_configure_course_page .quiz_creation_card .body form .form-field .question .question_options li.question_option_edit_mode .NE_radio_button{*/
/*    display: none;*/
/*}*/

/*.moderator_configure_course_page .quiz_creation_card .body form .form-field .question .question_options li.question_option_edit_mode .question_option_action{*/
/*    display: none;*/
/*}*/



/*.moderator_configure_course_page .page_body .create_course_form_wrapper{*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    justify-content: flex-start;*/
/*    align-items: center;*/
/*    width: 1134px;*/
/*    background-color: #fff;*/
/*    box-shadow: 0px 16px 56px #2C273814;*/
/*    border-radius: 9px;*/
/*    padding: 46px 34px;*/
/*    margin-bottom: 20px;*/
/*}*/

/*.moderator_configure_course_page .page_body .create_course_form_wrapper form{*/
/*    margin-top: 33px;*/
/*    width: 100%;*/
/*}*/

/*.moderator_configure_course_page .page_body .create_course_form_wrapper .form-field{*/
/*    width: 100%;*/
/*    margin-bottom: 18px;*/
/*}*/

/*.moderator_configure_course_page .page_body .create_course_form_wrapper .form-field:last-child{*/
/*    margin-bottom: 0px;*/
/*}*/

/*.moderator_configure_course_page .page_body .create_course_form_wrapper .form-actions{*/
/*    display: flex;*/
/*    flex-direction: row;*/
/*    justify-content: flex-end;*/
/*    align-items: center;*/
/*    width: 100%;*/
/*    margin-top: 47px;*/
/*}*/

/*.moderator_configure_course_page .page_body .create_course_form_wrapper .form-action{*/
/*    width: 199px;*/
/*    font-size: 20px*/
/*}*/

/*.moderator_configure_course_page .page_body .create_course_form_wrapper .form-action:first-child{*/
/*    margin-right: 14px;*/
/*}*/

/*.moderator_configure_course_page .page_body .create_course_form_wrapper .form-action:first-child{*/
/*    margin-right: 14px;*/
/*}*/

.moderator_configure_course_page .page_body .create_course_form_wrapper2{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 700px;
    background-color: #fff;
    box-shadow: 0px 16px 56px #2C273814;
    border-radius: 9px;
    padding: 34px;
}

.moderator_configure_course_page .page_body .create_course_form_wrapper2 > h1{
    font-size: 28px;
    font-weight: bold;
    color: #2C2738;
}

.moderator_configure_course_page .page_body .create_course_form_wrapper2 form{
    margin-top: 33px;
    width: 100%;
}

.moderator_configure_course_page .page_body .create_course_form_wrapper2 .form-field{
    width: 100%;
    margin-bottom: 18px;
}

.moderator_configure_course_page .page_body .create_course_form_wrapper2 .form-field:last-child{
    margin-bottom: 0px;
}

.moderator_configure_course_page .page_body .create_course_form_wrapper2 .form-field > .NE_normal_textarea textarea{
    min-height: 120px;
    resize: vertical;
}

.moderator_configure_course_page .page_body .create_course_form_wrapper2 .form-actions{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 34px;
}

.moderator_configure_course_page .page_body .create_course_form_wrapper2 .form-action{
    width: 190px;
    font-size: 17px
}

.moderator_configure_course_page .page_body .create_course_form_wrapper2 .form-action:first-child{
    margin-right: 24px;
}

/*!*Document Style Start *!*/

/*.NE_collapsable_card_with_icon .NE_collapsable_card_with_icon_open .document_creation_card{*/
/*    height: 732px;*/
/*}*/

/*.NE_collapsable_card_with_icon .NE_collapsable_card_with_icon_open .document_creation_card .body {*/
/*    display: flex;*/
/*    flex-direction: row;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*    width: 100%;*/
/*    margin-right: 8px;*/
/*}*/

/*.form-from{*/
/*    flex-direction: column;*/
/*    justify-content: flex-start;*/
/*    align-items: flex-start;*/
/*    width: 100%;*/
/*    padding: 33px 11px;*/
/*}*/

/*._actionsDocument{*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    justify-content: center;*/
/*    margin-top: 45px;*/
/*}*/

/*._actionsDocument {*/
/*    display: flex;*/
/*    justify-content: center;*/

/*}*/

/*._actionsDocument .toggle_button{*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    margin-top: 43px;*/
/*}*/

/*.document_creation_card .body form .action{*/
/*    margin-right: 8px;*/
/*    width: 20%;*/
/*}*/

/*!*Document Style end *!*/



/*._wrapper {*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*    position: relative;*/
/*    top: 254px;*/
/*    width: 100%;*/

/*}*/

/*._wrapper .form_wrapper {*/
/*    margin-bottom: 40px;*/
/*    width: 372px;*/
/*}*/

/*._wrapper .add_enabled {*/
/*    cursor: pointer;*/
/*}*/