.NE_client_addition_card{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
     align-items: flex-start;
    width: 270px;
    background: var(--gradient-color);
    padding: 16px 16px 19px 16px;
    border-radius: 24px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2), 0px 8px 16px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(255, 192, 203, 0);
    border: 1px solid transparent;
    cursor: pointer;
    transition: transform 0.2s ease-in-out, box-shadow 0.3s ease-in-out;
}



.NE_client_addition_card:hover {
    transform: translateY(-3px);
        box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.2), 0px 16px 32px rgba(0, 0, 0, 0.1), 0px 0px 40px rgba(39, 4, 10, 0.7);
        border: 0.5px solid white;
    }


.NE_client_addition_card .card_header{
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 24px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
}

.NE_client_addition_card .card_header .card_action{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 200px;
    border: 1.5px dashed #b0aaaa;
    border-radius: 24px;
}

.NE_client_addition_card .card_header .card_action:hover {

    width: 100%;
    height: 200px;
    border: 2px dashed #f8f5f5;
    border-radius: 24px;
}

.NE_client_addition_card .card_header .card_action img{
    width: 55px;
    height: 55px;
    cursor: pointer;
}

.NE_client_addition_card .card_body{
    /*margin-left: 7px;*/
    margin-top: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.NE_client_addition_card .card_body h1{
    font-weight: bold;
    font-size: 25px;
    color: #fff;
}

.NE_client_addition_card .card_body p{
    font-size: 14px;
    color: #fff;
    margin-top: 9px;
}

.NE_client_addition_card .card_footer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 83px;
}

.NE_client_addition_card button{
    width: 120px;
    height: 42px;
    border-radius: 6px;
    box-shadow: 0px 4px 8px #2C273814;
    border: 1px solid #DBE2EA;
    background-color: #eee7e7;
    color: var(--primary-color);
    font-size: 12px;
    font-weight: bold;
}

.NE_client_addition_card button:hover {
    width: 123px;
    height: 43px;
    box-shadow: 10px 4px 8px #2C273814;
    border: 1px solid #fbfbfb;
    background-color: #ffffff;
    font-size: 13px;
    font-weight: bold;
    cursor: pointer;
}

.NE_progress_circle{
    width: 61px;
    height: 61px;
    border-radius: 100%;
    background-color: #F6F5F5;
}
