.NE_action_progress_card{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 270px;
    min-height: 420px;
    background-color: #fff;
    padding: 16px 16px 19px 16px;
    border-radius: 24px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2), 0px 8px 16px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(255, 192, 203, 0);
    border: 1px solid transparent;
    cursor: pointer;
    transition: transform 0.2s ease-in-out, box-shadow 0.3s ease-in-out;
}

.NE_action_progress_card:hover {
    transform: translateY(-3px);
    box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.2), 0px 16px 32px rgba(0, 0, 0, 0.1), 0px 0px 40px rgba(39, 4, 10, 0.7);
    border: 0.3px solid rgb(246, 65, 219);
}

.NE_action_progress_card .card_header{
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  
}

.NE_action_progress_card .card_header .card_image{
    display: block;
    width: 100%;
    position: relative;
}

.NE_action_progress_card .card_header .card_image img{
    border-radius: 24px;
    width: 100%;
    object-fit: contain;
}

.NE_action_progress_card .card_header .card_image .card_category{
    position: absolute;
    top:10px;
    right:8px;
    width: fit-content;
    padding: 5px 12px;
    border-radius: 24px;
    color: #5a14f0;
    border:#303031;
    font-weight: 500;
    font-size: 14px;
    background: rgba(255, 255, 255, 0.74);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(1.2px);
    -webkit-backdrop-filter: blur(1.2px);
    border: 1px solid rgba(255, 255, 255, 0.99);
}

.NE_action_progress_card .card_header .card_secondary_text{
    /*margin-left: 7px;*/
    margin-top: 5px;
    color: #989898;
    font-size: 12px;
}

.NE_action_progress_card .card_body{
    margin-left: 7px;
    margin-top: 5px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.NE_action_progress_card .card_action_body h4{
   font-size: 16px;
    font-weight: bold;
}

.NE_action_progress_card .card_body h1{
    font-weight: bold;
    font-size: 12px;
    color: #2C2738;

}

.NE_action_progress_card .card_body p{
    font-size: 14px;
    color: #2C2738;
    margin-top: 9px;
}

.NE_action_progress_card .card_footer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    /*background-color: #ccc;*/
    margin-top: 13px;
}

.NE_action_progress_card button{
    width: 110px;
    height: 35px;
    border-radius: 6px;
    box-shadow: 0px 4px 8px #2C273814;
    border: 1px solid #DBE2EA;
    background: var(--gradient-color);
    color: #fbfbfb;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
}

.NE_action_progress_card button:hover {
    width: 111px;
    height: 36px;
    box-shadow: 10px 4px 8px #2C273814;
    font-size: 15px;
    font-weight: bold;
    cursor: pointer;
    color: #f7f7f7;
    border: 1.2px solid #ed06ce;
}

.NE_progress_circle{
    width: 61px;
    height: 61px;
    border-radius: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.NE_progress_circle svg{
    width: 61px;
    height: 61px;
    transform: rotate(-90deg);
    background-color: #F6F5F5;
    border-radius: 100%;
}

.NE_progress_circle svg circle{
    stroke:#5a14f0;
    stroke-width:6;
    fill:#F6F5F5;
}

.NE_progress_circle .progress_infos{
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 47px;
    height: 47px;
    background-color: #fff;
    border-radius: 100%;
}



.NE_progress_circle .progress_infos .progress_percentage{
    font-size: 12px;
    font-weight: 600;
    color: #2C2738;
    line-height: 18px;
    margin-bottom: 0 !important;
    margin-top: 6px;
}

.NE_progress_circle .progress_infos .progress_ratio{
    font-size: 10px;
    font-weight: 500;
    color: #2C2738;
    margin-bottom: 0 !important;
}
