


 .NE_comments_tree {
    margin-left: 22px;
    width: 96%;
}

.NE_etudiant_discussion .NE_etudiant_header {
    /* margin-top: 43px; */
    margin-left: 42px;
    width: 100%;
    height: 37px;
    margin-bottom: 30px;
}

.NE_etudiant_discussion .NE_etudiant_header p{
    font-size: 28px;
    font-weight: bold;
    letter-spacing: 0px;
    color: #2C2738;
    margin-top: 28px;
    margin-left: -18px;
    /* position: absolute; */
    /* top: 120px;
    left: 480px; */
}

.NE_etudiant_discussion .two_button{
    display: flex;
    justify-content: flex-end;
    width: 100%;
    position: relative;
    top: -24px;
}

.NE_etudiant_discussion .two_button .button_size {
    margin-right: 14px;
    width: 205px;
    height: 48px;
}


.NE_etudiant_discussion .NE_discussion .NE_body_content {
    display: flex;
    /* flex-direction: column; */
    /* align-items: center; */
}

.NE_etudiant_discussion .NE_discussion .NE_body_content  .NE_body_list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 10px;
    margin-left: 29px;
}

.NE_etudiant_discussion .NE_discussion .NE_body_content  .NE_body_list .NE_body_paragraph .comment{
    font: normal normal normal 16px/24px IBM Plex Sans;
    letter-spacing: -0.27px;
    color: #141414;
}

.NE_etudiant_discussion .NE_discussion .NE_body_content  .NE_body_list .NE_body_paragraph .comment_content .comment{
    width: 755px;
    height: 56px;
    margin-top: 23px;
    margin-bottom: 8px;
}

.NE_etudiant_discussion .NE_discussion .NE_body_content  .NE_body_list .NE_body_paragraph .reply{
    letter-spacing: -0.23px;
    color: var(--primary-color);
}

.NE_etudiant_discussion .NE_discussion .NE_body_content .hr_line{
    width: 100%;
    /* width: 1335px; */
    height: 0px;
    background: #F6F5F5 0% 0% no-repeat padding-box;
    border: 2px solid #E7E7E7;
    margin-top: 22px;
}


.NE_etudiant_discussion .NE_discussion .NE_body_content img {
    width: 40px;
    height: 40px;
    border-radius: 60px;
}


.border_button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 34px;
    border: 2px solid var(--primary-color);
    border-radius: 3px;
}

.NE_body_doc .NE_inline_items img {
    margin-left: 33px;
    margin-right: 30px;
}

.NE_body_doc .NE_inline_items .NE_inline_item_text {
    margin-right: 136px;
}

.NE_body_doc .NE_inline_items .NE_inline_item_size {
    margin-right: 619px;
}

.normal_button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 34px;
    background: var(--primary-color) 0% 0% no-repeat padding-box;
    box-shadow: 0px 12px 24px #2C273829;
    border-radius: 3px;
    margin-left: 24px;
}

.header_button {
    display: flex;
    justify-content: center;
    position: relative;
    top: 70px;
    width: 360px;
    height: 56px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 24px 48px #2C273814;
    border-radius: 6px;

}
.active_button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 179px;
    height: 56px;
    background: var(--primary-color) 0% 0% no-repeat padding-box;
    box-shadow: 0px 24px 48px #2C273814;
    border-radius: 6px;
    cursor: pointer;
}
.disabled_button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 179px;
    height: 56px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 24px 48px #2C273814;
    border-radius: 6px;
    cursor: pointer;
}

.header_button .active_button p {
    font: normal normal bold 24px/32px IBM Plex Sans;
    letter-spacing: 0px;
    color: #FFFFFF;
}

.header_button .disabled_button p {
    font: normal normal bold 24px/32px IBM Plex Sans;
    letter-spacing: 0px;
    color: #7C9CBF;
}

.NE_hr {
    margin-bottom: 35px;
    margin-top: 35px;
    margin-left: 33px;
    width: 1253px;
    height: 0px;
    border: 2px solid #F5F6FA;
    background-color: #F5F6FA;
}
.NE_inline_items .border_button p {
    font: normal normal bold 16px/20px IBM Plex Sans;
    letter-spacing: 0px;
    color: var(--primary-color);
}

.NE_inline_items .normal_button p {
    font: normal normal bold 16px/20px IBM Plex Sans;
    letter-spacing: 0px;
    color: #EBF4F8;
}

.send_button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 34px;
    background: var(--primary-color) 0% 0% no-repeat padding-box;
    box-shadow: 0px 12px 24px #2C273814;
    border-radius: 3px;
    cursor: pointer;
    position: absolute;

    right: 10px;
    top: 50%;
    transform: translateY(-15%);
}

.send_button h3 {
    font: normal normal bold 16px/20px IBM Plex Sans;
    letter-spacing: 0px;
    color: #FFFFFF;
}


.NE_comments_tree{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    /* width: 100%; */
    width: 1123px;
    height: 713px;
    background-color: #fff;
}

.NE_comments_tree .comments_list{
    /* display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: 684px;
    overflow-y: scroll;

    border: 1px solid red; */
    /* -ms-overflow-style: none; */
    /* scrollbar-width: none; */
    flex: 1 1 auto;
    overflow-y: auto;
    min-height: 0px;
    padding-right: 10px;
}

.NE_comments_tree .comments_list .comment{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    border-bottom: 2px solid #E7E7E7;
    padding-bottom: 22px;
    margin-bottom: 30px;
}

.NE_comments_tree .comments_list .comment:last-child{
    margin-bottom: 0px;
    border-bottom: none;
}

.NE_comments_tree .comments_list .comment .comment_header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 14px;
}

.NE_comments_tree .comments_list .comment .comment_header .comment_user{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.NE_comments_tree .comments_list .comment .comment_header .comment_user img{
    width: 42px;
    height: 42px;
    border-radius: 100%;
    margin-right: 13px;
}

.NE_comments_tree .comments_list .comment .comment_header .comment_user h1{
    font-size: 18px;
    font-weight: bold;
    letter-spacing: -0.3px;
    color: #2C2738;
}

.NE_comments_tree .comments_list .comment .comment_header .comment_date{
    font-size: 15px !important;
    font-weight: normal;
    color: #212121;
    font-family: "Arial";

    /*background-color: #3B5998;*/
}

.NE_comments_tree .comments_list .comment .comment_body{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    padding-left: 55px;
}

.NE_comments_tree .comments_list .comment .comment_body p{
    font-size: 16px;
    letter-spacing: -0.27px;
    color: #141414;
    max-width: 755px;
    margin-bottom: 20px;
}

.NE_comments_tree .comments_list .comment .comment_body span{
    font-size: 14px;
    font-weight: bold;
    letter-spacing: -0.23px;
    color: var(--primary-color);
    cursor: pointer;
}

.NE_comments_tree .comments_list .comment .comment_body .sub_comments{
    width: 100%;
}

.NE_comments_tree .comments_list .comment .comment_body .sub_comments .comment{
    border-bottom: none;
    border-top: 2px solid #E7E7E7;
    margin-bottom: 0;
    padding-top: 8px;
}

.NE_comments_tree .comments_list .comment .comment_body .sub_comments .comment:first-child{
    margin-top: 22px;
}

.NE_comments_tree .reply_box{
    width: 100%;
    margin-top: 15px;
    padding-left: 34px;
}


.NE_normal_input_with_action_button {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    position: relative;
}

.NE_normal_input_with_action_button label {
    font-size: 20px;
    font-weight: 500;
    color: #3f4351;
    margin-bottom: 6px;
}

.NE_normal_input_with_action_button input {
    width: 100%;
    height: 52px;
    border: 1px solid #dbe2ea;
    border-radius: 6px;
    box-shadow: 0px 4px 8px #2c27380a;
    padding: 16px 122px 16px 16px;
    font-size: 16px;
    color: #2c2738;
    margin-bottom: 6px;
}

.NE_normal_input_with_action_button input::placeholder{
    font-size: 18px;
    color: #989898;
}

.NE_normal_input_with_action_button input:focus{
    border: 2px solid #68A4F5;
    padding: 15px 121px 15px 15px;
}

.NE_normal_input_with_action_button input:disabled{
    background-color: #ebf4f8;
    color: #989898;
}

.NE_normal_input_with_action_button p {
    display: none;
    font-size: 14px;
}

.NE_normal_input_with_action_button button {
    border:none;
    position: absolute;
    right:12px;
    top: 42px;
    width: 100px;
    height: 34px;
    box-shadow: 0px 12px 24px #2C273814;
    border-radius: 3px;
    background-color: var(--primary-color);
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
}

.NE_normal_input_with_action_button_success p{
    display: block;
    color: #14a38b;
}

.NE_normal_input_with_action_button_error p{
    display: block;
    color: #ff7171;
}

.NE_notionItem_header_button .active_button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 205px;
    height: 48px;
    background: var(--primary-color) 0% 0% no-repeat padding-box;
    box-shadow: 0px 24px 48px #2c273814;
    border-radius: 6px;
    cursor: pointer;
  }
  .NE_notionItem_header_button .disabled_button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 205px;
    height: 48px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 2px solid var(--primary-color);

    box-shadow: 0px 24px 48px #2c273814;
    border-radius: 6px;
    cursor: pointer;
  }

  .NE_notionItem_header_button .active_button span {
    font: normal normal bold 20px/32px IBM Plex Sans;
    letter-spacing: 0px;
    color: #ffffff;
  }

  .NE_notionItem_header_button .disabled_button span {
    font: normal normal bold 20px/32px IBM Plex Sans;
    letter-spacing: 0px;
    color: var(--primary-color);
  }

  .pl-0{
      padding-left: 0 !important;
  }
