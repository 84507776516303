.header_modal_wrapper{
    display: none;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 3;
    top:0;
    left:0;
    bottom: 0;
    width: 100%;

}

.header_modal_open{
    display: flex;
    
}

.NE_header{
    display: flex;
    flex-direction: row !important;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 60px;
    background-color: #f3f3f3;
    box-shadow: 0px 4px 8px #2C27380A;
    border-radius: 0 0 10px 10px;
    padding: 0 14px;
    position: relative;
}

.container{
    /*width: 1640px;*/
    height: 60px;
    /*background-color: #cfc;*/
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    flex:1;
    padding: 0 15%;
    
}

.NE_header .logo{
    width: 130px;
    height: 43px;
}

.NE_header .logo img{
    height: 43px;
    object-fit: contain;
}

.NE_header .actions{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    /*background-color: red;*/
    height: 40px;
}

.NE_header .actions .input_wrapper{
    margin-right: 16px;
}

.NE_header .actions input{
    width: 360px;
    height: 40px;
    border: 1px solid #DBE2EA;
    border-radius: 6px;
    padding: 10px 57px 10px 16px;
    font-size: 18px;
}

.NE_avatar{

    border-radius: 100%;
    cursor: pointer;
}

.NE_avatar img{
    width: 34px;
    height: 34px;
    border-radius: 100%;
}

.NE_header .alerts_container{
    display: flex;
    flex-direction: column;
    width: 100%;
    position: absolute;
    top:60px;
    left:0;
    
}

.NE_header .profile_popup_container{
    display: none;
    flex-direction: column;
    width: fit-content;
    position: absolute;
    top:90px;
    right:0;
    z-index: 100;
}

.NE_header .profile_popup_container_open{
    display: flex;
}

.close_md{
    background-color: rgba(59, 89, 152, 0.07);
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
}
