.NE_radio_button{
    width: fit-content;
    height: fit-content;
}

.NE_radio_button input[type=radio]{
    display: none;
}

.NE_radio_button input[type=radio] + label{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-size: 20px;
    font-weight: 500;
    color: #3F4351;
    cursor: pointer;
}

.NE_radio_button input[type=radio] + label::before{
    content: '';
    display: block;
    width: 24px;
    height: 24px;
    background-color: #fff;
    border-radius: 100%;
    margin-right: 10px;
    cursor: pointer;
    box-shadow: 0px 4px 8px #2C273814;
    border: 1px solid #DBE2EA;
}

.NE_radio_button input[type=radio]:checked + label::before{
    background-image: url('assets/radio-checked.svg');
    background-repeat: no-repeat;
    background-position: center;
}

.NE_radio_button input[type=radio]:disabled + label::before{
    box-shadow: 0px 4px 8px #2C27380A;
    border: 1px solid #DBE2EA;
    background-color: #EBF4F8;
}

.NE_radio_button input[type=radio]:disabled:checked + label::before{
    background-image: url('assets/radio-checked-disabled.svg');
}
