.NE_dropdown {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    position: relative;
    margin-top: 8px;
}

.NE_dropdown label {
    font-size: 15px;
    font-weight: 500;
    color: #3f4351;
    margin-bottom: 6px;
}

.NE_dropdown .dropdown{
    width: 100%;
    height: 52px;
    background-color: #fff;
    box-shadow: 0px 4px 8px #2C27380A;
    border: 1px solid #DBE2EA;
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px 20px 16px 16px;
    cursor: pointer;
}

.NE_dropdown .dropdown p{
    font-size: 16px;
    color: #2C2738;
}

.NE_dropdown .dropdown  .down_button {
    background-image: url('assets/Icon-Chevron.svg');
    background-repeat: no-repeat;
    background-position: 50% 50%;
    width: 16px;
    height: 9px;
}

.NE_dropdown .dropdown_options{
    display: none;
    position: absolute;
    top: 90px;
    left: 0;
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 20px 20px #2C27380A;
    border: 1px solid #DBE2EA;
    border-radius: 6px;
    padding: 12px 0px;
}

.NE_dropdown .dropdown_options .dropdown_option{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0 16px;
    width: 100%;
    height: 44px;
    font-size: 16px;
    color: #3F4351;
    cursor: pointer;
}

.NE_dropdown .dropdown_options .dropdown_option_selected{
    background-color: #EBF4F8;
}

.NE_dropdown .dropdown_options .dropdown_option_disabled{
    color: #2C27383D;
    cursor: not-allowed;
}

.NE_dropdown_open .dropdown{
    border: 2px solid #68A4F5;
    padding: 15px 19px 15px 15px;
}

.NE_dropdown_open .dropdown_options{
    display: block;
}

.NE_dropdown_open .dropdown .down_button {
    transform: rotate(180deg);
}

.NE_dropdown > p {
    display: none;
    margin-top: 6px;
    font-size: 14px;
}

.NE_dropdown_success > p{
    display: block;
    color: #14a38b;
}

.NE_dropdown_error > p{
    display: block;
    color: #ff7171;
}

.NE_dropdown_disabled .dropdown{
    background-color: #ebf4f8;
    cursor: default;
}

.NE_dropdown_disabled .dropdown p{
    color: #989898;
}