.choose_business_game_modal_wrapper{
    display: none;
    justify-content: center;
    align-items: center;
    position: fixed;
    top:0;
    left:0;
    bottom: 0;
    width: 100%;
    background-color: rgba(44, 39, 56, 0.6);
}

.choose_business_game_modal_open{
    display: flex;
}

.choose_business_game_modal_wrapper .NE_body {
    width: 985px;
    background: #FFFFFF;
    box-shadow: 0 32px 64px #2C273814;
    border: 1px solid #707070;
    height: 650px;
    border-radius: 16px;
}

.choose_business_game_modal_wrapper .NE_body  .NE_text {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.choose_business_game_modal_wrapper .NE_body  .NE_text .NE_text_body {
    /*
        font-weight: 500;
        letter-spacing: -0.24px; */

    margin: 16px 57px 18px;
    font-size: 34px;
    text-align: left;
    letter-spacing: -0.34px;
    color: #2C2738;
    font-weight: 600;
    cursor: pointer;
}

.choose_business_game_modal_wrapper .NE_body .NE_search_bar {
    /* width: 100%; */
    width: 888px;
    height: 40px;
    margin: auto auto 35px;
}

.choose_business_game_modal_wrapper .NE_body .NE_body_content {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 23px 5px;
    width: 880px;
    height: 100% !important;
    max-height: 400px;
    overflow-y: scroll;
    overflow-x: hidden;
    margin: 0 auto;
    padding-bottom: 12px;
}

.choose_business_game_modal_wrapper .NE_body .NE_body_content::-webkit-scrollbar{
    width: 10px;
}

.choose_business_game_modal_wrapper .NE_body .NE_body_content::-webkit-scrollbar-track{
    background-color: #DBE2EA;
    border-radius: 6px;
}

.choose_business_game_modal_wrapper .NE_body .NE_body_content::-webkit-scrollbar-thumb{
    background-color: var(--primary-color);
    border-radius: 6px;
}



.choose_business_game_modal_wrapper .NE_body .form-actions {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.choose_business_game_modal_wrapper .NE_body .form-actions .form-action {
    width: 180px;
    height: 56px;
    margin-right: 8px;
}

.choose_business_game_modal_wrapper .NE_text_header {
    display: flex;
    justify-content: space-between;
}






.choose_business_game_modal_wrapper .NE_module_evaluation_card {
    width: 296px;
    height: 361px;
    margin-right: 23px;
    margin-bottom: 21px;
    cursor: pointer;
}

