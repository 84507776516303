.NE_images_uploader{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    /*background-color: #fff;*/
    /*padding: 20px;*/
}

.NE_images_uploader .drop_zone{
    width: 100%;
    padding: 33px 0;
    box-shadow: 0px 4px 8px #2C27380A;
    border: 2px dashed #DAE1ED;
    border-radius: 4px;
    background-color: #FAFCFD;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
}

.NE_images_uploader .drop_zone .drop_zone_label{
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
}

.NE_images_uploader .drop_zone .drop_zone_label img{
    width: 56px;
    height: 52px;
    margin-bottom: 19px;
    position: initial;
}

.NE_images_uploader .drop_zone .drop_zone_label h1{
    font-weight: bold;
    color: #989898;
    font-size: 20px;
    margin-bottom: 10px;
}

.NE_images_uploader .drop_zone .drop_zone_label input::file-selector-button {
    padding: 0.5em;
    border: thin solid grey;
    border-radius: 3px;
    font-size: 18px;
    color: #989898;
    cursor: pointer;

}

.NE_images_uploader .drop_zone .drop_zone_label input{
    font-size: 18px;
    color: #989898;
    margin-left: 80px;
    cursor: pointer;
    width: 50px o !important;

}

.NE_images_uploader .drop_zone p{
    color: #989898;
    font-size: 18px;
    margin-bottom: 10px;
}

.NE_images_uploader .drop_zone .drop_zone_label input{
    margin: 0;
    padding: 3px;
}

.NE_images_uploader .drop_zone p span{
    color: var(--primary-color);
    font-size: 16px;
    cursor: pointer;
}

.NE_images_uploader .upload_vault{
    margin-top: 38px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.NE_images_uploader .upload_vault .upload_item{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 20px;
}

.NE_images_uploader .upload_vault .upload_item:last-child{
    margin-bottom: 0;
}

.NE_images_uploader .upload_vault .upload_item .upload_item_icon{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 30px;
}

.NE_images_uploader .upload_vault .upload_item .upload_item_icon img{
    width: 20px;
    height: 18px;
    margin-top: 6px;
}

.NE_images_uploader .upload_vault .upload_item .upload_item_content{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
}


.NE_images_uploader .upload_vault .upload_item .upload_item_content .upload_item_header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 32px;
}

.NE_images_uploader .upload_vault .upload_item .upload_item_content .upload_item_header h1{
    display: flex;
    font-weight: bold;
    color: #101426;
    font-size: 16px;
    align-self: flex-start;
    line-height: 18px;
    margin-top: 6px;
}

.NE_images_uploader .upload_vault .upload_item .upload_item_content .upload_item_header button{
    border: none;
    padding: 8px 21px;
    border-radius: 20px;
    background-color: #FAF0F0;
    color: #FA5D5D;
    font-size: 12px;
    font-weight: 600;
    cursor: pointer;
}

.NE_images_uploader .upload_vault .upload_item .upload_item_content .upload_item_progress{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 8px;
}

.NE_images_uploader .upload_vault .upload_item .upload_item_content .upload_item_progress .progress_bar{
    width: 100%;
    height: 6px;
    border-radius: 3px;
    background-color: #E9EDF4;
}

.NE_images_uploader .upload_vault .upload_item .upload_item_content .upload_item_progress .progress_bar .progress_level{
    height: 6px;
    border-radius: 3px;
    background-color: #0676ED;
}

.NE_images_uploader .upload_vault .upload_item .upload_item_content .upload_item_progress .progress_infos{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 8px;
}

.NE_images_uploader .upload_vault .upload_item .upload_item_content .upload_item_progress .progress_infos .progress_title{
    font-size: 10px;
    color: #8F9BB3;
    letter-spacing: 0.25px
}

.NE_images_uploader .upload_vault .upload_item .upload_item_content .upload_item_progress .progress_infos .progress_percentage{
    font-size: 11px;
    font-weight: bold;
    color: #8F9BB3;
    letter-spacing: 0.28px;
}
.upload-button_img{
    display: none;
}



#upload-button-complete{
    width: 378px !important;
}