.userinfo_page_header{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 242px;
    background-repeat: no-repeat;
    background-size: cover;
}

.userinfo_page_body{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* justify-content: flex-start;
    align-items: flex-start; */
    width: 1640px;
    /*margin-top: -137px;*/
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 20px;
}

.userinfo_page_body .userinfo_content{
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    width: 75%;
    height: 620px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 16px 56px #2C273814;
    border-radius: 9px;
    /*display: flex;*/
    /*flex-direction: row;*/
    /*justify-content: space-between;*/
    /*align-items: flex-start;*/
    /* display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 28px;
    width: 100%; */
}

.userinfo_page_body .userinfo_content_small{
    display: flex;
    flex-direction: column;
    width: 75%;
    height: 620px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 16px 56px #2C273814;
    border-radius: 9px;
    
}

.userinfo_page_body .userinfo_content_fb_gl{
    display: flex;
    flex-direction: column;
    width: 1134px;
    height: 775px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 16px 56px #2C273814;
    border-radius: 9px;
}


.userinfo_NE_ {
    width: 1134px;
    height: 1100px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 16px 56px #2C273814;
    border-radius: 9px;
}


.userinfo_NE_header{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 60px;
    background-color: #fff;
    box-shadow: 0px 4px 8px #2c27380a;
    border-radius: 0 0 10px 10px;
    padding: 0 14px;
}

.userinfo_NE_header_edit {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin-top: 25px;
    margin-bottom: 12px;

}

.userinfo_NE_header_edit label {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: flex-start;
    margin-left: 79px;
    /* background-image: url(https://avatars.dicebear.com/api/initials/Youness.svg?radius=50&size=34); */
}

.userinfo_NE_header_edit img {
    height: 50px;
    width: 50px;
    margin-right: 20px;
    border-radius: 60px;
}
.userinfo_NE_header_edit p {
    text-decoration: underline;
    letter-spacing: 0px;
    font-size: 16px;
    letter-spacing: 0px;
    color: var(--primary-color);
}

.userinfo_description {
    display: flex;
    justify-content: space-between;
    width: 977px;
    /*height: 148px;*/
    margin-left: 79px;
    margin-bottom: 0px;
}
.containerinfo{
    margin-left: 79px;
}

.userinfo_h {
    display: flex;
    /* justify-content: space-between; */
    flex-direction: row;
    gap: 20px;
    width: 977px;
    height: 80px;
    margin-top: 8px;
}

.userinfo_h .header_align_change_password{
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
}
.userinfo_h .userinfo_change_password{
    font-size: 14px;
    text-decoration: underline;
    letter-spacing: 0px;
    color: var(--primary-color);
    position: absolute;
    right: 10px;
    top: 37px;
}

.userinfo_h_ {

    width: 475px;
    height: 85px;
}

.userinfo_hh {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.userinfo_footer {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 18px;
}
.userinfo_footer .userinfo_vwidth {
    width: 180px;
    height: 56px;
    margin-top:45px;
    margin-left: 12px;
    margin-right: 14px; 
}

.userinfo_footer .userinfo_hwidth {
    width: 180px;
    height: 56px;
    margin-top: 45px;
    margin-right: 13px;
}

.alerts_container_user{
    position: relative;
}

.alerts_container_user .NE_alert{
    position: absolute;
}

.error_password_match{
    color: red;
}
