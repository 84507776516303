.NE_comments_tree{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
     width: 100%;
    /*width: 1123px;*/
    height: 713px;
    background-color: #fff;
}

.NE_comments_tree .comments_list{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: 684px;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.NE_comments_tree .comments_list::-webkit-scrollbar{
    /* display: none; */
}

.NE_comments_tree .comments_list .comment{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    border-bottom: 2px solid #E7E7E7;
    padding-bottom: 22px;
    margin-bottom: 30px;
}

.NE_comments_tree .comments_list .comment:last-of-type{
    margin-bottom: 0px;
    border-bottom: none;
}

.NE_comments_tree .comments_list .comment .comment_header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 14px;
}

.NE_comments_tree .comments_list .comment .comment_header .comment_user{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.NE_comments_tree .comments_list .comment .comment_header .comment_user img{
    width: 42px;
    height: 42px;
    border-radius: 100%;
    margin-right: 13px;
}

.NE_comments_tree .comments_list .comment .comment_header .comment_user h1{
    font-size: 18px;
    font-weight: bold;
    letter-spacing: -0.3px;
    color: #2C2738;
}

.NE_comments_tree .comments_list .comment .comment_header .comment_date{
    font-size: 11px;
    font-weight: normal;
    color: #212121;
    font-family: "Arial";
}

.NE_comments_tree .comments_list .comment .comment_body{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    padding-left: 55px;
}

.NE_comments_tree .comments_list .comment .comment_body p{
    font-size: 16px;
    letter-spacing: -0.27px;
    color: #141414;
    max-width: 755px;
    margin-bottom: 20px;
}

.NE_comments_tree .comments_list .comment .comment_body span{
    font-size: 14px;
    font-weight: bold;
    letter-spacing: -0.23px;
    color: var(--primary-color);
    cursor: pointer;
}

.NE_comments_tree .comments_list .comment .comment_body .sub_comments{
    width: 100%;
}

.NE_comments_tree .comments_list .comment .comment_body .sub_comments .comment{
    border-bottom: none;
    border-top: 2px solid #E7E7E7;
    margin-bottom: 0;
    padding-top: 8px;
}

.NE_comments_tree .comments_list .comment .comment_body .sub_comments .comment:first-child{
    margin-top: 22px;
}

.NE_comments_tree .reply_box{
    width: 100%;
    /*margin-top: 67px;*/
    padding-left: 34px;
}