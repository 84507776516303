.NE_latest_comments_card{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 310px;
    /*max-height: 854px;*/
    background-color: #fff;
    padding: 10px;
    border-radius: 24px;
    box-shadow: 0px 4px 8px #2C273814;
    border: 1px solid #DBE2EA;
    height: 100%;

}

.NE_latest_comments_card .card_header{
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 25px;
}

.NE_latest_comments_card .card_header .card_image{
    display: block;
    width: 100%;
}

.NE_latest_comments_card .card_header .card_image img{
    border-radius: 16px;
    width: 100%;
}

.NE_latest_comments_card .card_body{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    overflow: hidden;
    height: 100%;
}

.NE_latest_comments_card .card_body h1{
    font-weight: bold;
    font-size: 20px;
    color: #2C2738;
    margin-left: 32px;
}

.NE_latest_comments_card .card_body .comments_wrapper{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    max-height: calc(100% - 70px);
    overflow-y: scroll;
    overflow-x: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.NE_latest_comments_card .card_body .comments_wrapper::-webkit-scrollbar{
    display: none;
}

.NE_latest_comments_card .card_body .comments_wrapper .comment{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 19px;
}

.NE_latest_comments_card .card_body .comments_wrapper .comment .comment_body{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
}

.NE_latest_comments_card .card_body .comments_wrapper .comment .comment_body img{
    border-radius: 100%;
}

.NE_latest_comments_card .card_body .comments_wrapper .comment .comment_body .comment_infos{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
}

.NE_latest_comments_card .card_body .comments_wrapper .comment .comment_body .comment_infos .comment_user{
    margin-left: 9px;
    margin-bottom: 12px;
    width: 90%;
}

.NE_latest_comments_card .card_body .comments_wrapper .comment .comment_body .comment_infos .comment_user h2{
    font-size: 18px;
    color: #2C2738;
    font-weight: bold;
}

.NE_latest_comments_card .card_body .comments_wrapper .comment .comment_body .comment_infos .comment_user p{
    font-size: 16px;
    color: #2C2738;
}

.NE_latest_comments_card .card_body .comments_wrapper .comment .comment_body .comment_infos .comment_user p .course_name{
    font-weight: 600;
    cursor: pointer;
}

.NE_latest_comments_card .card_body .comments_wrapper .comment .comment_body .comment_infos .comment_text{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    padding: 9px 11px;
    background-color: #F6F5F5;
    border-radius: 12px;
    font-size: 16px;
    color: #2C2738;
    border-top-left-radius: 0px;

}

.NE_latest_comments_card .card_body .comments_wrapper .comment .comment_body .comment_infos .comment_text .comment_text_paragraph {
    font-size: 16px;
    margin-left: 11px;
}


.NE_latest_comments_card .card_body .comments_wrapper .comment:last-child{
    margin-bottom: 0;
}
