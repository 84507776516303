
.participant_points {
    /*display: flex;*/
    flex-direction: column;
    align-items: center;
}

.page_body_profile{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* justify-content: flex-start;
    align-items: flex-start; */
    width: 1640px;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 20px;
}

.page_body_profile .content{
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    width: 1134px;
    height: 1100px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 16px 56px #2C273814;
    border-radius: 9px;
    /*display: flex;*/
    /*flex-direction: row;*/
    /*justify-content: space-between;*/
    /*align-items: flex-start;*/
    /* display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 28px;
    width: 100%; */
}

.NE_ {
    width: 1134px;
    height: 1100px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 16px 56px #2C273814;
    border-radius: 9px;
}


.NE_header{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 60px;
    background: var(--gradient-color);
    box-shadow: 0px 4px 8px #2c27380a;
    border-radius: 0 0 10px 10px;
    padding: 0 14px;
}

.NE_header_edit {
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 100%;
    margin-top: 30px;
    margin-left: 76px;
    margin-bottom: 26px;
}

.NE_header_edit img {
    height: 50px;
    width: 50px;
    margin-right: 20px;
    border-radius: 60px;
}
.NE_header_edit p {
    font-weight: bold;
    font-size: 24px;
    text-decoration: underline;
    letter-spacing: 0px;
    color: var(--primary-color);
}

.h {
    display: flex;
    justify-content: space-between;
    width: 977px;
    height: 140px;
    margin-left: 79px;
}

.h .change_password{
    font-size: 20px;
    text-decoration: underline;
    letter-spacing: 0px;
    color: var(--primary-color);
    position: absolute;
    right: 386px;
    top: 740px;
}

.h_ {

    width: 475px;
    height: 85px;
}

.hh {
    display: flex;
    justify-content: space-between;
}

.hhh {
    display: flex;
    justify-content: center;
}
.hhh .vwidth {
    width: 180px;
    height: 56px;
    margin-left: 4px;
    /* margin-right: 4px; */
}

.hhh .hwidth {
    width: 180px;
    height: 56px;
    margin-right: 4px;
}

/*@media screen and (max-width: 768px) {*/
/*    .login_right {*/
/*        width: 0;*/
/*    }*/
/*    .login_left {*/
/*        display: flex;*/
/*        left: 0;*/
/*        background-color: #fff;*/
/*        justify-content: center;*/
/*        width: 100%;*/
/*    }*/
/*    .login_centered .login_NE_contain {*/
/*        width: 90%;*/

/*    }*/
/*}*/
