.NE_client_info_card_with_actions {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 296px;
    height: 100%;
    background: #FFFFFF;
    box-shadow: 0px 24px 48px #2C27380A;
    border-radius: 24px;
    padding: 37px 31px 27px 31px;
}

.NE_client_info_card_with_actions .header{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    position: relative;
}

.NE_client_info_card_with_actions .header .actions_btn{
    width: 30px;
    height: 30px;
    background-image: url('assets/actions-icon.svg');
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    top: -14px;
    right: -12px;
    cursor: pointer;
}

.NE_client_info_card_with_actions .header img{
    width: 145px;
    height: 140px;
    border-radius: 100%;
    border: 3px solid;
    border-image: var(--gradient-color) 1;
    margin-bottom: 7px;
}

.NE_client_info_card_with_actions .header h1 {
    font-size: 28px;
    font-weight: bold;
    color: #2C2738;
}

.NE_client_info_card_with_actions .header p {
    font-size: 16px;
    font-weight: normal;
    color: #989898;
}


.NE_client_info_card_with_actions .body{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-top: 13px;
}

.NE_client_info_card_with_actions .body .phone{
    width: fit-content;
    margin-bottom: 19px;
}

.NE_client_info_card_with_actions .body .phone p{
    font-size: 18px;
    font-weight: bold;
    color: var(--secondary-color);
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.NE_client_info_card_with_actions .body .phone p::before{
    content: '';
    display: block;
    background-image: url("assets/icon-phone.svg");
    background-repeat: no-repeat;
    width: 15px;
    height: 15px;
    margin-right: 7px;
}

.NE_client_info_card_with_actions .body .address{
    width: fit-content;
}

.NE_client_info_card_with_actions .body .address p{
    font-size: 16px;
    color: #9F9F9F;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    line-height: 25px;
}

.NE_client_info_card_with_actions .body .address p::before{
    content: '';
    display: block;
    background-image: url("assets/icon-address.svg");
    background-repeat: no-repeat;
    width: 30px;
    height: 16px;
    margin-right: 16px;
    margin-top: 3px;
}

.NE_client_info_card_with_actions .footer{
    margin: 54px -17px 0;

}

.NE_client_info_card_with_actions .footer button{
    margin-bottom: 28px;
}

.NE_client_info_card_with_actions .footer button:last-child{
    margin-bottom: 0;
}