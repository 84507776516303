.NE_module_evaluation_card{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 279px;
    background-color: #fff;
    padding: 16px 16px 19px 16px;
    border-radius: 24px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2), 0px 8px 16px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(255, 192, 203, 0);
    border: 1px solid #DBE2EA;
    cursor: pointer;
    min-height: 425px;
    transition: transform 0.2s ease-in-out, box-shadow 0.3s ease-in-out;
}

.NE_module_evaluation_card:hover {
    transform: translateY(-3px);
    box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.2), 0px 16px 32px rgba(0, 0, 0, 0.1), 0px 0px 40px rgba(39, 4, 10, 0.7);
    border: 0.3px solid rgb(246, 65, 219);
}

.NE_module_evaluation_card .card_header{
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
}


.NE_module_evaluation_card .card_header .actions_wrapper {
    position: absolute;
    top: -5px;
    right: 29px;
    display: none;
}

.NE_module_evaluation_card .card_header .actions_wrapper_open {
    display: block;
}


.NE_module_evaluation_card .card_header .actions_btn{
    width: 20px;
    height: 20px;
    background-image: url('assets/actions-icon.svg');
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    top: 16px;
    right: 8px;
    cursor: pointer;
    z-index: 2;
}


.NE_module_evaluation_card .card_header .card_image{
    display: block;
    width: 100%;
    position: relative;
}

.NE_module_evaluation_card .card_header .card_image img{
    border-radius: 24px;
    /*width: 100%;*/
    width: 245px;
    height: 200px;
}

.NE_module_evaluation_card .card_header .card_image .card_category{

    position: absolute;
    top: 10px;
    right: 8px;
    width: fit-content;
    padding: 5px 12px;
    border-radius: 24px;
    color: #5a14f0;
    border: #930b85;
    font-weight: 500;
    font-size: 14px;
    background: rgba(255, 255, 255, 0.74);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(1.2px);
    -webkit-backdrop-filter: blur(1.2px);
    border: 1px solid rgba(255, 255, 255, 0.99);
}

.NE_module_evaluation_card .card_header .card_secondary_text{
    margin-left: 7px;
    margin-top: 5px;
    color: #989898;
    font-size: 14px;
}

.NE_module_evaluation_card .card_body_moderator{
    margin-left: 7px;
    margin-top: 5px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex: 1;
}


.NE_module_evaluation_card .card_body_moderator h1{
    box-sizing: border-box;
    outline: none;
    font-family: 'IBM Plex Sans', sans-serif;
    letter-spacing: 0;
    font-weight: normal;
    font-size: 16px;
}

.NE_module_evaluation_card .card_body_moderator p{
    font-size: 14px;
    color: #2C2738;
    margin-top: 9px;
}

.NE_module_evaluation_card .card_footer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    /*background-color: #ccc;*/
    margin-top: 13px;
}

.NE_module_evaluation_card .card_footer .metric{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
    width: fit-content;
}

.NE_module_evaluation_card .card_footer .metric img{
    width: 14px;
    height: 14px;
    margin-right: 4px;
}

.NE_module_evaluation_card .card_footer .metric p{
    line-height: 12px;
    font-size: 12px;
    color: #616161;
}

.NE_module_evaluation_card .card_footer .metric p .metric_count{
    font-size: 15px;
    color: #000;
    font-weight: 600;
}
