.participant_points .page_header{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 60px;
}

.participant_points .page_body{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 45px;
}

.participant_item_page .page_body{
  margin-top: 19px;

}

.participant_points .page_body .content{
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  margin-right: 21px;
  margin-left: 16px;
  /*justify-content: space-between;*/
  /*align-items: flex-start;*/

  width: 100%;
}

.participant_points .page_body .last_comments{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: fit-content;
  margin-left: 24px;
}

.NE_notionItem_header_button .active_button{
  background: var(--primary-color) !important;
}
.NE_notionItem_header_button .active_button span{
  color: white !important;
}
 .switch_sections_wrapper{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 19px 0;
}
