.NE_checkbox{
    width: fit-content;
    height: fit-content;
}

.NE_checkbox input[type=checkbox]{
    display: none;
}

.NE_checkbox input[type=checkbox] + label{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-size: 20px;
    font-weight: 500;
    color: #3F4351;
    cursor: pointer;
}

.NE_checkbox input[type=checkbox] + label::before{
    content: '';
    display: block;
    width: 28px;
    height: 28px;
    background-color: #fff;
    border-radius: 4px;
    margin-right: 10px;
    cursor: pointer;
    box-shadow: 0px 4px 8px #2C27380A;
    border: 1px solid #DBE2EA;
    box-sizing: border-box;
}

.NE_checkbox input[type=checkbox]:checked + label::before{
    background-image: url('assets/checkbox-checked.svg');
    background-repeat: no-repeat;
    background-position: center;
    border: none;
}

.NE_checkbox input[type=checkbox]:disabled + label::before{
    box-shadow: 0px 4px 8px #2C27380A;
    border: 1px solid #DBE2EA;
    background-color: #EBF4F8;
}

.NE_checkbox input[type=checkbox]:disabled:checked + label::before{
    background-image: url('assets/checkbox-checked-disabled.svg');
    border: none;
}

.NE_checkbox_partially_checked input[type=checkbox] + label::before{
    background-image: url('assets/checkbox-partial-check.svg');
    border: none;
}

.NE_checkbox_partially_checked input[type=checkbox]:checked + label::before{
    background-image: url('assets/checkbox-partial-check.svg');
    border: none;
}

.NE_checkbox_partially_checked input[type=checkbox]:disabled + label::before{
    background-image: url('assets/checkbox-partial-check-disabled.svg');
    border: none;
}

.NE_checkbox_partially_checked input[type=checkbox]:disabled:checked + label::before{
    background-image: url('assets/checkbox-partial-check-disabled.svg');
    border: none;
}
